import ComingSoon from 'pages/maintenance/coming-soon/coming-soon2';
import Blog from 'pages/frontOffice/Blog';
import DataPolicy from 'pages/frontOffice/DataPolicy';
import LegalNotice from 'pages/frontOffice/LegalNotice';
// ==============================|| MAIN ROUTES ||============================== //

const OtherRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <ComingSoon/>,    
    },
    {
      path: 'blog',
      element: <Blog/>
    },
    {
      path:'/data-policy',
      element:<DataPolicy/>
    },
    {
       path:'/legal-notice',
      element:<LegalNotice/>
    },
  ]
};

export default OtherRoutes;
