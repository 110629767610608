import React , {useState}from 'react';
import { Box, Typography, List, ListItem, ListItemText,Tooltip , Dialog,
  DialogContent,
  DialogTitle, Stack } from '@mui/material';
import Lottie from 'lottie-react';
import { Description } from '@mui/icons-material';
import IconButton from 'components/@extended/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
const ServiceCardComponent = ({ animationData, icon, title, bulletPoints, isAnimation ,isMobile, work , family, description}) => {
   const [open, setOpen] = useState(false);
    const handleOpen = () => {
      setOpen(true);
    };
  
    const normalizeText = (text) => text.toLowerCase().replaceAll(' ', '-');
    const handleClose = () => setOpen(false);

  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        maxWidth: '300px',
        margin: '0 auto',
        backgroundColor: 'transparent',
        border: isMobile ? "1px solid #B0B0B0" : "none",
        borderRadius: isMobile ? "20px" :"none",
        marginBottom: isMobile ? "20px" :"none",
        paddingLeft : isMobile ? "20px": "10px"

      }}
    >
      <Box sx={{ marginBottom: '10px'  }}>
        {isAnimation ? (
          <Lottie animationData={animationData} loop={true} style={{ width: '100%', height: 'auto', maxWidth: '150px', margin: '0 auto', marginTop: work ? "17px" : family? "10px":"none" }} />
        ) : (
          <img src={icon} alt={title} style={{ width: '100%', height: 'auto', maxWidth: '150px', margin: '0 auto' }} />
        )}
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>

      <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '5px' ,marginTop: work ? "-17px" : family? "-10px":"none"}}>
        {title}
        
      </Typography>
       <Tooltip title="Découvrir" style={{marginTop: work ? "-17px" : family? "-10px":"none"}}>
                    <IconButton onClick={() => handleOpen()}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
              <Typography variant="h6">{title}</Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
             {description}
            </DialogContent>
          </Dialog>
          </Stack>
      <List sx={{ textAlign: 'left', color: "#888" , marginLeft: isMobile ? "10px" :"40px" }}>
        {bulletPoints.map((point, index) => (
          <ListItem key={index}  sx={{ 
            paddingLeft: 0, 
            display: 'flex', 
            alignItems: 'flex-start', 
            position: 'relative',
            listStyleType: 'none',
            '&::before': {
              content: '"•"',
              color: "#888" ,
              fontWeight: 'bold',
              display: 'inline-block',
              width: '2em',
              marginRight: '0.5em',
              position: 'absolute',
              left: 0,
            },
          }}>
            <ListItemText primary={point} sx={{marginTop:"0px", marginBottom:"0px", paddingLeft: '1.5em'  }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ServiceCardComponent;
