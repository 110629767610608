import { Link } from 'react-router-dom';

// material-ui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// project-imports
import useAuth from 'hooks/useAuth';
import AnimateButton from 'components/@extended/AnimateButton';
import AuthWrapper from 'sections/auth/AuthWrapper';
import Logo from 'components/logo';
import AuthDivider from 'sections/auth/AuthDivider';
import LanguageSwitcher from 'components/LanguageSwitcher';
import ButtonComponent from 'components/ButtonComponent';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { margin } from '@mui/system';

// ================================|| CHECK MAIL ||================================ //

export default function CheckMail() {
  const { isLoggedIn } = useAuth();
 const { t } = useTranslation();
  const navigate = useNavigate();
  
  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Logo />
        </Grid>
        <Grid item xs={12}>
          <AuthDivider />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
          <Typography variant="h3">Bonjour, veuillez vérifier votre email</Typography>
<Typography color="secondary" sx={{ mb: 0.5, mt: 1.25 }}>
  Nous avons envoyé des instructions pour la récupération de votre mot de passe à votre email.
</Typography>

          </Box>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <ButtonComponent
               label={t('homePage.login')}
               width="150px"
               height="40px"
               backgroundColor="white"
               color="grey"
               border = "2px solid grey"
              onClick={() => navigate(isLoggedIn ? '/auth/login' : '/login')}
            />
            
        </Grid>              

        <Grid item xs={12}>
          <AuthDivider/>
        </Grid>
          
      </Grid>
    </AuthWrapper>
  );
}
