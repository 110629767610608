import React, { useState, useEffect } from 'react';
import { Stack, styled } from '@mui/system';
import InputComponent from '../../../../components/InputComponent';
import { useTranslation } from 'react-i18next';
import { Button, Grid,  MenuItem, Select, Typography, Link,Box } from '@mui/material';
import countryCodes from 'common/countryCodes';
import { phone } from 'phone';
import userAPI from "services/AvektoiAPIClient"
import Constants from 'common/Constants';
import IconButton from 'components/@extended/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/Loader';

const CountryCodeSelect = styled(Select,)(({ theme, disabled }) => ({
  width: '100px',
  height: '56px',
  marginRight: '10px',
  borderRadius: '4px',
  color: theme.palette.text.primary, 
  pointerEvents: 'none',
  
}));

const PhoneNumberInput = styled(InputComponent)(({ theme }) => ({
  width: '190px',
  height: '56px',
  borderRadius: '4px',
}));

const PhoneStep = ({ nextStep, prevStep }) => {
  const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [countryCode, setCountryCode] = useState('+33');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [selection, setSelection] = useState(clientObject.client_type);

  useEffect(() => {
    let clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));
    setSelection(clientObject.client_type);
    sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify({...clientObject, client_type: selection}));
    setFirstName(clientObject.first_name);

    if (clientObject.phone_number) {
      nextStep({ ...clientObject });
      return;
    }

    clientObject = JSON.parse(sessionStorage.getItem('clientObject')) || {};
    if (clientObject.phone_number) {
      const parsedPhone = phone(clientObject.phone_number);
      setPhoneNumber(parsedPhone.phoneNumber.replace(parsedPhone.countryCode, ''));
      setCountryCode(parsedPhone.countryCode);
    }
  }, [nextStep]);

  useEffect(() => {
    if (error) {
      setError('');
    }
  }, [phoneNumber]);

  const handleNextClick = async () => {
    let formattedPhoneNumber = phoneNumber;
  
    if (countryCode === '+33' && phoneNumber.startsWith('0')) {
      formattedPhoneNumber = phoneNumber.substring(1);
    }
  
    const fullPhoneNumber = `${countryCode}${formattedPhoneNumber}`;
    
    if (validatePhoneNumber(fullPhoneNumber)) {
      setError('');
      setIsLoading(true)
      const response = await userAPI.checkPhone(fullPhoneNumber);
      setIsLoading(false)
      if (response === true) {
        setError('already_used');
      } else {
        const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) || {};
        sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify({ ...clientObject, phone_number: fullPhoneNumber, country_code: countryCode }));

        const verificationResponse = await userAPI.sendPhoneVerification(fullPhoneNumber);
        if (verificationResponse) {
          console.log('Phone verification sent:', verificationResponse);
        } else {
          console.error('Failed to send phone verification');
        }
        nextStep({ phone_number: fullPhoneNumber });
      }
    } else {
      setError('invalid');
    }
  };
  

  const validatePhoneNumber = (fullPhoneNumber) => {
    const result = phone(fullPhoneNumber);
    return result.isValid;
  };

  const handleBackClick = () => {
    prevStep()
  }

  return (
    <>
      {isLoading && <Loader />}
      {/*<TypeSwitcher selection={selection} toggleSelection={toggleSelection} homePage={true} />*/}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ position: 'relative', width: '100%', maxWidth: '800px' }}>
              <IconButton 
                onClick={handleBackClick} 
                style={{ position: 'absolute', left: 0, top: "50%" }}
              >
                <ArrowBackIcon style={{ width: 30, height: 30 }} />
              </IconButton>
              <Box sx={{ textAlign: 'center', marginLeft: '46px', marginTop:'30px' }}>
      <Typography variant="h2" style={{ marginTop: 20 }}>{firstName}, {t('signup.step2.title2')}</Typography>
      <div style={{ marginTop: 30 }}>
        <Typography variant="h5">{t('signup.step2.instructions2')}</Typography>
        <Grid container>
        
<Grid container spacing={3} justifyContent="center" marginTop="10px">            <div style={{ display: 'flex', maxWidth: 400, alignItems: 'center', marginBottom: '20px' }}>
            <CountryCodeSelect
                value={countryCode}
                IconComponent={disabled ? null : undefined}
                renderValue={(selected) => selected}
              >
                {countryCodes.map((code) => (
                  <MenuItem key={code.code} value={code.code}>
                    {code.name} ({code.code})
                  </MenuItem>
                ))}
              </CountryCodeSelect>
              <PhoneNumberInput
                label={t('signup.step2.phoneNumber')}
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                error={error === 'already_used' || error === 'invalid'}
                helperText={
                  error === 'already_used'
                    ? t('signup.step2.errorAlreadyUsedInput')
                    : error === 'invalid'
                      ? t('signup.step2.errorInvalidInput')
                      : ''
                }
              />
            </div>
          </Grid>
        </Grid>
        {error === 'already_used' && (
          <Typography variant="body2" color="error" marginTop="-20px" marginBottom="10px">
            {t('signup.step2.errorMessage')}
            <Link component="button" variant="body2" marginTop="-20px" marginBottom="10px" sx={{textDecoration:"underline"}} onClick={() => navigate('/login')}>
              {t('signup.step2.linkLogin')}
            </Link>
          </Typography>
        )}
        <Stack direction='row' alignItems='center' justifyContent='center' columnGap={2}>
          <Button size='large' disabled={!phoneNumber  || error === 'already_used'} variant="contained" color="primary" style={{ width: 250 }} onClick={handleNextClick}>{t('buttons.next')}</Button>
        </Stack>
      </div>
      </Box>
      </Box>
      </Box>

    </>
  );
};

export default PhoneStep;
