import React from "react";
import { Grid, Stack, Box, Typography, Link, TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import LogoMain from "components/logo/LogoMain";
import NewButton from "components/NewButton";
export default function Footer() {
  const { t } = useTranslation();

  return (
    <Box sx={{ backgroundColor: "#555555", color: "#f0f0f0", p: {xs: 4, md: 7} ,
bottom: 0,
width:"100%",
zIndex:1000}}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <LogoMain sx={{ color: "#F066B5", mb: 2 }} />
            <Typography variant="body2" sx={{ width: "80%", textAlign: "center", marginTop: "10px" }}>
              {t("footer.description")}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
  
  <Grid container direction="column" alignItems="center">
    
    <Grid item xs={12}>
      <Link href="/data-policy" color="inherit" variant="body2" sx={{ textAlign: "center" }}>
        {t("footer.dataPolicy")}
      </Link>
    </Grid>
    <Grid item xs={12}>
      <Link href="/legal-notice" color="inherit" variant="body2" sx={{ textAlign: "center" }}>
        {t("footer.legalNotice")}
      </Link>
    </Grid>
    <Grid item xs={12}>
      <Link href="/blog" color="inherit" variant="body2" sx={{ textAlign: "center" }}>
        Blog
      </Link>
    </Grid>
  </Grid>
</Grid>


        <Grid item xs={12} md={4} sx={{ textAlign: {xs: 'center'}}}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            {t("footer.contact")}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
          Tendanz Courtage - avektoi          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
          <a href="https://www.google.com/maps?q=66+AVENUE+DES+CHAMPS+ELYSEES+75008+PARIS" target="_blank" rel="noopener noreferrer" style={{color:"inherit" , textDecoration:"none"}}>
          66 avenue des Champs Elysées 75008 Paris
          </a>
          </Typography>
        
          <Typography variant="body2" sx={{ mb: 2 }} > 
          <a href="mailto:contact@avektoi.fr" style={{color:"inherit" , textDecoration:"none"}}>
          contact@avektoi.fr
          </a>
                    </Typography>

          
               


        
        </Grid>
      </Grid>
    </Box>
  );
}