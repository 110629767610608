import { useOutletContext } from 'react-router';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormHelperText from '@mui/material/FormHelperText';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import * as Yup from 'yup';
import { Formik } from 'formik';
import MainCard from 'components/MainCard';
import { openSnackbar } from 'api/snackbar';
import { useContext } from 'react';
import JWTContext from 'contexts/JWTContext';
import { CLIENT_TYPE } from '../../../../common/Constants';
import dateFormat from 'dateformat';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography'; // Correct import
import NewButton from 'components/NewButton';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = { PaperProps: { style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP } } };

function useInputRef() {
  return useOutletContext();
}

// Helper function to calculate age
const calculateAge = (dob) => {
  const diff = Date.now() - dob.getTime();
  const age = new Date(diff).getUTCFullYear() - 1970;
  return age;
};

export default function TabPersonal() {
  const { profile, updateMyProfile } = useContext(JWTContext);
  const { t } = useTranslation();

  const maxDate = new Date();

  const inputRef = useInputRef();

  return (
    <MainCard content={false} title={t('profile.cardTitle.personalInformation')} sx={{ '& .MuiInputLabel-root': { fontSize: '0.875rem' } }}>
      <Formik
        enableReinitialize
        initialValues={{
          first_name: profile?.first_name,
          last_name: profile?.last_name,
          email: profile?.email,
          gender: parseInt(profile?.gender),
          date_of_birth: profile?.date_of_birth ? new Date(profile?.date_of_birth) : null,
          country_code: profile?.country_code,
          phone_number: profile?.phone_number?.replace(profile?.country_code, ''),
          address_line_1: profile?.address_line_1,
          address_line_2: profile?.address_line_2,
          country_id: profile?.country_id,
          city: profile?.city,
          nationality_id: profile?.nationality_id,
          state_province: profile?.state_province,
          nbr_family_members: parseInt(profile?.nbr_family_members),
          company_name: profile?.company_name,
          nbr_employees: parseInt(profile?.nbr_employees),
          zip_code: profile?.zip_code,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          first_name: Yup.string().max(255).required(t('profile.errors.firstname.required')),
          last_name: Yup.string().max(255).required(t('profile.errors.lastname.required')),
          email: Yup.string().email(t('profile.errors.email.invalid')).max(255).required(t('profile.errors.email.required')),
          phone_number: Yup.number()
            .test('len', t('profile.errors.phone.invalid'), (val) => [9, 10].includes(val?.toString().length))
            .required(t('profile.errors.phone.required')),
          zip_code: Yup.number().typeError(t('profile.errors.zipcode.invalid')),
          date_of_birth: Yup.date()
            .nullable()
            .required(t('profile.errors.dob.required'))
            .test('age', t('profile.errors.dob.ageRestriction'), function (value) {
              if (!value) return true;
              const age = calculateAge(value);
              return age >= 18 && age <= 70;
            }),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const data = {
              ...values,
              date_of_birth: dateFormat(values.date_of_birth, 'dd/mm/yyyy'),
              phone_number: `${values.country_code}${values.phone_number}`,
            };
            await updateMyProfile(profile?.id, data);
            setStatus({ success: false });
            setSubmitting(false);
            openSnackbar({
              open: true,
              message: 'Personal profile updated successfully.',
              variant: 'alert',
              alert: { color: 'success' },
            });
          } catch (err) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values, resetForm }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box sx={{ p: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="personal-first-name">{t('profile.inputLabels.firstname')}</InputLabel>
                    <TextField
                      fullWidth
                      id="personal-first-name"
                      value={values.first_name}
                      name="first_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={t('profile.inputLabels.firstname')}
                      autoFocus
                      inputRef={inputRef}
                    />
                  </Stack>
                  {touched.first_name && errors.first_name && (
                    <FormHelperText error id="personal-first-name-helper">
                      {errors.first_name}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="personal-last-name">{t('profile.inputLabels.lastname')}</InputLabel>
                    <TextField
                      fullWidth
                      id="personal-last-name"
                      value={values.last_name}
                      name="last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={t('profile.inputLabels.lastname')}
                    />
                  </Stack>
                  {touched.last_name && errors.last_name && (
                    <FormHelperText error id="personal-last-name-helper">
                      {errors.last_name}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="personal-gender">{t('profile.inputLabels.gender')}</InputLabel>
                    <RadioGroup
                      aria-labelledby="gender-label"
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      row
                      sx={{
                        flexBasis: '100%',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label={t('profile.genderOptions.male')}
                        sx={{ marginRight: 5 }}
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label={t('profile.genderOptions.female')}
                        sx={{ marginRight: 5 }}
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label={t('profile.genderOptions.other')}
                      />
                    </RadioGroup>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="dob-month">{t('profile.inputLabels.dob')}</InputLabel>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                      <Select
                        fullWidth
                        value={values?.date_of_birth ? values.date_of_birth?.getDate().toString() : ''}
                        name="dob-date"
                        onBlur={handleBlur}
                        onChange={(e) => setFieldValue('date_of_birth', new Date(values.date_of_birth.setDate(parseInt(e.target.value, 10))))}
                        MenuProps={MenuProps}
                      >
                        {[...Array(31)].map((_, i) => (
                          <MenuItem key={i} value={i + 1}>
                            {i + 1}
                          </MenuItem>
                        ))}
                      </Select>
                      <Select
                        fullWidth
                        value={values.date_of_birth ? values.date_of_birth?.getMonth().toString() : ''}
                        name="dob-month"
                        onChange={(e) => setFieldValue('date_of_birth', new Date(values.date_of_birth.setMonth(parseInt(e.target.value, 10))))}
                      >
                        <MenuItem value="0">{t('profile.monthOptions.january')}</MenuItem>
                        <MenuItem value="1">{t('profile.monthOptions.february')}</MenuItem>
                        <MenuItem value="2">{t('profile.monthOptions.march')}</MenuItem>
                        <MenuItem value="3">{t('profile.monthOptions.april')}</MenuItem>
                        <MenuItem value="4">{t('profile.monthOptions.may')}</MenuItem>
                        <MenuItem value="5">{t('profile.monthOptions.june')}</MenuItem>
                        <MenuItem value="6">{t('profile.monthOptions.july')}</MenuItem>
                        <MenuItem value="7">{t('profile.monthOptions.august')}</MenuItem>
                        <MenuItem value="8">{t('profile.monthOptions.september')}</MenuItem>
                        <MenuItem value="9">{t('profile.monthOptions.october')}</MenuItem>
                        <MenuItem value="10">{t('profile.monthOptions.november')}</MenuItem>
                        <MenuItem value="11">{t('profile.monthOptions.december')}</MenuItem>
                      </Select>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          views={['year']}
                          value={values.date_of_birth}
                          maxDate={maxDate}
                          onChange={(newValue) => {
                            setFieldValue('date_of_birth', newValue);
                          }}
                          sx={{ width: 1 }}
                        />
                      </LocalizationProvider>
                    </Stack>
                    {(touched.date_of_birth && errors.date_of_birth) && (
                  <Typography variant="body2" color="error">
                    {errors.date_of_birth}
                  </Typography>
                )}
                  </Stack>
                 
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="personal-phone">{t('profile.inputLabels.phoneNumber')}</InputLabel>
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                      <Stack spacing={1} sx={{ flex: 3 }}>
                        <TextField
                          fullWidth
                          id="country-code"
                          disabled
                          value={values.country_code}
                          name="country_code"
                          placeholder={t('profile.inputPlaceholders.countryCode')}
                        />
                      </Stack>
                      <Stack spacing={1} sx={{ flex: 7 }}>
                        <TextField
                          fullWidth
                          id="personal-contact"
                          disabled
                          value={values.phone_number}
                          name="phone_number"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder={t('profile.inputPlaceholders.contactNumber')}
                        />
                      </Stack>
                    </Stack>
                    {touched.phone_number && errors.phone_number && (
                      <FormHelperText error id="personal-contact-helper">
                        {errors.phone_number}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="personal-email">{t('profile.inputLabels.email')}</InputLabel>
                    <TextField
                      type="email"
                      fullWidth
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled
                      id="personal-email"
                      placeholder={t('profile.inputLabels.email')}
                    />
                  </Stack>
                  {touched.email && errors.email && (
                    <FormHelperText error id="personal-email-helper">
                      {errors.email}
                    </FormHelperText>
                  )}
                </Grid>

                {profile?.client_type !== CLIENT_TYPE.PERSONAL ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="personal-company-name">{t('profile.inputLabels.companyName')}</InputLabel>
                        <TextField
                          type="text"
                          fullWidth
                          value={values.company_name}
                          name="company_name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="personal-company-name"
                          placeholder={t('profile.inputLabels.companyName')}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1} sx={{ flex: 6 }}>
                        <InputLabel htmlFor="nbr_employees">{t('profile.inputLabels.numberEmployees')}</InputLabel>
                        <TextField
                          fullWidth
                          id="nbr_employees"
                          value={values.nbr_employees}
                          name="nbr_employees"
                          type="number"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder={t('profile.inputLabels.numberEmployees')}
                        />
                      </Stack>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="personal-zip-code">{t('profile.inputLabels.zipcode')}</InputLabel>
                        <TextField
                          type="text"
                          fullWidth
                          value={values.zip_code}
                          name="zip_code"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="personal-zipcode"
                          placeholder={t('profile.inputLabels.zipcode')}
                        />
                      </Stack>
                      {touched.zip_code && errors.zip_code && (
                        <FormHelperText error id="personal-zipcode-helper">
                          {errors.zip_code}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1} sx={{ flex: 6 }}>
                        <InputLabel htmlFor="number-employees">{t('profile.inputLabels.numberFamilyMembers')}</InputLabel>
                        <TextField
                          fullWidth
                          id="number-employees"
                          value={values.nbr_family_members}
                          name="nbr_family_members"
                          type="number"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder={t('profile.inputLabels.numberFamilyMembers')}
                        />
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
            <CardHeader title={t('profile.cardTitle.address')} />
            <Divider />
            <Box sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="personal-nationality">{t('profile.inputLabels.nationality')}</InputLabel>
                    <TextField
                      fullWidth
                      id="personal-nationality"
                      value={values.nationality_id}
                      name="nationality_id"
                      placeholder={t('profile.inputPlaceholders.nationality')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="personal-country">{t('profile.inputLabels.country')}</InputLabel>
                    <TextField
                      fullWidth
                      id="personal-country"
                      value={values.country_id}
                      name="country_id"
                      placeholder={t('profile.inputPlaceholders.country')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="personal-city">{t('profile.inputLabels.city')}</InputLabel>
                    <TextField
                      fullWidth
                      id="personal-city"
                      value={values.city}
                      name="city"
                      placeholder={t('profile.inputLabels.city')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="personal-state">{t('profile.inputLabels.state')}</InputLabel>
                    <TextField
                      fullWidth
                      id="personal-state"
                      value={values.state_province}
                      name="state_province"
                      placeholder={t('profile.inputLabels.state')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="personal-address1">{t('profile.inputLabels.addressLine1')}</InputLabel>
                    <TextField
                      multiline
                      rows={2}
                      fullWidth
                      id="personal-addrees1"
                      value={values.address_line_1}
                      name="address_line_1"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={t('profile.inputLabels.addressLine1')}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="personal-address2">{t('profile.inputLabels.addressLine2')}</InputLabel>
                    <TextField
                      multiline
                      rows={2}
                      fullWidth
                      id="personal-addrees2"
                      value={values.address_line_2}
                      name="address_line_2"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={t('profile.inputLabels.addressLine2')}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ p: 2.5 }}>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mt: 2.5 }}>
              <NewButton label= {t('profile.buttons.cancel')} onClick={() => resetForm()} cancel={true}/>

                <NewButton label={t('profile.buttons.save')}onClick={handleSubmit}  disabled={isSubmitting || !!errors.date_of_birth} />
                
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </MainCard>
  );
}
