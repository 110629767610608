import React, { useEffect, useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Confetti from "react-confetti";
import mascot from "../../../assets/mascot_2.png";
import background from "../../../assets/comingsoon1.png";
import Logo from "../../../assets/Logo2.png";
import axiosServices from "utils/axios";

export default function CongratulationsPage() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const { id } = useParams()

  useEffect(() => {
    setTimeout(() => {
      goToDashboard();
    }, 10000);
  }, []);


  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    axiosServices.post(`/docusign/send-for-signature/`, { quotation_id: id }).then((response) => {
    }).catch((error) => {
      console.error(error);
    })
  }, []);

  const goToDashboard = () => {
    navigate("/my-insurances");
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center",
        position: "relative",
        overflow: "hidden",
        color: "#000",
      }}
    >
      <Confetti width={width} height={height} style={{ zIndex: 0 }} />

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 0,
          width: "95vw",
        }}
      >
        <img
          src={mascot}
          alt="Mascotte"
          style={{
            width: "100%",
            height: "auto",
            display: "block",
          }}
        />
      </Box>

      <Container>
        <Box sx={{ marginBottom: "20px", marginTop: "90px" }}>
          <img
            src={Logo}
            alt="Avektoi Logo"
            style={{ height: "50px", marginBottom: "10px" }}
          />
        </Box>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: "#F066B5",
            marginBottom: "20px",
          }}
        >
          Félicitations !
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{
            fontSize: "19px",
            fontWeight: "bold",
          }}
        >
          <p style={{ color: "#000", marginBottom: "0px" }}>
            Votre paiement a été accepté,
          </p>
          <span style={{ color: "#000" }}>
            et votre couverture est maintenant active.
          </span>
        </Typography>

        <Typography
          sx={{
            fontSize: "18px",
            marginBottom: "30px",
            color: "#fff",
            fontWeight: 400,
          }}
        >
          <p style={{ color: "#000", marginBottom: "0px" }}>
            Profitez de tous les avantages de votre nouvelle assurance.
          </p>
          <span style={{ color: "#000" }}>
            Nous sommes ravis de vous avoir parmi nos clients !
          </span>
        </Typography>


        <Button
          variant="contained"
          sx={{
            color: "#fff",
            backgroundColor: "#F066B5",
            padding: "12px 25px",
            fontSize: "16px",
            fontWeight: "bold",
            borderRadius: "25px",
            zIndex: 1,

            textTransform: 'none',
          }}
          onClick={goToDashboard}
        >
          Mon Espace Client
        </Button>
      </Container>

     
    </Box>
  );
}
