module.exports = Object.freeze({
    // APP_API_BASE_URL: 'http://localhost:3001',
    APP_API_BASE_URL: 'https://d1kxae2wzqot6k.cloudfront.net',
    // DOMAIN_URL: 'https://avektoi.fr/',    
    //DOMAIN_URL: 'http://localhost:3000',
    DOMAIN_URL: 'https://avektoi.fr',
    ACCESS_AUTH_TOKEN: 'CLIENT_AUTH_TOKEN',
    CLIENT_OBJECT: 'CLIENT_OBJECT',
    ACCESS_ADMIN_TOKEN: 'ACCESS_ADMIN_TOKEN',
    ADMIN_OBJECT: 'ADMIN_OBJECT',
    ACCESS_API_KEY: 'ACCESS_API_KEY',
    STRIPE_PUBLIC_KEY: 'pk_live_51PyfnlFtMe18BWZVLonmwGcOKiaQx0cOWSzbpUfGKNIAV8iINIiyqv4rURrGX7L2LFzd0BG4D19jt8qMzBFrftLq009eY2Oy9J',
    ACCESS_AUTH_TOKEN_SAMPLE: 'f72578c82c8a676651d5ec76a4c6d7fd4a5cae044b5b897b94eaecf6d48f7ea045a72935ff00b6f030eca38c66dbf02e459f15713f6184bd12c413b7b4c254c5b0de574f5df13af563b360f20ca581875258e6ebcc7176bbef100cd06c3d511e18964aec', // TODO: FOR INITIAL TEST ONLY, to be setup properly later
    ACCESS_API_KEY_SAMPLE: 'xxxxx-a5f5c9f4-d1d8-4c6d-9cde-9e8e38dbb6bb-xxxxxxx', // TODO: FOR INITIAL TEST ONLY, to be setup properly later
    CLIENT_ID_SAMPLE: '3792ffea-d92e-490f-848c-ea645a9baed5', // TODO: FOR INITIAL TEST ONLY, to be setup properly later
    CLAIM_ID_SAMPLE: 'e0f2b12e-9d5c-4b58-8e2b-2a2d850fd7e4', // TODO: FOR INITIAL TEST ONLY, to be setup properly later
    POLICY_ID_SAMPLE: '1c5a0e5e-4e8c-4e71-8a93-8d1a0f6c1c5a', // TODO: FOR INITIAL TEST ONLY, to be setup properly later
    PORTFOLIO_ID_SAMPLE: 'd46103a2-9bcf-4de3-9f0e-df2c97f0ae70',
    GOOGLE_ANALYTICS_ID: 'G-QN5P1Z7TEC',
    PASSWORD_MIN_LENGTH: 8,
    DATE_FORMAT: 'DD/MM/YYYY',
    PASSWORD_COMPLEXITY_REGEX: /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
    PASSWORD_LETTER_REGEX: /[a-zA-Z]/,
    EMAIL_REGEX: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    APP_PRIMARY_ACCENT_COLOR_DEFAULT: '#F066B5',
    APP_PRIMARY_ACCENT_SHADE_1_COLOR_DEFAULT: '#D85CA3',
    APP_PRIMARY_ACCENT_SHADE_2_COLOR_DEFAULT: '#FFFFFF',
    APP_SECONDARY_ACCENT_COLOR_DEFAULT: '#D71683',
    APP_TERTIARY_ACCENT_COLOR_DEFAULT: '#282828',
    APP_QUATERNARY_ACCENT_COLOR_DEFAULT: '#EEEEEE',
    APP_QUATERNARY_ACCENT_COLOR_SHADE_1_DEFAULT: '#B5B5B5',
    APP_ERROR_COLOR: '#F44336',
    APP_ERROR_COLOR_DISABLED: '#F44336',
    APP_WHITE_COLOR: '#FFFFFF',
    APP_TEXT_COLOR: '#000000',
    APP_SUCCESS_COLOR: '#4CAF50',
    APP_LINK_COLOR: '#007DFF',
    APP_SECONDARY_TEXT_COLOR: '#675f63',
    APP_DRAWER_MENU_ITEM: {
        DIVIDER_ID: 'divider',
        ICON_ACCOUNT_CIRCLE: 'AccountCircle',
        ICON_ARROW_RIGHT: 'ArrowRight',
        ICON_NOTE_ALT: 'NoteAlt',
        ICON_SECURITY: 'Security'
    },
    APP_TABLE: {
        MY_COMPLAINTS: {
            NEW_COMPLAINT: "NEW COMPLAINT"
        },
        ACTION: {
            VIEW: 'View',
            DOWNLOAD: 'Download',
            DELETE: 'Delete'
        }
    },
    STATUS: {
        COMPLAINT: {
            DRAFT: 'DRAFT',
            DECLARED: 'DECLARED',
            OPEN: 'OPEN',
            PENDING: 'PENDING',
            RECEIPT: 'RECEIPT',
            CLOSED: 'CLOSED',
        },
        CLAIM: {
            NIL: {
                VALUE: 0,
                LABEL: 'NIL'
            },
            OPEN: {
                VALUE: 1,
                LABEL: 'OPEN'
            },
            CLOSED: {
                VALUE: 2,
                LABEL: 'CLOSED'
            },
            JUDICIAL: {
                VALUE: 3,
                LABEL: 'JUDICIAL'
            },
        }
    },
    APP_BUTTON: {
        CATEGORIES: {
            PRIMARY_CONTAINED: 'primary',
            SECONDARY_CONTAINED: 'secondary',
            DANGER_CONTAINED: 'danger',
            PRIMARY_OUTLINED: 'primary_outlined',
            SECONDARY_OUTLINED: 'secondary_outlined',
            DANGER_OUTLINED: 'danger_outlined'
        },
        TYPES: {
            VIEW: 'View',
            DOWNLOAD: 'Download',
            DELETE: 'Delete'
        }
    },
    APP_CLAIM_STATUS: {
        NIL: "Nil",
        OPEN: "Open",
        CLOSED: "Closed",
        JUDICIAL: "Judicial"
    },
    APP_DOCUMENT_TYPE: {
        IMAGE: 'Image',
        PDF: "PDF"
    },
    CLAIM: {
        TYPE: {
            INCIDENT_ILLNESS: 'INCIDENT_ILLNESS',
            HOUSE_HARRASSMENT_VIOLENCE: 'HOUSE_HARRASSMENT_VIOLENCE',
            TERRORIST_ATTACK: 'TERRORIST_ATTACK',
            DEATH_RELATIVE: 'DEATH_RELATIVE',
            PREMATURE_BIRTH: 'PREMATURE_BIRTH',
            TRAUMA_WITNESS: 'TRAUMA_WITNESS',
            DEATH_PET: 'DEATH_PET',
            FLOOD: 'FLOOD',
            FIRE: 'FIRE',
            THEFT: 'THEFT',
            MENTAL_HEALTH: 'MENTAL_HEALTH',
            WORK_HARRASSMENT: 'WORK_HARRASSMENT',
            LAYOFF: 'LAYOFF'
        },
        TYPE_LIST: ['INCIDENT_ILLNESS',
            'HOUSE_HARRASSMENT_VIOLENCE',
            'TERRORIST_ATTACK',
            'DEATH_RELATIVE',
            'PREMATURE_BIRTH',
            'TRAUMA_WITNESS',
            'DEATH_PET',
            'FLOOD',
            'FIRE',
            'THEFT',
            'MENTAL_HEALTH',
            'WORK_HARRASSMENT',
            'LAYOFF'
        ],
        CATEGORY: {
            MY_HOME: 'MY_HOME',
            MY_WORK: 'MY_WORK'
        }
    },
    COMPLAINT: {
        STATUS: {
            DRAFT: 'DRAFT',
            DECLARED: 'DECLARED',
            OPEN: 'OPEN',
            PENDING: 'PENDING',
            RECEIPT: 'RECEIPT',
            CLOSED: 'CLOSED',
            CANCELLED: 'CANCELLED'
        },
        TYPE: {
            POLICY: 'policy',
            CLAIM: 'claim',
            OTHER: 'other'
        },
        LEVEL: {
            LEVEL_1: '1',
            LEVEL_2: '2',
            LEVEL_3: '3'
        },
    },
    NOTICE_MESSAGE: {
        TYPE: {
            ERROR: 'Error',
            SUCCESS: 'Success'
        },
        CLAIM: {
            CREATE_SUCCESS: "Claim has been created successfully"
        }
    },
    DATETIME_PICKER: {
        TYPE: {
            DATE_PICKER: 'Date picker',
            TIME_PICKER: 'Time picker',
            DATETIME_PICKER: 'Datetime picker',
            DATERANGE_PICKER: 'Date range picker',
            DATETIMERANGE_PICKER: 'Datetime range picker'
        }
    },
    CLIENT_TYPE: { PERSONAL: 'PERSONAL', PROFESSIONAL: 'PROFESSIONAL' },
    SITE_MAP: {
        LOGIN: '/login',
        SIGN_UP: '/sign-up',
        REGISTER_POLICY: '/register-policy',
    },
});