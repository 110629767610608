import serviceLinks from 'common/Links.json';
import axios from 'utils/axios';

class PromotionCodeService {
  static async checkPromoCode(promoCode) {
    const url = serviceLinks['PromoCodes']['check'].url;

    return await axios.post(url, { code: promoCode });
  }

  static async getPromoCodes() {
    const url = serviceLinks['PromoCodes']['list'].url;

    return await axios.get(url);
  }

  static async createPromoCode(payload) {
    const url = serviceLinks['PromoCodes']['create'].url;

    return await axios.post(url, payload);
  }

  static async updatePromoCode(id, payload) {
    const url = serviceLinks['PromoCodes']['update'].url.replace('{id}', id);

    return await axios.put(url, payload);
  }

  static async deletePromoCode(id) {
    const url = serviceLinks['PromoCodes']['delete'].url.replace('{id}', id);

    return await axios.delete(url);
  }
}

export default PromotionCodeService;
