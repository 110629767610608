import React from "react";
import { styled,Box, Typography } from "@mui/material";
import HomepageLayout from "layouts/frontOffice/HomePageLayout";
import { Stack, useMediaQuery, Grid, CardMedia, Card, CardContent, Paper, TextField, IconButton, Button, DialogActions, Dialog, DialogTitle, DialogContent } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor:"white", 
    marginBottom:"100px",
  borderRadius: "15px", 
  border: "3px solid transparent",  
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",  
  position: 'relative',
  
  });
const LegalNotice = () => {
      const navigate = useNavigate();
    
     const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <HomepageLayout>
  <Container style={{ maxWidth: 800, marginBottom: "80px" }}>
    
      <IconButton
        onClick={() => navigate('/')}
        sx={{
          position: 'absolute', 
          top: 0, 
          left: 0, 
          margin: '15px',
          
        }}
      >
        <ArrowBackIcon />
      </IconButton>
    
    
    <Stack
      direction="row"
      sx={{
        paddingTop: isMobile ? "30px" : "30px",
        marginBottom: "5px",
        width: isMobile ? "90%" : "90%",
      }}
    >
      <Box sx={{ padding: "40px 0", textAlign: "justify" }}>
        <Typography variant="h2" sx={{ marginBottom: "50px", textAlign: "center", color: '#F066B5' }}>
          Mentions légales
        </Typography>
          
          <Typography variant="h5" sx={{ marginTop: "20px" , marginBottom:"6px" , textDecoration:"underline"}}>
            1. Éditeur du site
          </Typography>
          <Typography variant="body1">
          Le site <strong >avektoi</strong> est édité par <strong>Tendanz Courtage</strong>, société par actions simplifiée unipersonnelle (SASU) au capital de <strong>1 000 euros</strong>, immatriculée au <strong>Registre du Commerce et des Sociétés de Nanterre</strong> sous le numéro <strong>928 838 945</strong>
          </Typography>
          <ul>
          <li><strong>Siège social : </strong> 66 Avenue des Champs-Élysées, 75008 Paris
          </li>
          <li><strong>Numéro de TVA intracommunautaire : </strong> FR78928838945
          </li>
          <li>
          <strong>Directeur de la publication : </strong> Jean Brunet, en qualité de Président

          </li>
          </ul>
         
          <Typography variant="h5" sx={{ marginTop: "20px" , marginBottom:"6px" , textDecoration:"underline"}}>
            2. Hébergement du site
          </Typography>
          <Typography variant="body1">
        Le site <strong>avektoi</strong> est hébergé sur des serveurs situés en Suisse, appartenant à la société <strong>Hostpoint</strong>, dont le siège social est situé à : <strong>Neue Jonastrasse 60, 8640 Rapperswil-Jona, Suisse</strong>.

          </Typography>
          <ul>
            <li>
            <strong> Téléphone : </strong> +41 55 220 63 10</li>
<li><strong> E-mail :</strong>  <a href="mailto:privacy@hostpoint.ch">privacy@hostpoint.ch</a></li>
<li><strong> Site Web :</strong>  <a href="https://www.hostpoint.ch" target="_blank">https://www.hostpoint.ch</a></li>


          
          </ul>
          <Typography variant="body1">
          Le site <strong>avektoi</strong> est également hébergé par <strong>Amazon Web Services LLC</strong>, dont le siège social est situé à : <strong>P.O. Box 81226,
          WA 98108-1226 Seattle</strong>

          </Typography>
          <ul>
            <li>
            <strong> Téléphone : </strong> (206) 266-4064</li>
<li><strong> E-mail :</strong>  <a href="https://aws.amazon.com/fr/contact-us/">https://aws.amazon.com/fr/contact-us/</a></li>
<li><strong> Site Web :</strong>  <a href="http://aws.amazon.com" target="_blank">http://aws.amazon.com</a></li>


          
          </ul>
          <Typography>
          </Typography>
          <Typography variant="h5" sx={{ marginTop: "20px" , marginBottom:"6px" , textDecoration:"underline"}}>
            3. Accès au site
          </Typography>
          <Typography variant="body1">
          L'accès au site est <strong>gratuit </strong>(hors frais de connexion à Internet) et disponible <strong>24h/24 et 7j/7</strong>, sauf en cas de <strong>maintenance, de pannes techniques ou de force majeure.</strong>


          </Typography>
          <Typography variant="h5" sx={{ marginTop: "20px" , marginBottom:"6px" , textDecoration:"underline"}}>
            4. Propriété intellectuelle

          </Typography>
          <Typography variant="body1">
          L’ensemble des éléments du site <strong>(contenus, textes, images, marques, logos, etc.)</strong> est protégé par la législation en vigueur sur la propriété intellectuelle. Ces éléments sont la propriété exclusive de <strong>Tendanz Courtage</strong> ou font l’objet d’une autorisation d’utilisation. <strong>Toute reproduction, diffusion ou exploitation, sans autorisation expresse et préalable, est interdite.</strong>
          </Typography>
         
          <Typography variant="h5" sx={{ marginTop: "20px" , marginBottom:"6px" , textDecoration:"underline"}}>
            5. Liens hypertextes et cookies
          </Typography>
          <Typography variant="body1">
          Le site <strong>avektoi</strong> peut contenir des liens vers des sites tiers. <strong>Tendanz Courtage </strong>décline toute responsabilité quant aux contenus de ces sites externes.

Des <strong>cookies</strong> peuvent être déposés sur votre terminal lors de la navigation sur le site. Ces cookies permettent notamment d'optimiser votre expérience utilisateur et d'effectuer des mesures d’audience. Vous pouvez configurer votre navigateur pour gérer ces cookies ou les refuser.

          </Typography>
          <Typography variant="h5" sx={{ marginTop: "20px" , marginBottom:"6px" , textDecoration:"underline"}}>
            6. Protection des données personnelles

          </Typography>
          <Typography variant="body1">
          Conformément à la <strong>loi Informatique et Libertés </strong>et au <strong>Règlement Général sur la Protection des Données (RGPD)</strong>, vous disposez d’un droit d’<strong>accès, de rectification, de suppression et d’opposition</strong> sur les données personnelles vous concernant.

Pour exercer ces droits, vous pouvez contacter <strong>Tendanz Courtage </strong>:          </Typography>
<ul>
          <li><strong>Par email :</strong> <a href="mailto:contact@avektoi.fr" >
          contact@avektoi.fr
          </a>
          </li>
          <li><strong>Par courrier : </strong>   <a href="https://www.google.com/maps?q=66+AVENUE+DES+CHAMPS+ELYSEES+75008+PARIS" target="_blank" rel="noopener noreferrer" style={{color:"inherit" , textDecoration:"none"}}>
          66 avenue des Champs Elysées 75008 Paris
          </a>
          </li>
          
          </ul>

          <Typography variant="h5" sx={{ marginTop: "20px" , marginBottom:"6px" , textDecoration:"underline"}}>
            7. Loi applicable et juridiction compétente


          </Typography>
          <Typography variant="body1">
          Les présentes mentions légales sont régies par le  <strong>droit français </strong>. Tout litige relatif à leur interprétation ou à leur application sera de la compétence exclusive des  <strong>tribunaux français. </strong>

</Typography>
         
<Typography variant="h5" sx={{ marginTop: "20px" , marginBottom:"6px" , textDecoration:"underline"}}>
8. Modifications des mentions légales

          </Typography>
          <Typography variant="body1">
          <strong>Tendanz Courtage </strong> se réserve le droit de modifier les présentes mentions légales à tout moment, sans préavis.


          </Typography>
        </Box>
        </Stack>
        </Container>

    </HomepageLayout>
  );
};

export default LegalNotice;
