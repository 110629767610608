import React, { useEffect, useRef, useState } from 'react';
import { styled, Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import HomePageLayout from 'layouts/frontOffice/HomePageLayout';
import { Stack, useMediaQuery, Grid, CardMedia, Card, CardContent, Paper, TextField, IconButton, Button, DialogActions, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Constants from 'common/Constants';
import { Editor } from '@tinymce/tinymce-react';
import dateFormat, { masks } from "dateformat";
const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const BlogListComponent = () => {
    const { t } = useTranslation();
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        try {
            const response = await fetch(Constants.APP_API_BASE_URL + '/blog');
            const data = await response.json();
            setPosts(data);
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    return (
        <Grid container spacing={2}>
            <Container id="AdminPanel">
                <Grid container spacing={2}>
                    {posts.map((post) => (
                        <Grid item xs={12} md={12} key={post.id}>
                            <Card>
                                <CardContent style={{ textAlign: 'left' }}>
                                    <Grid container spacing={3}>
                                        <Grid item md={3}>
                                            {post.image_url && (
                                                <CardMedia
                                                    component="img"
                                                    image={post.image_url}
                                                    alt={post.title}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item md={9}>
                                            <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>{post.title}</div>
                                            <div style={{ marginBottom: 15 }}><small>{t('blog.blog_posted_date')}: {dateFormat(post.created_at, ' HH:MM dd-mm-yyyy')}</small></div>
                                            <div style={{ textAlign: 'right' }}><Link to={`/blog/${post.id}`}>{t('blog.read_more')}</Link></div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container >
        </Grid >
    );
};

export default BlogListComponent;
