import React, { useEffect, useState } from 'react';
import { Stack, styled } from '@mui/system';
import InputComponent from '../../../../components/InputComponent';
import { useTranslation } from 'react-i18next';
import userAPI from '../../../../services/AvektoiAPIClient';
import { Button, Grid, Typography, Box } from '@mui/material';
import Constants from 'common/Constants';
import Loader from 'components/Loader';
import IconButton from 'components/@extended/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { openSnackbar } from 'api/snackbar';

export const Instructions = styled('p')({
  fontSize: '16px',
  color: '#675f63',
  textAlign: 'center',
  marginBottom: '20px',
  maxWidth: '306px',
  margin: '0 auto 20px',
});

const VerifyPhoneStep = ({ nextStep, prevStep }) => {
  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [timer, setTimer] = useState(0);
  const [attempt, setAttempt] = useState(0);

  const initialTimer = 10; // 10 seconds in milliseconds
  const maxTimer = 1200;

  useEffect(() => {
    let countdown;

    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }

    return () => {
      if (countdown) clearInterval(countdown);
    };
  }, [timer]);

  useEffect(() => {
    let clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));
    setPhoneNumber(clientObject.phone_number);
    if (clientObject.phone_number && clientObject.isPhoneVerified) {
      nextStep({ ...clientObject });
      return;
    }
  }, [nextStep]);

  const handleNextClick = async () => {
    try {
      const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) || {};
      const phoneNumber = clientObject.phone_number;
      setIsLoading(true);
      const response = await userAPI.verifyPhone(phoneNumber, parseInt(verificationCode));
      setIsLoading(false);
      let isPhoneVerified = false;
      if (response === undefined) {
        setError('');
        isPhoneVerified = true;
        nextStep();
      } else if (response.message === 'Invalid phone number or verification code') {
        setError('invalid');
      } else {
        setError('creation_failed');
      }
      sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify({ ...clientObject, isPhoneVerified }));
    } catch (error) {
      setError('invalid');
    }
  };

  const resendToken = async () => {
    try {
      if (timer > 0) {
        return;
      }

      setIsLoading(true);
      const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) || {};
      const phoneNumber = clientObject.phone_number;

      await userAPI.sendPhoneVerification(phoneNumber);
      setIsLoading(false);

      openSnackbar({
        open: true,
        message: t('signup.step3.tokenResent'),
        variant: 'alert',

        alert: {
          color: 'success',
        },
      });

      // Reset the timer and attempt counter
      const newAttempt = attempt + 1;
      setAttempt(newAttempt);
      setTimer(Math.min(initialTimer * Math.pow(2, newAttempt - 1), maxTimer));
    } catch (error) {
      console.error('Error resending token:', error);
    }
  };

  const handleBackClick = () => {
    // Remove existing phone number from session storage
    let clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));
    delete clientObject.phone_number;
    sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify(clientObject));

    // Navigate back to the previous step
    prevStep();
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <IconButton onClick={handleBackClick}>
        <ArrowBackIcon style={{ width: 30, height: 30 }} />
      </IconButton>
      <Box>
        {isLoading && <Loader />}
        {/*<TypeSwitcher selection={selection} toggleSelection={toggleSelection}  homePage={true}/>*/}

        <Typography variant="h2" style={{ marginTop: 20 }}>
          {t('signup.step3.title1')}
        </Typography>
        <div style={{ marginTop: 30 }}>
          <Typography variant="h5">{t('signup.step3.instructions1')}</Typography>
          <Typography variant="h5">{phoneNumber}</Typography>
          <Grid container>
            <Grid item xs={12}>
              <div style={{ maxWidth: 300 }}>
                <InputComponent
                  label={t('signup.step3.verificationCode')}
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  error={error === 'invalid'}
                  helperText={error === 'invalid' ? t('signup.step3.errorInvalidInput') : ''}
                />
              </div>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="center" columnGap={2} style={{ marginBottom: 20 }}>
            <Button
              size="large"
              disabled={!verificationCode}
              variant="contained"
              color="primary"
              style={{ width: 250 }}
              onClick={handleNextClick}
            >
              {t('buttons.confirm')}
            </Button>
          </Stack>
          <Instructions>
            {t('signup.step3.haventReceivedCode')}

            <Button
              size="large"
              disabled={timer > 0}
              variant="text"
              color="primary"
              style={{ width: 250, fontWeight: 'bold', marginTop: 4 }}
              onClick={resendToken}
            >
              {timer > 0 ? `${timer}s` : t('signup.step3.resendCode')}
            </Button>
          </Instructions>
        </div>
      </Box>
    </Box>
  );
};

export default VerifyPhoneStep;
