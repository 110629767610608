import ReactGA from 'react-ga4';
import Constants from 'common/Constants';
import { Cookies } from 'react-cookie';

const cookie = new Cookies();

export const initGA = () => {
  ReactGA.initialize(Constants.GOOGLE_ANALYTICS_ID);
};

export const trackPageView = (path) => {
  const cookieConsent = cookie.get('cookie_consent');

  if (cookieConsent) {
    ReactGA.send({ hitType: 'pageview', page: path });
  }
};

export const trackEvent = ({ category, action, label }) => {
  const cookieConsent = cookie.get('cookie_consent');

  if (cookieConsent) {
    ReactGA.event({ category, action, label });
  }
};
