import axios from "axios"
import Links from "../common/Links.json"
import {APP_API_BASE_URL, ACCESS_API_KEY_SAMPLE} from "../common/Constants";
import ApiHelper from "../common/ApiHelper";
import Headers from "../common/types/Headers";

const headers = new Headers();

const login = async (username, password) => {
    try {
        const response = await axios.post(Links.login, {"email": username, "password": password});
        return response.data;
    } catch (error) {
        return error.response.data.message;
    }
}

const adminlogin = async (username, password) => {
    try {
        const response = await axios.post(Links.adminlogin, {"username": username, "password": password});
        return response.data;
    } catch (error) {
        return error.response.data.message;
    }
}

const resetPassword = async (username) => {
    try {
        const response = await axios.post(APP_API_BASE_URL + Links.changePasswordRequest, {"email": username})
        return response.data
    } catch (error) {
        throw error.response.data.message
    }
}

const confirmResetPassword = async (username, password) => {
    try {
        const response = await axios.post(APP_API_BASE_URL + Links.changePasswordConfirm, {"email": username, "newPassword": password})
        return response.data
    } catch (error) {
        return error.response.data.message
    }
}

const confirmToken = async (email, token) => {
    try {
        const response = await axios.post(APP_API_BASE_URL + Links.confirmToken, {"email": email, "token": token})
        return response.data
    } catch (error) {
        return error.response.data.message
    }
}

const emailVerification = async (username, token) => {
    try {
        const response = await axios.post(APP_API_BASE_URL + Links.emailVerification, {"email": username, "token": token})
        return response.data
    } catch (error) {
        console.log("email verification error: ", error.response.data.message)
    }
}

const resendToken = async (username) => {
    try {
        const response = await axios.post(APP_API_BASE_URL + Links.resendToken, {"email": username})
        return response.data
    } catch (error) {
        return error.response.data.message
    }
}

const createClient = (clientObject) => {
    const url = Links.createClient;
    const hdrs = headers.GetHeaders();

    return new Promise((resolve, reject) => {
        console.log('createClient: Sending POST request to', url, 'with data:', clientObject);
        ApiHelper('POST', url, clientObject, hdrs)
            .then(response => {
                console.log('createClient: Response received', response);
                resolve(response);
            })
            .catch(err => {
                console.error('createClient: Error occurred', err.response ? err.response.data : err.message);
                reject(err.response ? err.response.data.message : err.data.message);
            });
    });
};

const updateClient = async (id, data) => {
    const url = `${APP_API_BASE_URL}${Links.updateClient.replace('{id}', id)}`;
    try {
        console.log(`Sending PUT request to ${url} with data:`, data);
        const response = await axios.put(url, data, {
            headers: {
                'access-api-key': ACCESS_API_KEY_SAMPLE,
                'Content-Type': 'application/json'
            }
        });
        console.log('Response data:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error response:', error.response ? error.response.data : error.message);
        return error.response ? error.response.data.message : error.message;
    }
};

const checkPhone = async (phoneNumber) => {
    try {
        const response = await axios.get(`${APP_API_BASE_URL}${Links.checkPhone.replace('{phone-number}', phoneNumber)}`, {
            headers: {
                'access-api-key': ACCESS_API_KEY_SAMPLE,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        return error.response ? error.response.data.message : error.message;
    }
};

const sendPhoneVerification = async (phoneNumber) => {
    try {
        const response = await axios.post(`${APP_API_BASE_URL}${Links.sendPhoneVerification}`, {"phone_number": phoneNumber}, {
            headers: {
                'access-api-key': ACCESS_API_KEY_SAMPLE,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        return error.response ? error.response.data.message : error.message;
    }
};

const verifyPhone = (phoneNumber, code) => {
    const url = Links.verifyPhone;
    const data = {"phone_number": phoneNumber, "code": code};
    let hdrs = headers.GetHeaders();

    return new Promise((resolve, reject) => {
        ApiHelper('POST', url, data, hdrs)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

const checkEmail = async (email) => {
    try {
        const response = await axios.get(`${APP_API_BASE_URL}${Links.checkEmail.replace('{email}', email)}`, {
            headers: {
                'access-api-key': ACCESS_API_KEY_SAMPLE,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        return error.response ? error.response.data.message : error.message;
    }
};

const sendEmailVerification = async (email) => {
    try {
        const response = await axios.post(`${APP_API_BASE_URL}${Links.requestPasswordReset}`, {"email": email}, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data.message;
    } catch (error) {
        return error.response ? error.response.data.message : error.message;
    }
};

const verifyEmail = (email, code) => {
    const url = Links.verifyEmail;
    const data = {"email": email, "verification_code": code};
    let hdrs = headers.GetHeaders();

    return new Promise((resolve, reject) => {
        ApiHelper('POST', url, data, hdrs)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

const getPoliciesByClient = async (clientID) => {
    try {
        const response = await axios.get(`${APP_API_BASE_URL}${Links.getPoliciesByClient.replace('{client_id}', clientID)}`, {
            headers: {
                'access-api-key': ACCESS_API_KEY_SAMPLE,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        return error.response ? error.response.data.message : error.message;
    }
};

const getPolicyDetail = async (policyID) => {
    try {
        const response = await axios.get(`${APP_API_BASE_URL}${Links.getPolicyDetail.replace('{policy_id}', policyID)}`, {
            headers: {
                'access-api-key': ACCESS_API_KEY_SAMPLE,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        return error.response ? error.response.data.message : error.message;
    }
};

export default {
    login,
    resetPassword,
    confirmResetPassword,
    emailVerification,
    confirmToken,
    resendToken,
    createClient,
    updateClient,
    checkPhone,
    sendPhoneVerification,
    verifyPhone,
    checkEmail,
    verifyEmail,
    sendEmailVerification,
    getPoliciesByClient,
    getPolicyDetail
}
