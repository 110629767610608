import PropTypes from 'prop-types';
import { Fragment, useEffect, useMemo, useState } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';

// third-party
import { DndProvider } from 'react-dnd';
import { isMobile } from 'react-device-detect';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedMinMaxValues,
  getFacetedUniqueValues,
  getPaginationRowModel,
  getSortedRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  flexRender,
  useReactTable,
  sortingFns
} from '@tanstack/react-table';
import { compareItems, rankItem } from '@tanstack/match-sorter-utils';

// project import
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import IconButton from 'components/@extended/IconButton';
import ExpandingClaimDetail from 'sections/tables/react-table/ExpandingClaimDetail';

import {
  CSVExport,
  DebouncedInput,
  EmptyTable,
  Filter,
  HeaderSort,
  RowSelection,
  TablePagination,
  RowEditable,
  DraggableColumnHeader,
} from 'components/third-party/react-table';

//assets
import { ArrowDown2, ArrowRight2, CloseCircle, Command, Edit2, Send, TableDocument } from 'iconsax-react';

import ClaimService from '../../../services/ClaimService';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tabs, Tab, Chip } from '@mui/material';
import CreateClaimFormLayout from 'layouts/frontOffice/CreateClaimFormLayout/CreateClaimFormLayout';

// i18n
import { useTranslation } from 'react-i18next';
import NewButtonComponent from 'components/NewButton';

export const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta(itemRank);
  return itemRank.passed;
};

export const fuzzySort = (rowA, rowB, columnId) => {
  let dir = 0;
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(rowA.columnFiltersMeta[columnId], rowB.columnFiltersMeta[columnId]);
  }
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

// ==============================|| REACT TABLE - EDIT ACTION ||============================== //

function EditAction({ row, table }) {
  const { t } = useTranslation();
  const meta = table?.options?.meta;
  const setSelectedRow = (e) => {
    meta?.setSelectedRow((old) => ({
      ...old,
      [row.id]: !old[row.id]
    }));
    meta?.revertData(row.index, e?.currentTarget.name === 'cancel');
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {meta?.selectedRow[row.id] && (
        <Tooltip title={t('claims.cancel')}>
          <IconButton color="error" name="cancel" onClick={setSelectedRow}>
            <CloseCircle size="15" variant="Outline" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={meta?.selectedRow[row.id] ? t('claims.save') : t('claims.edit')}>
        <IconButton color={meta?.selectedRow[row.id] ? 'success' : 'primary'} onClick={setSelectedRow}>
          {meta?.selectedRow[row.id] ? <Send size="15" variant="Bold" /> : <Edit2 variant="Outline" />}
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ defaultColumns, data, onRefresh, onFiltered, fullData }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [grouping, setGrouping] = useState([]);
  const [columns] = useState(() => [...defaultColumns]);
  const [columnOrder, setColumnOrder] = useState(columns.map((column) => column.id));
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isRedirectToNewClaim, setIsRedirectToNewClaim] = useState(false);
  const [claimLimits, setClaimLimits] = useState({ maxClaims: 0, currentClaims: 0 });

  const getClaimLimits = async () => {
    try {
      const response = await ClaimService.getPolicyClaimLimits();
      setClaimLimits({
        maxClaims: parseInt(response.maxClaims, 10),
        currentClaims: parseInt(response.currentClaims, 10),
      });
    } catch (error) {
      console.error('Error fetching claim limits:', error);
    }
  };

  useEffect(() => {
    const fetchClaimLimits = async () => {
      try {
        const response = await ClaimService.getPolicyClaimLimits();
        console.log('response', response);
        setClaimLimits({
          maxClaims: parseInt(response.maxClaims || 0, 10),
          currentClaims: parseInt(response.currentClaims || 0, 10),
        });
      } catch (error) {
        console.error('Error fetching claim limits:', error);
        setClaimLimits({ maxClaims: 0, currentClaims: 0 }); // Fallback
      }
    };

    fetchClaimLimits();
  }, []);


  const table = useReactTable({
    data,
    columns,
    defaultColumn: { cell: RowEditable },
    state: {
      rowSelection,
      columnFilters,
      globalFilter,
      sorting,
      grouping,
      columnOrder,
      columnVisibility
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onGroupingChange: setGrouping,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    getRowCanExpand: () => true,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    globalFilterFn: fuzzyFilter,
    getRowId: (row) => row.id.toString(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true
  });

  useEffect(() => setColumnVisibility({ id: false, role: false, contact: false, country: false, progress: false }), []);

  const backColor = alpha(theme.palette.primary.lighter, 0.1);

  let headers = [];
  table.getVisibleLeafColumns().map(
    (columns) =>
      columns.columnDef.accessorKey &&
      headers.push({
        label: typeof columns.columnDef.header === 'string' ? columns.columnDef.header : '#',
        key: columns.columnDef.accessorKey
      })
  );

  const openCreateNewClaimPage = () => {
    setIsRedirectToNewClaim(true);
  };

  const handleClose = () => {
    setIsRedirectToNewClaim(false);
  };

  const renderCreateClaimDialog = () => (
    <>
      <Dialog open={isRedirectToNewClaim} onClose={handleClose} size="lg">
        <Box sx={{ p: 1, py: 1.5 }}>
          <DialogTitle>
            <Box fontWeight="bold">{t('claims.create_new_claim')}</Box>
          </DialogTitle>
          <DialogContent>
            <CreateClaimFormLayout onRefresh={onRefresh} onClose={handleClose}/>
          </DialogContent>
          
        </Box>
      </Dialog>
    </>
  );
  const tabs = [
    { label: t('claims.tabs.all_claims'), status_ids: [] },  
    { label: "En cours", status_ids: ['OPEN'] },
    { label: "Accepté", status_ids: ['APPROVED'] },
    { label: t('claims.tabs.rejected'), status_ids: ['REJECTED'] },
    { label: t('claims.tabs.awaiting_client'), status_ids: ['AWAITING_CLIENT'] },
    { label: 'Annulé', status_ids: ['CANCELLED'] },
  ];
  

  const handleTabChange = (event, tabIndex) => {
    setCurrentTabIndex(tabIndex);
    onFiltered(tabs[tabIndex].status_ids);
  };

  const countStatus = (status_ids) => {
    if (status_ids.length === 0) return fullData.length;
    return fullData.filter((item) => status_ids.includes(item.claim_status_id)).length;
  };

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  return (
    <MainCard content={false}>
      {renderCreateClaimDialog()}
      <Tabs value={currentTabIndex} onChange={handleTabChange} aria-label="basic tabs example">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            icon={<Chip label={countStatus(tab.status_ids)} color="primary" variant="light" />}
            iconPosition="end"
            style={{textTransform:"none"}}
          />
        ))}
      </Tabs>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        justifyContent="space-between"
        sx={{ padding: 2, ...(matchDownSM && { '& .MuiOutlinedInput-root, & .MuiFormControl-root': { width: '100%' } }) }}
      >
        <DebouncedInput
          value={globalFilter ?? ''}
          onFilterChange={(value) => setGlobalFilter(String(value))}
          placeholder={t('claims.search_claims')}
        />
        <Stack direction="row" spacing={2} alignItems="center" sx={{ width: { xs: '100%', sm: 'auto' } }}>
          <NewButtonComponent onClick={openCreateNewClaimPage}
           label={t('claims.create_new_claim')}
          />
            
          
        </Stack>
      </Stack>

      <ScrollX>
        <RowSelection selected={Object.keys(rowSelection).length} />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    if (header.column.columnDef.meta !== undefined && header.column.getCanSort()) {
                      Object.assign(header.column.columnDef.meta, {
                        className: header.column.columnDef.meta.className + ' cursor-pointer prevent-select'
                      });
                    }

                    return (
                      <DraggableColumnHeader key={header.id} header={header} table={table}>
                        <>
                          {header.isPlaceholder ? null : (
                            <Stack direction="row" spacing={1} alignItems="center">
                              {header.column.getCanGroup() && (
                                <IconButton
                                  color={header.column.getIsGrouped() ? 'error' : 'primary'}
                                  onClick={header.column.getToggleGroupingHandler()}
                                  size="small"
                                  sx={{ p: 0, width: 24, height: 24, fontSize: '1rem', mr: 0.75 }}
                                >
                                  {header.column.getIsGrouped() ? (
                                    <Command size="32" color="#FF8A65" variant="Bold" />
                                  ) : (
                                    <TableDocument size="32" variant="Outline" />
                                  )}
                                </IconButton>
                              )}
                              <Box>{flexRender(header.column.columnDef.header, header.getContext())}</Box>
                              {header.column.getCanSort() && <HeaderSort column={header.column} sort />}
                            </Stack>
                          )}
                        </>
                      </DraggableColumnHeader>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell key={header.id} {...header.column.columnDef.meta}>
                      {header.column.getCanFilter() && <Filter column={header.column} table={table} />}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <TableRow row={row} reorderRow={() => {}}>
                      <>
                        {row.getVisibleCells().map((cell) => {
                          let bgcolor = 'background.paper';
                          if (cell.getIsGrouped()) bgcolor = 'primary.lighter';
                          if (cell.getIsAggregated()) bgcolor = 'warning.lighter';
                          if (cell.getIsPlaceholder()) bgcolor = 'error.lighter';

                          if (cell.column.columnDef.meta !== undefined && cell.column.getCanSort()) {
                            Object.assign(cell.column.columnDef.meta, {
                              style: { backgroundColor: bgcolor }
                            });
                          }

                          return (
                            <TableCell
                              key={cell.id}
                              {...cell.column.columnDef.meta}
                              sx={{ bgcolor }}
                              {...(cell.getIsGrouped() &&
                                cell.column.columnDef.meta === undefined && {
                                style: { backgroundColor: bgcolor }
                              })}
                            >
                              {cell.getIsGrouped() ? (
                                <Stack direction="row" alignItems="center" spacing={0.5}>
                                  <IconButton
                                    color="secondary"
                                    onClick={row.getToggleExpandedHandler()}
                                    size="small"
                                    sx={{ p: 0, width: 24, height: 24 }}
                                  >
                                    {row.getIsExpanded() ? (
                                      <ArrowDown2 size="32" variant="Outline" />
                                    ) : (
                                      <ArrowRight2 size="32" variant="Outline" />
                                    )}
                                  </IconButton>
                                  <Box>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Box> <Box>({row.subRows.length})</Box>
                                </Stack>
                              ) : cell.getIsAggregated() ? (
                                flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())
                              ) : cell.getIsPlaceholder() ? null : (
                                flexRender(cell.column.columnDef.cell, cell.getContext())
                              )}
                            </TableCell>
                          );
                        })}
                      </>
                    </TableRow>
                    {row.getIsExpanded() && !row.getIsGrouped() && (
                      <TableRow sx={{ bgcolor: backColor, '&:hover': { bgcolor: `${backColor} !important` } }}>
                        <TableCell colSpan={row.getVisibleCells().length + 2}>
                          <ExpandingClaimDetail data={row.original} />
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={table.getAllColumns().length}>
                    <EmptyTable msg={t('claims.no_data')} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              {table.getFooterGroups().map((footerGroup) => (
                <TableRow key={footerGroup.id}>         
                  {footerGroup.headers.map((footer) => (
                    <TableCell key={footer.id} {...footer.column.columnDef.meta}>
                      {footer.isPlaceholder ? null : flexRender(footer.column.columnDef.header, footer.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableFooter>
          </Table>
        </TableContainer>
        <Divider />
        <Box sx={{ p: 2 }}>
          <TablePagination
            {...{
              setPageSize: table.setPageSize,
              setPageIndex: table.setPageIndex,
              getState: table.getState,
              getPageCount: table.getPageCount
            }}
          />
        </Box>
      </ScrollX>
    </MainCard>
  );
}

// ==============================|| REACT TABLE - UMBRELLA ||============================== //

export default function MyClaimPage() {
  const { t } = useTranslation();
  
  const formatCurrency = (value) => {
    if (value === null || value === undefined) {
      return '—'; 
    }
    const formattedValue = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    }).format(value);
    
    return formattedValue.replace(/\u202F/g, '\u00A0');
  };
  const columns = useMemo(
    () => [
      {
        id: 'expander',
        enableGrouping: false,
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <IconButton color={row.getIsExpanded() ? 'primary' : 'secondary'} onClick={row.getToggleExpandedHandler()} size="small">
              {row.getIsExpanded() ? <ArrowDown2 size="32" variant="Outline" /> : <ArrowRight2 size="32" variant="Outline" />}
            </IconButton>
          ) : (
            <IconButton color="secondary" size="small" disabled>
              <CloseCircle />
            </IconButton>
          );
        }
      },
     
      {
        id: 'policy_amendment_number',
        header: t('claims.policy_number'),
        footer: t('claims.policy_number'),
        accessorKey: 'policy_amendment_number',
        dataType: 'code',
        enableGrouping: false
      },
      {
        id: 'claim_reference_number',
        header: t('claims.claim_number'),
        footer: t('claims.claim_number'),
        accessorKey: 'claim_reference_number',
        dataType: 'code',
        enableGrouping: false
      },
     
      {
        id: 'amount',
        header: t('claims.request_amount'),
        footer: t('claims.request_amount'),
        accessorKey: 'request_amount',
        cell: ({ getValue }) => {
          const value = getValue();
          
          return <span>{formatCurrency(value)}</span>;
        },
        dataType: 'text', 
        enableGrouping: false
      },
      {
        id: 'claim_status_id',
        header: t('claims.claim_status'),
        footer: t('claims.claim_status'),
        accessorKey: 'claim_status_id',
        dataType: 'select',
        enableColumnFilter: false,
        enableGrouping: false,
        cell: ({ row }) => {
          const status = row.original.claim_status_id;
      
          let statusText = '';
          let chipColor = '';

          switch (status) {
            case 'OPEN':
              statusText = 'En cours';
              chipColor = 'primary';
              break;
            case 'REJECTED':
              statusText = 'Refusé';
              chipColor = 'error';
              break;
            case 'APPROVED':
              statusText = 'Accepté';
              chipColor = 'success';
              break;
            case 'AWAITING_CLIENT':
              statusText = 'En attente';
              chipColor = 'secondary';
              break;
              case 'CANCELLED':
                statusText = 'Annulé';
                chipColor = 'info';
                break;
            default:
              statusText = 'Inconnu'; 
              chipColor = 'default';
              break;
          }
      
          return (
            <Box 
              display="flex" 
              justifyContent="center" 
              alignItems="center" 
              height="100%" 
              width="100%"
            >
              <Chip label={statusText} color={chipColor} variant='light'/>
            </Box>
          );
        },},
      {
        id: 'declaration_date',
        header: t('claims.create_time'),
        footer: t('claims.create_time'),
        accessorKey: 'created_at',
        dataType: 'datetime',
        enableGrouping: false
      }
    ],
    [t]
  );

  const [claimsData, setClaimsData] = useState([]);
  const [claimsDataFull, setClaimsDataFull] = useState([]);

  useEffect(() => {
      getClaimListData();
  }, []);

  function getClaimListData() {
    ClaimService.getMyClaimList()
      .then((response) => {
        if (response) {
          setClaimsData(response);
          setClaimsDataFull(response);
        }
      })
      .catch((err) => {
        console.log('claims list error:', err);
      });
  }

  const onRefresh = () => {
    getClaimListData();
  };

  const onFiltered = (status_ids) => {
    if (status_ids.length === 0) setClaimsData(claimsDataFull);
    else setClaimsData(claimsDataFull.filter((claim) => status_ids.includes(claim.claim_status_id)));
  };

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <ReactTable {...{ data: claimsData, defaultColumns: columns, onRefresh, onFiltered, fullData: claimsDataFull }} />
    </DndProvider>
  );
}

EditAction.propTypes = { row: PropTypes.object, table: PropTypes.object };

ReactTable.propTypes = { defaultColumns: PropTypes.array, data: PropTypes.array, onRefresh: PropTypes.func };

