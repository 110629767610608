import axios from 'axios';
import Constants from 'common/Constants';

export const uploadDocuments = async (data, { claim_id, complaint_id, client_id, type } = {}) => {
  const accessToken = localStorage.getItem('CLIENT_AUTH_TOKEN');

  let formData = new FormData();
  data.forEach((item) => {
    formData.append('files', item);
    if (claim_id) formData.append('claim_id', claim_id);
    if (complaint_id) formData.append('complaint_id', complaint_id);
    if (client_id) formData.append('client_id', client_id);
    if (type) formData.append('type', type);
  });

  const response = await axios.post(Constants.APP_API_BASE_URL + '/documents/upload', formData, {
    headers: {
      'content-type': 'multipart/form-data',
      'access-api-key': Constants.ACCESS_API_KEY_SAMPLE,
      'access-auth-token': accessToken,
    },
  });
  return response;
};
