import serviceLinks from '../common/Links.json';
import ApiHelper from '../common/ApiHelper';
import Headers from '../common/types/Headers';
import Constants from '../common/Constants';
import axios from 'utils/axios';

const headers = new Headers();

class PoliciesService {
  static getMyPoliciesList() {
    var clientId = Constants.CLIENT_ID_SAMPLE; // TODO: Get from loggedin client
    if (clientId) {
      const url = serviceLinks.getMyPoliciesList;
      let params = {};
      let hdrs = headers.GetHeaders();
      return new Promise((resolve, reject) => {
        ApiHelper('GET', url, params, hdrs)
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  static getAllPoliciesList() {
    var clientId = Constants.CLIENT_ID_SAMPLE; // TODO: Get from loggedin client
    if (clientId) {
      const url = serviceLinks['Policies']['all'].url;
      let params = {};
      let hdrs = headers.GetHeaders();
      return new Promise((resolve, reject) => {
        ApiHelper('GET', url, params, hdrs)
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  static getPolicyDetailByPolicyId(policyId) {
    if (policyId) {
      const url = serviceLinks.getPolicyDetailByPolicyId + policyId;
      let params = {};
      let hdrs = headers.GetHeaders();
      return new Promise((resolve, reject) => {
        ApiHelper('GET', url, params, hdrs)
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  static getEventsByPolicyId(clientId, policyId) {
    if (clientId && policyId) {
      const url = `${serviceLinks.Policies.getEventsByPolicyId.url}/${clientId}/${policyId}`;
      let params = {};
      let hdrs = headers.GetHeaders();
      return new Promise((resolve, reject) => {
        ApiHelper('GET', url, params, hdrs)
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  static async sendCancellationRequest(policyId) {
    const url = serviceLinks['Policies']['send-cancellation-request'].url;

    return await axios.post(
      url,
      {
        policy_id: policyId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          business_partner_id: 'a5f5c9f4-d1d8-4c6d-9cde-9e8e38dbb6bb',
        },
      }
    );
  }

  static async cancelPolicy(policyId) {
    const url = serviceLinks['Policies']['cancel'].url;

    return await axios.post(
      url,
      { policy_id: policyId },
      {
        headers: {
          'Content-Type': 'application/json',
          business_partner_id: 'a5f5c9f4-d1d8-4c6d-9cde-9e8e38dbb6bb',
        },
      }
    );
  }
}

export default PoliciesService;
