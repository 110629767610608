import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SignupLayout from "layouts/frontOffice/StyledSignupLayout";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, Step, StepLabel, Stepper, Tooltip, Typography } from "@mui/material";
import ClientInfoStep from "./Register/ClientInfoStep";
import PhoneStep from "./Register/PhoneStep";
import VerifyPhoneStep from "./Register/VerifyPhoneStep";
import EmailStep from "./Register/EmailStep";
import BirthdayStep from "./Register/BirthdayStep";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from '@mui/icons-material/Close';
import CountryStep from "./Register/CountryStep";
import CompanyStep from "./Register/CompanyStep";
import PeopleStep from "./Register/PeopleStep";
import EmployeesStep from "./Register/EmployeesStep";
import { useExplanation } from "hooks/useExplanation";
import { useTranslation } from "react-i18next";
import Constants from "common/Constants";
import { useMediaQuery } from 'react-responsive';
import { SnackbarProvider } from "notistack";

const SignupProcess = () => {
  const isPC = useMediaQuery({ query: '(min-width: 800px)' });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [userType, setUserType] = useState(null);
  const [formData, setFormData] = useState({});
  const [open, setOpen] = React.useState(false);
  const explanation = useExplanation({ step: currentStep, clientType: userType });

  useEffect(() => {
    const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));
    if (clientObject?.client_type) {
      setUserType(clientObject.client_type);
    }
  }, [currentStep]);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const nextStep = (data) => {
    setFormData((prevData) => ({ ...prevData, ...data }));
    setCurrentStep((prevStep) => prevStep + 1);
    convertActiveStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep === 1) navigate("/");
    else setCurrentStep((prevStep) => prevStep - 1);
  };

  const steps = [
    { label: t('signupProcess.provide_information'), description: '' },
    { label: t('signupProcess.account_registered'), description: '' },
    { label: t('signupProcess.additional_information'), description: '' },
    { label: t('signupProcess.complete_registration'), description: '' },
  ];

  const convertActiveStep = (step) => {
    switch (step) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        setActiveStep(0);
        break;
      case 6:
        setActiveStep(2);
        break;
      case 7:
        setActiveStep(3);
        break;
      default:
        setActiveStep(0);
        break;
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <ClientInfoStep nextStep={nextStep} prevStep={prevStep} />;
      case 2:
        return <BirthdayStep nextStep={nextStep} prevStep={prevStep} />;
      case 3:
        return <PhoneStep nextStep={nextStep} prevStep={prevStep} firstName={formData.firstName} />;
      case 4:
        return <VerifyPhoneStep nextStep={nextStep} prevStep={prevStep} />;
      case 5:
        return <EmailStep nextStep={nextStep} prevStep={prevStep} />;
      case 6:
        return userType === "PERSONAL" ? (
          <CountryStep nextStep={nextStep} prevStep={prevStep} />
        ) : (
          <CompanyStep nextStep={nextStep} prevStep={prevStep} />
        );
      case 7:
        return userType === "PERSONAL" ? (
          <PeopleStep nextStep={nextStep} prevStep={prevStep} />
        ) : (
          <EmployeesStep nextStep={nextStep} prevStep={prevStep} />
        );
      default:
        return null;
    }
  };

  return (
    <SnackbarProvider maxSnack={3}>
      <SignupLayout step={currentStep} onPreviousStep={prevStep}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} style={{ marginBottom: 10 }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel>{activeStep === index || isPC ? step.label : ''}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
        
        {renderStep()}

        <Grid
  container
  justifyContent="flex-start"
  sx={{
    position: 'fixed',
    bottom: 0,
    left: 0,
    p: 2, 
    zIndex: 1300, 
    pointerEvents: 'none',
  }}
>
                    <Tooltip title={explanation.question}>
            <IconButton size="large" onClick={handleClickOpen} sx={{pointerEvents: 'auto'}}>
              <HelpIcon />
            </IconButton>
          </Tooltip>
          <Dialog onClose={handleClose} open={open}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
              {explanation.question}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Typography gutterBottom>
                {explanation.answer}
              </Typography>
            </DialogContent>
          </Dialog>
        </Grid>
      </SignupLayout>
    </SnackbarProvider>
  );
};

export default SignupProcess;
