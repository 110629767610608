import React, { useEffect, useState } from 'react';
import { styled, Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import HomePageLayout from 'layouts/frontOffice/HomePageLayout';
import TitleSection from 'components/frontOffice/HomePageComponents/TitleSectionComponent';
import { Stack, useMediaQuery, Grid, Typography } from '@mui/material';
import familyAnimation from '../../assets/homePageAssets/Family.json';
import Lottie from 'lottie-react';
import FeatureCard from 'components/frontOffice/HomePageComponents/FeatureCardComponent';
import Indemnite from 'assets/homePageAssets/Indemnite.json';
import Psychologist from 'assets/homePageAssets/Psychologist.json';
import Administration from 'assets/homePageAssets/administration.json';
import { useNavigate } from 'react-router-dom';
import ServiceCardComponent from 'components/frontOffice/HomePageComponents/ServiceCardComponent';
import Constants from 'common/Constants';
import houseAnimation from '../../assets/homePageAssets/House.json';
import workAnimation from '../../assets/homePageAssets/work.json';
import teamwork from '../../assets/homePageAssets/Teamwork.json';
import Building from '../../assets/homePageAssets/Building.json';
import Business from '../../assets/homePageAssets/Business.json';
import ButtonComponent from 'components/ButtonComponent';
import homepage from 'assets/homePageAssets/homepage2.json';
import background from '../../assets/comingsoon1.png';
import paperwork from '../../assets/homePageAssets/Judge.json';
import { trackEvent } from '../../utils/analytics';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [selection, setSelection] = useState(Constants.CLIENT_TYPE.PERSONAL);
  const toggleSelection = () => {
    setSelection((prevSelection) =>
      prevSelection === Constants.CLIENT_TYPE.PERSONAL
        ? Constants.CLIENT_TYPE.PROFESSIONAL
        : Constants.CLIENT_TYPE.PERSONAL
    );
  };
  const handleNavigateSignUp = () => navigate('/sign-up');

  const handleLoginClick = () => navigate('/sign-up');

  const [clientType, setClientType] = useState('PERSONAL');

  useEffect(() => {
    setClientType('PERSONAL');
    const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));
    sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify({ ...clientObject, client_type: clientType }));
  }, []);

  const handleClientType = (type) => {
    setClientType(type);
    const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) | {};
    sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify({ ...clientObject, client_type: type }));
    navigate('/sign-up');
  };

  return (
    <HomePageLayout handleLoginClick={handleLoginClick}>
      <Container>
        <Stack
          direction="row"
          sx={{
            borderRadius: '50px',
            backgroundImage: `url(${background})`,
            paddingTop: isMobile ? '50px' : '50px',
            paddingBottom: '50px',
            marginBottom: '30px',
            width: isMobile ? '90%' : '85%',
          }}
        >
          <Box sx={{ width: isMobile ? '100%' : '80%', padding: isMobile ? '0 0' : '0 50px' }}>
            <TitleSection
              titleParts={["L'assurance qui protège ", 'votre bien-être']}
              titleSizes={isMobile ? ['1.4rem', '1.4rem'] : ['2.1rem', '2.1rem']}
              titleColors={['#282828', '#F066B5']}
              paragraph={[
                'Face aux épreuves de la vie, nous sommes',
                ' avektoi.',
                ' Un soutien psychologique, financier, juridique et administratif pour alléger votre charge mentale.',
              ]}
              textAlign="left"
              margin={isMobile ? '0px 20px' : '5px 50px'}
              paragraphColors={['#282828', '#F066B5', '#282828']}
              isMobile={isMobile}
            />
            <div style={{ marginTop: '-25px' }}>
              <TitleSection
                button={true}
                titleParts={['Protégez votre bien-être dès aujourd’hui']}
                titleSizes={isMobile ? ['1.1rem'] : ['1.1rem']}
                titleColors={['#F066B5']}
                textAlign="left"
                margin={isMobile ? '0px 20px' : '5px 50px'}
                bulletPoints={true}
                points={[
                  'À partir de 4,1 €/mois',
                  '2 mois gratuits',
                  'Soutien psychologique, indemnisation en 72h et assistance juridique et administrative.',
                ]}
                paragraphColors={['#282828', '#282828', '#282828']}
                isMobile={isMobile}
                underline={true}
              />
            </div>
            <div
              style={{
                marginTop: '-10px',
                display: !isMobile && 'flex',
                justifyContent: !isMobile && 'flex-start',
                marginLeft: !isMobile && '50px',
              }}
            >
              <ButtonComponent
                label="Je protège mon bien-être maintenant"
                width="300px"
                height="40px"
                onClick={() => {
                  handleNavigateSignUp();
                  trackEvent({
                    category: 'Homepage',
                    action: 'sign_up_button',
                    label: 'Je protège mon bien-être maintenant',
                  });
                }}
                borderRadius="50px"
              />
            </div>
          </Box>

          {/*
          <CardComponent
            text={t('homePage.typeSwitcher.professional')}
            icon={professionalAnimation}
            active={clientType === 'PROFESSIONAL'}
            onClick={() => handleClientType('PROFESSIONAL')}
          />*/}
          {!isMobile && (
            <Box sx={{ width: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Lottie animationData={homepage} loop={true} style={{ width: '100%', height: '100%' }} />
            </Box>
          )}
        </Stack>
        <TitleSection
          titleParts={[t('homePage.completeInsurance.title'), t('homePage.completeInsurance.subtitle')]}
          titleSizes={isMobile ? ['1.6rem', '1.6rem'] : ['2rem', '2rem']}
          titleColors={['#F066B5', '#282828']}
          fontSize="18px"
          paragraphColors={['#282828', '#F066B5', '#282828']}
          isMobile={isMobile}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: '0px auto',
            marginTop: '-10px',
            marginBottom: '40px',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              textAlign: 'center',
              color: '#282828',
              fontWeight: 'normal',
              marginRight: 0.5,
              fontSize: '18px',
              paddingLeft: isMobile ? '10px' : 'none',
              paddingRight: isMobile ? '10px' : 'none',
            }}
          >
            Parce qu’un coup dur ne devrait pas détruire votre équilibre mental,{' '}
            <strong style={{ color: '#F066B5' }}>nous sommes là</strong> pour vous aider immédiatement
          </Typography>
        </Box>

        {!isMobile ? (
          <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '50px' }}>
            <FeatureCard
              number={1}
              icon={Indemnite}
              title={['Indemnisation', ' financière']}
              description={[
                'Recevez un versement rapide pour couvrir vos dépenses urgentes',
                'Utilisation libre pour répondre à vos besoins immédiats ou futurs',
              ]}
              isAnimation={true}
              titleColors={['#282828', '#F066B5']}
              subTitleColors={['#282828', '#F066B5', '#282828']}
            />
            <FeatureCard
              number={2}
              icon={Psychologist}
              subTitleColors={['#282828']}
              title={['Soutien', ' psychologique']}
              titleColors={['#282828', '#F066B5']}
              description={[
                'Un soutien immédiat',
                'Accès à un réseau de psychologues certifiés',
                'Séances remboursées, sans avancer d’argent ',
              ]}
              isAnimation={true}
            />
            <FeatureCard
              number={3}
              icon={Administration}
              subTitleColors={['#282828']}
              title={['Suivi', ' administratif']}
              titleColors={['#282828', '#F066B5']}
              description={[
                'Assistance pour vos démarches administratives',
                'Accompagnement personnalisé pour vous faciliter la vie',
              ]}
              isAnimation={true}
            />
            <FeatureCard
              number={4}
              icon={paperwork}
              subTitleColors={['#282828']}
              title={['Soutien', 'juridique']}
              titleColors={['#282828', '#F066B5']}
              description={[
                'Assistance juridique disponible avant sinistre',
                'Aide juridique en cas de conflit ou de litige.',
              ]}
              isAnimation={true}
            />
          </div>
        ) : (
          <Grid container spacing={4} justifyContent="center" alignItems="center" sx={{ marginBottom: '50px' }}>
            <Grid item xs={12} sm={6} md={4}>
              <FeatureCard
                number={1}
                icon={Indemnite}
                title={['Indemnisation', ' financière']}
                description={[
                  'Recevez un versement rapide pour couvrir vos dépenses urgentes',
                  'Utilisation libre pour répondre à vos besoins immédiats ou futurs',
                ]}
                isAnimation={true}
                titleColors={['#282828', '#F066B5']}
                subTitleColors={['#282828', '#F066B5', '#282828']}
                isMobile={true}
              />
              <FeatureCard
                number={2}
                icon={Psychologist}
                subTitleColors={['#282828']}
                title={['Soutien', ' psychologique']}
                titleColors={['#282828', '#F066B5']}
                description={[
                  'Un soutien immédiat',
                  'Accès à un réseau de psychologues certifiés',
                  'Séances remboursées, sans avancer d’argent ',
                ]}
                isAnimation={true}
                isMobile={true}
              />
              <FeatureCard
                number={3}
                icon={Administration}
                subTitleColors={['#282828']}
                title={['Suivi', ' administratif']}
                titleColors={['#282828', '#F066B5']}
                description={[
                  'Assistance pour vos démarches administratives',
                  'Accompagnement personnalisé pour vous faciliter la vie',
                ]}
                isAnimation={true}
                isMobile={true}
              />
              <FeatureCard
                number={4}
                icon={paperwork}
                subTitleColors={['#282828']}
                title={['Soutien', 'juridique']}
                titleColors={['#282828', '#F066B5']}
                description={[
                  'Assistance juridique disponible avant sinistre',
                  'Aide juridique en cas de conflit ou de litige.',
                ]}
                isAnimation={true}
                isMobile={true}
              />
            </Grid>
          </Grid>
        )}
        <div style={{ marginTop: '-40px', marginBottom: '50px' }}>
          <ButtonComponent
            label="Je choisis l'indemnisation financière"
            width="300px"
            height="40px"
            onClick={() => {
              handleNavigateSignUp();
              trackEvent({
                category: 'Homepage',
                action: 'sign_up_button',
                label: "Je choisis l'indemnisation financière",
              });
            }}
            borderRadius="50px"
          />
        </div>
        <TitleSection
          titleParts={[t('homePage.coveringInsurance.title'), t('homePage.coveringInsurance.subtitle')]}
          titleSizes={isMobile ? ['1.6rem', '1.6rem'] : ['2rem', '2rem']}
          titleColors={['#F066B5', '#282828']}
          fontSize="16px"
          isMobile={isMobile}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: '0px auto',
            marginTop: '-10px',
            marginBottom: '40px',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              textAlign: 'center',
              color: '#282828',
              fontWeight: 'normal',
              marginRight: 0.5,
              fontSize: '18px',
              paddingLeft: isMobile ? '10px' : 'none',
              paddingRight: isMobile ? '10px' : 'none',
            }}
          >
            Personnalisez votre protection en fonction de votre situation à partir de
            <strong style={{ color: '#F066B5' }}> 4,1 €/mois</strong> <br />
            <div style={{ color: '#888', fontSize: '16px' }}>
              Ma maison, mon travail, ma famille, choisissez un pack ou combinez-les librement.
              <br />
              Avec chacun des packs, vous êtes couverts de la même manière.
            </div>
          </Typography>
        </Box>

        {/*<TypeSwitcher selection={selection} toggleSelection={toggleSelection}  homePage={true}/>*/}

        {selection === Constants.CLIENT_TYPE.PERSONAL ? (
          !isMobile ? (
            <div style={{ display: 'flex', marginBottom: '50px' }}>
              <ServiceCardComponent
                animationData={houseAnimation}
                title={t('homePage.personal.house.title')}
                description={
                  'Votre foyer est votre refuge, mais un imprévu peut tout bouleverser. Que ce soit un incendie, une catastrophe naturelle ou un vol, nous vous aidons à rebondir avec un soutien complet pour surmonter le choc.'
                }
                bulletPoints={t('homePage.personal.house.points', { returnObjects: true }) || []}
                isAnimation={true}
              />
              <ServiceCardComponent
                animationData={workAnimation}
                title={t('homePage.personal.work.title')}
                description={
                  'Perdre son emploi, subir du harcèlement ou un burn-out peut être dévastateur. Nous sommes là pour vous : un accompagnement complet pour retrouver confiance et avancer sereinement.'
                }
                bulletPoints={t('homePage.personal.work.points', { returnObjects: true }) || []}
                isAnimation={true}
                work={true}
              />
              <ServiceCardComponent
                animationData={familyAnimation}
                title={t('homePage.personal.family.title')}
                description={
                  'Protégez ceux qui comptent le plus face aux épreuves de la vie – maladie grave, perte d’un proche, violences ou harcèlement. Vous et vos proches ne serez jamais seuls pour affronter ces moments difficiles.'
                }
                bulletPoints={t('homePage.personal.family.points', { returnObjects: true }) || []}
                isAnimation={true}
                family={true}
              />
            </div>
          ) : (
            <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ marginBottom: '50px' }}>
              <Grid item xs={12} sm={6} md={4}>
                <ServiceCardComponent
                  animationData={houseAnimation}
                  title={t('homePage.personal.house.title')}
                  description={
                    'Votre foyer est votre refuge, mais un imprévu peut tout bouleverser. Que ce soit un incendie, une catastrophe naturelle ou un vol, nous vous aidons à rebondir avec un soutien complet pour surmonter le choc.'
                  }
                  bulletPoints={t('homePage.personal.house.points', { returnObjects: true }) || []}
                  isAnimation={true}
                  isMobile={isMobile}
                />
                <ServiceCardComponent
                  animationData={workAnimation}
                  title={t('homePage.personal.work.title')}
                  description={
                    'Perdre son emploi, subir du harcèlement ou un burn-out peut être dévastateur. Nous sommes là pour vous : un accompagnement complet pour retrouver confiance et avancer sereinement.'
                  }
                  bulletPoints={t('homePage.personal.work.points', { returnObjects: true }) || []}
                  isAnimation={true}
                  work={true}
                  isMobile={isMobile}
                />
                <ServiceCardComponent
                  animationData={familyAnimation}
                  title={t('homePage.personal.family.title')}
                  description={
                    'Protégez ceux qui comptent le plus face aux épreuves de la vie – maladie grave, perte d’un proche, violences ou harcèlement. Vous et vos proches ne serez jamais seuls pour affronter ces moments difficiles.'
                  }
                  bulletPoints={t('homePage.personal.family.points', { returnObjects: true }) || []}
                  isAnimation={true}
                  family={true}
                  isMobile={isMobile}
                />
              </Grid>
            </Grid>
          )
        ) : !isMobile ? (
          <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '50px' }}>
            <ServiceCardComponent
              animationData={Building}
              title={t('homePage.professional.local.title')}
              bulletPoints={t('homePage.professional.local.points', { returnObjects: true }) || []}
              isAnimation={true}
            />
            <ServiceCardComponent
              animationData={Business}
              title={t('homePage.professional.activity.title')}
              bulletPoints={t('homePage.professional.activity.points', { returnObjects: true }) || []}
              isAnimation={true}
            />
            <ServiceCardComponent
              animationData={teamwork}
              title={t('homePage.professional.employees.title')}
              bulletPoints={t('homePage.professional.employees.points', { returnObjects: true }) || []}
              isAnimation={true}
            />
          </div>
        ) : (
          <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ marginBottom: '50px' }}>
            <Grid item xs={12} sm={6} md={4}>
              <ServiceCardComponent
                animationData={Building}
                title={t('homePage.professional.local.title')}
                bulletPoints={t('homePage.professional.local.points', { returnObjects: true }) || []}
                isAnimation={true}
                isMobile={isMobile}
              />
              <ServiceCardComponent
                animationData={Business}
                title={t('homePage.professional.activity.title')}
                bulletPoints={t('homePage.professional.activity.points', { returnObjects: true }) || []}
                isAnimation={true}
                isMobile={isMobile}
              />
              <ServiceCardComponent
                animationData={teamwork}
                title={t('homePage.professional.employees.title')}
                bulletPoints={t('homePage.professional.employees.points', { returnObjects: true }) || []}
                isAnimation={true}
                isMobile={isMobile}
              />
            </Grid>
          </Grid>
        )}
        <div style={{ marginTop: '-40px', marginBottom: '50px' }}>
          <ButtonComponent
            label="Je choisis ma formule"
            width="300px"
            height="40px"
            onClick={() => {
              handleNavigateSignUp();
              trackEvent({
                category: 'Homepage',
                action: 'sign_up_button',
                label: "Je choisis ma formule",
              });
            }}
            borderRadius="50px"
          />
        </div>
      </Container>
    </HomePageLayout>
  );
};

export default HomePage;
