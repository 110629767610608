import React from "react";
import { styled,Box, Typography } from "@mui/material";
import HomepageLayout from "layouts/frontOffice/HomePageLayout";
import { Stack, useMediaQuery, Grid, CardMedia, Card, CardContent, Paper, TextField, IconButton, Button, DialogActions, Dialog, DialogTitle, DialogContent } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor:"white", 
    marginBottom:"100px",
  borderRadius: "15px", 
  border: "3px solid transparent",  
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",  
  position: 'relative',
  
  });
const DataPolicy = () => {
      const navigate = useNavigate();
    
     const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <HomepageLayout>
  <Container style={{ maxWidth: 800, marginBottom: "80px" }}>
    
      <IconButton
        onClick={() => navigate('/')}
        sx={{
          position: 'absolute', 
          top: 0, 
          left: 0, 
          margin: '15px',
          
        }}
      >
        <ArrowBackIcon />
      </IconButton>
    
    
    <Stack
      direction="row"
      sx={{
        paddingTop: isMobile ? "30px" : "30px",
        marginBottom: "5px",
        width: isMobile ? "90%" : "90%",
      }}
    >
      <Box sx={{ padding: "40px 0", textAlign: "justify" }}>
        <Typography variant="h2" sx={{ marginBottom: "50px", textAlign: "center", color: '#F066B5' }}>
        Politique de confidentialité d'avektoi Assurances        </Typography>
        <Typography variant="body1">
        Chez avektoi Assurances, nous nous engageons à protéger la confidentialité et la sécurité de vos données personnelles. Cette politique vous informe sur les informations que nous recueillons, leur utilisation et vos droits associés.          </Typography>
          <Typography variant="h5" sx={{ marginTop: "20px" , marginBottom:"6px" , textDecoration:"underline"}}>
            1. Collecte des données
          </Typography>
          <Typography variant="body1">
          Nous collectons des données personnelles lorsque vous :          </Typography>
          <ul>
          <li>Demandez un devis ou souscrivez une assurance
          </li>
          <li>Remplissez un formulaire de contact ou de réclamation
          </li>
          <li>
          Créez un compte utilisateur sur notre site
          </li>
          </ul>
          <Typography variant="body1">
          Les données collectées peuvent inclure votre nom, prénom, date de naissance, adresse, numéro de téléphone, adresse e-mail, informations financières, ainsi que des informations nécessaires pour évaluer et gérer votre contrat d'assurance.
           </Typography>
          <Typography variant="h5" sx={{ marginTop: "20px" , marginBottom:"6px" , textDecoration:"underline"}}>
            2. Utilisation des données
          </Typography>
          <Typography variant="body1">
          Nous utilisons vos données personnelles pour :

          </Typography>
          <ul>
          <li>Évaluer votre demande d’assurance et gérer votre contrat
          </li>
          <li>Répondre à vos questions ou réclamations
          </li>
          <li>
          Améliorer nos services et personnaliser votre expérience utilisateur
          </li>
          <li>
          Vous informer des actualités et offres promotionnelles d'avektoi Assurances (avec votre consentement préalable)          </li>
          </ul>
          <Typography variant="h5" sx={{ marginTop: "20px" , marginBottom:"6px" , textDecoration:"underline"}}>
            3. Protection des données
          </Typography>
          <Typography variant="body1">
          Nous appliquons des mesures rigoureuses pour assurer la sécurité de vos données personnelles et prévenir tout accès, modification ou divulgation non autorisés.

          </Typography>
          <Typography variant="h5" sx={{ marginTop: "20px" , marginBottom:"6px" , textDecoration:"underline"}}>
            4. Partage des données

          </Typography>
          <Typography variant="body1">
          Vos données personnelles peuvent être partagées avec :          </Typography>

         <ul>
          <li>Nos partenaires ou prestataires de services impliqués dans la gestion de votre contrat d’assurance
          </li>
          <li>Des organismes réglementaires ou autorités publiques, lorsque requis par la loi
          </li>
         </ul>
         <Typography variant="body1">
         Nous ne vendons ni ne louons jamais vos données personnelles à des tiers.         </Typography>
          <Typography variant="h5" sx={{ marginTop: "20px" , marginBottom:"6px" , textDecoration:"underline"}}>
            5. Vos droits
          </Typography>
          <Typography variant="body1">
          Conformément à la réglementation en vigueur, vous avez le droit de :
          </Typography>
          <ul>
          <li>Accéder à vos données personnelles
          </li>
          <li>Rectifier ou supprimer vos données
          </li>
          <li>Vous opposer au traitement de vos données ou demander sa limitation
          </li>
          <li>Demander la portabilité de vos données
          </li>
         </ul>
         <Typography variant="body1">
         Pour exercer ces droits, contactez-nous à : <a href="mailto:privacy@avektoi.fr" >privacy@avektoi.fr</a>.          </Typography>
          <Typography variant="h5" sx={{ marginTop: "20px" , marginBottom:"6px" , textDecoration:"underline"}}>
            6. Modification de la politique

          </Typography>
          <Typography variant="body1">
          Cette politique de confidentialité peut être mise à jour périodiquement. Toute modification sera clairement affichée sur cette page.      </Typography>
          <Typography variant="h5" sx={{ marginTop: "20px" , marginBottom:"6px" , textDecoration:"underline"}}>
            7. Contact

          </Typography>
          <Typography variant="body1">
          Pour toute question concernant la protection de vos données, veuillez nous contacter : </Typography>

<ul>
          <li><strong>Par email :</strong> <a href="mailto:contact@avektoi.fr" >
          contact@avektoi.fr
          </a>
          </li>
          <li><strong>Par courrier : </strong>   <a href="https://www.google.com/maps?q=66+AVENUE+DES+CHAMPS+ELYSEES+75008+PARIS" target="_blank" rel="noopener noreferrer" style={{color:"inherit" , textDecoration:"none"}}>
          66 avenue des Champs Elysées 75008 Paris
          </a>
          </li>
          
          </ul>

         
          <Typography variant="body1">
          Dernière mise à jour : 25/03/2025
</Typography>

        </Box>
        </Stack>
        </Container>

    </HomepageLayout>
  );
};

export default DataPolicy;
