import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import NewButton from 'components/NewButton';
// material-ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from '@mui/material/Modal';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// third-party
import * as Yup from 'yup';
import { Formik } from 'formik';
import { PatternFormat } from 'react-number-format';

// project-imports
import IconButton from 'components/@extended/IconButton';
import MainCard from 'components/MainCard';
import { openSnackbar } from 'api/snackbar';
import { useTranslation } from 'react-i18next';

// assets
import { Add, Trash } from 'iconsax-react';
import masterCard from 'assets/images/icons/master-card.png';
import paypal from 'assets/images/icons/paypal.png';
import visaCard from 'assets/images/icons/visa-card.png';
import Constants from 'common/Constants';
import PaymentMethodService from 'services/PaymentMethodService';
import ClientService from 'services/ClientService';

// style & constant
const buttonStyle = { color: 'text.primary', fontWeight: 600 };

const stripePromise = loadStripe(Constants.STRIPE_PUBLIC_KEY);

// ==============================|| PAYMENT - CARD ||============================== //

function PaymentCard({ cardItem, onDelete, onDefaultChange, isDefault }) {
  const {
    id,
    card: { brand, last4 },
  } = cardItem;

  return (
    <MainCard content={false} sx={{ cursor: 'pointer' }}>
      <Box sx={{ p: 2 }}>
        <FormControlLabel
          value={id}
          control={<Radio value={id} checked={isDefault} onChange={() => onDefaultChange(id)} />}
          sx={{ display: 'flex', '& .MuiFormControlLabel-label': { flex: 1 } }}
          label={
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Stack spacing={0.5} sx={{ ml: 1 }}>
                  <Typography variant="subtitle1">
                    <PatternFormat value={last4} displayType="text" type="text" format="**** **** **** ####" />
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                  <img src={brand === 'master' ? masterCard : visaCard} alt="payment card" />
                  <IconButton color="secondary" onClick={() => onDelete(id)}>
                    <Trash />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          }
        />
      </Box>
    </MainCard>
  );
}

// ==============================|| USER PROFILE - PAYMENT ||============================== //

function SetupForm({ onSuccess }) {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });

    if (error) {
      openSnackbar({
        open: true,
        message: error.message,
        variant: 'alert',
        alert: { color: 'error' },
      });
    } else {
      onSuccess();
      openSnackbar({
        open: true,
        message: t('profile.payment.cardAddedSuccess'),
        variant: 'alert',
        alert: { color: 'success' },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        variant="contained"
        type="submit"
        startIcon={<Add />}
        sx={{ display: { xs: 'none', sm: 'flex' }, mt: 2, width: '100%' }}
      >
        {t('profile.buttons.save')}
      </Button>
    </form>
  );
}

function CreatePaymentMethod({ openModal, setOpenModal, onSuccess }) {
  const [clientSecret, setClientSecret] = useState(null);

  const { t } = useTranslation();

  const options = {
    clientSecret: clientSecret,
  };

  useEffect(() => {
    if (openModal) {
      PaymentMethodService.createSetupIntent().then((res) => {
        setClientSecret(res.data.client_secret);
      });
    }
  }, [openModal]);

  const onResetModal = () => {
    setOpenModal(false);
    setClientSecret(null);
    onSuccess();
  };

  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          borderRadius: 2,
          pt: 2,
          px: 4,
          pb: 3,
          width: '500px',
        }}
      >
        {!clientSecret ? (
          <h3>Loading...</h3>
        ) : (
          <>
            <h3>{t('profile.payment.addNewCard')}</h3>
            <Elements stripe={stripePromise} options={options}>
              <SetupForm onSuccess={onResetModal} />
            </Elements>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default function TabPayment() {
  const { t } = useTranslation();
  const [cards, setCards] = useState([]);
  const [method, setMethod] = useState('card');
  const [value, setValue] = useState('2');
  const [openModal, setOpenModal] = useState(false);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);

  const getCards = async () => {
    const request = await PaymentMethodService.getPaymentMethods();
    setCards(request.data);
  };

  const getCustomerInfo = async () => {
    const request = await ClientService.getCustomerInfo();
    if (request.data && request.data.invoice_settings && request.data.invoice_settings.default_payment_method) {
      setDefaultPaymentMethod(request.data.invoice_settings.default_payment_method);
    }
  };

  useEffect(() => {
    getCards();
    getCustomerInfo();
  }, []);

  const handleDeleteCard = (id) => {
    PaymentMethodService.deletePaymentMethod(id).then((res) => {
      openSnackbar({
        open: true,
        message: t('profile.payment.cardDeletedSuccess'),
        variant: 'alert',
        alert: { color: 'success' },
      });
      setCards(cards.filter((card) => card.id !== id));
    });
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleSuccess = () => {
    getCards();
    setOpenModal(false);
  };

  const onDefaultPaymentMethodChange = (id) => {
    setDefaultPaymentMethod(id);
  };

  const handleSave = () => {
    ClientService.setDefaultPaymentMethod(defaultPaymentMethod).then((res) => {
      openSnackbar({
        open: true,
        message: t('profile.payment.cardDefaultSuccess'),
      });
    });
  };

  return (
    <MainCard title={t('profile.payment.title')}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={1.25} direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                color={method === 'card' || method === 'add' ? 'primary' : 'secondary'}
                sx={buttonStyle}
                onClick={() => setMethod(method !== 'card' ? 'card' : method)}
                startIcon={<img src={masterCard} alt={t('profile.payment.masterCard')} />}
              >
                {t('profile.payment.card')}
              </Button>
              <Button
                variant="outlined"
                color={method === 'paypal' ? 'primary' : 'secondary'}
                sx={buttonStyle}
                onClick={() => setMethod(method !== 'paypal' ? 'paypal' : method)}
                startIcon={<img src={paypal} alt={t('profile.payment.paypal')} />}
              >
                {t('profile.payment.paypal')}
              </Button>
            </Stack>

            <NewButton label={t('profile.payment.addNewCard')} onClick={() => setOpenModal(true)} startIcon={<Add />} />

            <Tooltip title={t('profile.payment.addNewCard')}>
              <IconButton
                variant="contained"
                onClick={() => setOpenModal(true)}
                sx={{ display: { xs: 'block', sm: 'none' } }}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>

        <CreatePaymentMethod openModal={openModal} setOpenModal={setOpenModal} onSuccess={handleSuccess} />

        {method === 'card' && (
          <>
            <Grid item xs={12}>
              <RadioGroup row aria-label="payment-card" name="payment-card" value={value} onChange={handleRadioChange}>
                <Grid item xs={12} container spacing={2.5}>
                  {cards.map((cardItem, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <PaymentCard
                        cardItem={cardItem}
                        onDelete={handleDeleteCard}
                        isDefault={defaultPaymentMethod === cardItem.id}
                        onDefaultChange={onDefaultPaymentMethodChange}
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <NewButton label={t('profile.buttons.cancel')} onClick={() => {}} cancel={true} />
                <NewButton label={t('profile.buttons.save')} onClick={handleSave} />
              </Stack>
            </Grid>
          </>
        )}
        {method === 'paypal' && (
          <Grid item xs={12}>
            <Formik
              initialValues={{
                email: 'stebin.ben@paypal.co',
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email(t('profile.errors.email.invalid'))
                  .max(255)
                  .required(t('profile.errors.email.required')),
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                  openSnackbar({
                    open: true,
                    message: t('profile.payment.paypalUpdateSuccess'),
                    variant: 'alert',
                    alert: { color: 'success' },
                  });
                  setStatus({ success: false });
                  setSubmitting(false);
                } catch (err) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="payment-paypal-email">{t('profile.payment.emailAddress')}</InputLabel>
                        <TextField
                          type="email"
                          fullWidth
                          value={values.email}
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="payment-paypal-email"
                          placeholder={t('profile.payment.emailAddress')}
                        />
                      </Stack>
                      {touched.email && errors.email && (
                        <FormHelperText error id="payment-paypal-email-helper">
                          {errors.email}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                        <Button color="error" onClick={() => setMethod('card')}>
                          {t('profile.buttons.cancel')}
                        </Button>
                        <Button
                          disabled={isSubmitting || Object.keys(errors).length !== 0}
                          type="submit"
                          variant="contained"
                        >
                          {t('profile.buttons.save')}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        )}
      </Grid>
    </MainCard>
  );
}

PaymentCard.propTypes = { card: PropTypes.any };
