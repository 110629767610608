import DashboardLayout from '../layouts/BackOfficeLayout';
import ClaimManagmenetComponent from 'pages/backOffice/claimManagement';
import ComplainManagementComponent from 'pages/backOffice/complaintManagement';
import PolicyManagement from 'pages/backOffice/policyManagement';
import AccountManagement from 'pages/backOffice/accountManagement';
import ClientManagement from 'pages/backOffice/clientManagement';
import BlogManagement from 'pages/backOffice/BlogManagement';
import DiscountManagement from 'pages/backOffice/discountManagement';
// ==============================|| MAIN ROUTES ||============================== //

const BackOfficeRoutes = {
  path: '/',
  children: [
    {
      path: '/backoffice',
      element: <DashboardLayout/>,
      children: [
        {
          path: '',
          element: <PolicyManagement/>
        },   
        {
          path: 'policies',
          element: <PolicyManagement/>
        },   
        {
          path: 'claims',
          element: <ClaimManagmenetComponent/>
        },      
        {
          path: 'complaints',
          element: <ComplainManagementComponent/>
        },      
        {
          path: 'accounts',
          element: <AccountManagement/>
        },
        {
          path: 'clients',
          element: <ClientManagement/>
        },
        {
          path: 'blog',
          element: <BlogManagement/>
        },
        {
          path: 'discounts',
          element: <DiscountManagement/>
        },
      ]
    }
  ]
};

export default BackOfficeRoutes;
