import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, Chip, Grid, List, ListItem, Stack, TextareaAutosize, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { useTheme } from '@mui/material/styles';

// Project imports
import MainCard from 'components/MainCard';
import FileUploaded from 'components/FileUploaded';
import { fetcher, fetcherPost } from 'utils/axios';
import { COMPLAINT } from 'common/Constants';
import ComplaintService from "../../../services/ComplaintService";

// Utilities
import dateFormat from 'dateformat';
import { format as currencyFormatter } from 'currency-formatter';
import { useTranslation } from 'react-i18next';
import NewButton from 'components/NewButton';

export default function ExpandComplaintDetail({ data, onStatusChange }) {
    const { t } = useTranslation(); // Translation hook
    const [policyDetail, setPolicyDetail] = useState(null);
    const [claimDetail, setClaimDetail] = useState(null);
    const [loading, setLoading] = useState(false); // For button state
    const [complaintData, setComplaintData] = useState(data);
    const [conversations, setConversations] = useState([])
    const [postMessage, setPostMessage] = useState('')
    const theme = useTheme();

    const {
        policy_id,
        claim_id,
        complaint_reference_number,
        financial_compensation_amount,
        created_at,
        receipt_date,
        complaint_type_id,
        documents,
        status_id
    } = data;

    const level = useMemo(() => complaintData.complaint_level_id ? complaintData.complaint_level_id : COMPLAINT.LEVEL.LEVEL_1, [complaintData.complaint_level_id]);

    const isShowEscalateButton = useMemo(
        () =>
            [
                COMPLAINT.STATUS.DRAFT,
                COMPLAINT.STATUS.DECLARED,
                COMPLAINT.STATUS.OPEN,
                COMPLAINT.STATUS.PENDING,
                COMPLAINT.STATUS.RECEIPT,
            ].includes(status_id) && level === COMPLAINT.LEVEL.LEVEL_1,
        [status_id, level]
    );

    const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (policy_id) {
                    const policyData = await fetcher(`/policies/detail/${policy_id}`);
                    setPolicyDetail(policyData);
                }

                if (claim_id) {
                    const claimData = await fetcher(`/claims/detail/${claim_id}`);
                    setClaimDetail(claimData);
                }
                const conversationData = await fetcher(`/complaints/conversation?complaint_id=${data.id}`)
                setConversations(conversationData)


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [policy_id, claim_id]);

    const STATUS_MAP = {
        [COMPLAINT.STATUS.DRAFT]: { color: 'default', label: t('complaint.status.draft') },
        [COMPLAINT.STATUS.DECLARED]: { color: 'primary', label: t('complaint.status.declared') },
        [COMPLAINT.STATUS.CLOSED]: { color: 'secondary', label: "Clos" },
        [COMPLAINT.STATUS.CANCELLED]: { color: 'error', label: "Annulé" },
        [COMPLAINT.STATUS.OPEN]: { color: 'success', label: 'En cours' },
        [COMPLAINT.STATUS.PENDING]: { color: 'warning', label: t('complaint.status.pending') },
    };

    const renderStatus = (status) => {
        const statusInfo = STATUS_MAP[status] || { color: 'default', label: status };
        return <Chip color={statusInfo.color} label={statusInfo.label} size="default" variant="light" />;
    };

    const renderComplaintButton = (status) => {
        const buttonMap = {
            [COMPLAINT.STATUS.OPEN]: { color: 'error', label: t('complaint.button.cancelComplaint'), action: cancelComplaintHandler },
            [COMPLAINT.STATUS.RECEIPT]: { color: 'error', label: t('complaint.button.cancelComplaint'), action: cancelComplaintHandler },
            [COMPLAINT.STATUS.DECLARED]: { color: 'error', label: t('complaint.button.cancelComplaint'), action: cancelComplaintHandler },
            [COMPLAINT.STATUS.PENDING]: { color: 'error', label: t('complaint.button.cancelComplaint'), action: cancelComplaintHandler },
        };

        const { color, label, action } = buttonMap[status] || {};
        return status === COMPLAINT.STATUS.CLOSED ? null : ( // Prevent rendering if the status is CLOSED
            action && (
                <NewButton label={loading ? "Annulation" : label} color={color} onClick={action} cancel={label===t('complaint.button.cancelComplaint') && true}/>
                
            )
        );
    };

    const underConstruction = () => {
        alert(t('complaint.underConstruction'));
    };

    const cancelComplaintHandler = async () => {
        if (!complaintData.id) {
            console.error('Complaint ID is missing in the data.');
            alert(t('complaint.error.missingId'));
            return;
        }

        setLoading(true);
        try {
              const confirmation = await Swal.fire({
                           title: "Êtes-vous sûr de vouloir annuler cet avis ? Cette action est irréversible.",
                           confirmButtonText: t('buttons.yes'),
                           cancelButtonText: t('buttons.no'),
                           confirmButtonColor: theme.palette.primary.main,
                           showCancelButton: true,
                           reverseButtons: true,
                         });
                         if (!confirmation.isConfirmed) return;
            const updatedComplaint = await ComplaintService.cancelComplaintById(complaintData.id);
 const confirmation2 = await Swal.fire({
        title: "Le sinistre a été annulé avec succès.",
        confirmButtonText: "OK",
        confirmButtonColor: theme.palette.primary.main,
        reverseButtons: true,
      });
      if (!confirmation2.isConfirmed) return;
            setComplaintData({
                ...complaintData,
                status_id: updatedComplaint.status_id
            });

            // Notify parent about the status change
            onStatusChange(updatedComplaint.id, updatedComplaint.status_id);
        } catch (error) {
            console.error('Error canceling complaint:', error);
            alert(t('complaint.error.cancel'));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log('Updated complaint data:', complaintData);
    }, [complaintData]);

    useEffect(() => {
        console.log('ExpandComplaintDetail data:', data);
    }, [data]);


    const messageChange = (event) => {
        setPostMessage(event.target.value)
    }

    const submitMessage = async () => {
        try {
            await fetcherPost(['/complaints/conversation/create/', { complaint_id: data.id, message: postMessage }]);
            const conversationData = await fetcher(`/complaints/conversation?complaint_id=${data.id}`);
            setConversations(conversationData);
        } catch (error) {
            console.error('Error submitting message:', error);
            alert(t('complaint.error.submitMessage'));
        } finally {
            setPostMessage('');
        }
    };

    return (
        <Grid container spacing={2.5} sx={{ pl: { xs: 0, sm: 5, md: 6, lg: 10, xl: 12 } }}>
            <Grid item xs={12} sm={7} md={7} xl={7}>
                <Stack spacing={2.5}>
                    <MainCard title={t('complaint.details.title')}>
                        <List sx={{ py: 0 }}>
                            <ListItem divider={!matchDownMD}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.number')}</Typography>
                                            <Typography variant="h3">{complaint_reference_number}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.financialCompensation')}</Typography>
                                            <Typography variant="h3">
                                                {financial_compensation_amount ? currencyFormatter(financial_compensation_amount, { code: 'EUR' }) : ''}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem divider={!matchDownMD}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.createdDate')}</Typography>
                                            <Typography>{dateFormat(created_at, 'dd-mm-yyyy - HH:MM')}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.receiptDate')}</Typography>
                                            <Typography>{receipt_date ? dateFormat(receipt_date, 'dd-mm-yyyy - HH:MM') : ''}</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem divider={!matchDownMD}>
                                <Grid container spacing={3}>
                                  
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.type')}</Typography>
                                            <Typography>
                                                <Chip color="primary" label={t(`${complaint_type_id}`)} size="Default" variant="light" />
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.status')}</Typography>
                                            <Typography>{renderStatus(complaintData.status_id)}</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </ListItem>
                           
                            <ListItem divider={!matchDownMD}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.description')}</Typography>
                                            <Typography>{data.description}</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem divider={!matchDownMD}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.evidenceDocuments')}</Typography>
                                            {documents.length > 0 && documents.map((document) => (
                                                <FileUploaded file={document} key={document.id} />
                                            ))}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.level')}</Typography>
                                            <Typography>
                                                <Chip
                                                    color={level === COMPLAINT.LEVEL.LEVEL_3 ? 'error' : 'primary'}
                                                    label={t(`complaint.level.${level}`)}
                                                    size="default"
                                                    variant="light"
                                                />
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem divider={!matchDownMD}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <Stack spacing={0.5}>
                                            {renderComplaintButton(complaintData.status_id)}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </List>
                    </MainCard>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={5} md={5} xl={5}>
                <Stack spacing={2.5}>
                    <MainCard title="Détails de la conversation">
                        {conversations.length > 0 && conversations.map(conversation =>
                            <>
                                {conversation.client_id &&
                                    <div className='complain-conversation-item complain-conversation-item-left'>
                                        <div className='messsage'>{conversation.message}</div>
                                        <div className='messsage-info'>
                                            {data.client.first_name} - <em>{dateFormat(conversation.created_at, 'dd-mm-yyyy  HH:MM')}</em></div>
                                    </div>
                                }
                                {!conversation.client_id &&
                                    <div className='complain-conversation-item complain-conversation-item-right'>
                                        <div className='messsage'>{conversation.message}</div>
                                        <div className='messsage-info'>
                                            Tendanz Admin - <em>{dateFormat(conversation.created_at, 'dd-mm-yyyy  HH:MM')}</em></div>
                                    </div>
                                }
                            </>
                        )}
                        {conversations.length == 0 &&
                            <div className='no-message'>Aucune conversation.</div>
                        }

                        <div className='complain_conversation-post'>
                            <h5>Envoyez un nouveau message:</h5>
                            <div><TextareaAutosize value={postMessage} onChange={messageChange} minRows={6} maxRows={20} variant='outlined' style={{ width: '100%' }}></TextareaAutosize></div>
                            <div style={{ textAlign: 'right' }}>
                            <NewButton label="Envoyer"onClick={submitMessage} />
                           </div>
                        </div>
                    </MainCard>
                </Stack>
            </Grid>
        </Grid>

    );
}

ExpandComplaintDetail.propTypes = {
    data: PropTypes.object.isRequired,
    onStatusChange: PropTypes.func.isRequired
};
