import SignupPage from '../pages/frontOffice/UserOnboard/SignupPage';
import SignupChoicePage from 'pages/frontOffice/UserOnboard/SignUpChoicePage';
import HomePage from "../pages/frontOffice/HomePage"
import Error404 from 'pages/maintenance/error/404'
import RegisterPolicyProcess from 'pages/frontOffice/RegisterPolicy/RegisterPolicy';
import Quotation from 'pages/frontOffice/Quotation';
import GuestLayout from 'layouts/Guest';
import CreatePassword from 'pages/frontOffice/UserOnboard/CreatePassword';
import UploadClientDocuments from 'pages/frontOffice/UserOnboard/UploadClientDocuments';
import Blog from 'pages/frontOffice/Blog';
import PolicyRegisterSuccess from 'pages/frontOffice/policy-register-success';
import { element } from 'prop-types';
import DataPolicy from 'pages/frontOffice/DataPolicy';
import LegalNotice from 'pages/frontOffice/LegalNotice';
import BlogDetail from 'pages/frontOffice/BlogDetail';
// ==============================|| MAIN ROUTES ||============================== //

const GuestRoutes = {
  path: '/',
  children: [{
    path: '/',
    element: <GuestLayout />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/have-an-account',
        element: <SignupChoicePage />,
      },
      {
        path: 'sign-up',
        element: <SignupPage/>
      },
      {
        path: 'sign-up-more-information/:token',
        element: <CreatePassword />
      },
      {
        path: 'register-policy',
        element: <RegisterPolicyProcess/>
      },
      {
        path: 'quotation/*',
        element: <Quotation/>
      },
      {path:'data-policy',
        element:<DataPolicy/>
      },
      {
        path:'legal-notice',
        element:<LegalNotice/>
      },
      {
        path: 'upload-documents',
        element: <UploadClientDocuments/>
      },
      {
        path: 'blog',
        element: <Blog/>
      },
      {
        path: 'blog/:id',
        element: <BlogDetail/>
      },
      {
        path: 'register-policy-success/:id',
        element: <PolicyRegisterSuccess/>
      },
     
    ]
  },
  { 
    path: '*',
    element: <Error404 />,
  }
]
};

export default GuestRoutes;
