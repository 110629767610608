import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import HomePageLayout from 'layouts/frontOffice/HomePageLayout';
import { Stack, useMediaQuery } from '@mui/material';
import BlogListComponent from 'components/BlogListComponent';
import { trackPageView } from '../../utils/analytics';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const Blog = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    trackPageView(window.location.pathname);
  }, []);

  return (
    <HomePageLayout>
      <Container style={{ maxWidth: 800, margin: 'auto' }}>
        <Stack
          direction="row"
          sx={{
            borderRadius: '50px',
            paddingTop: isMobile ? '50px' : '50px',
            paddingBottom: '50px',
            marginBottom: '30px',
            width: isMobile ? '90%' : '85%',
          }}
        >
          <BlogListComponent />
        </Stack>
      </Container>
    </HomePageLayout>
  );
};

export default Blog;
