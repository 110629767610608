import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import Slider from '@mui/material/Slider';
import {
  Button,
  Grid,
  List,
  ListItem,
  Typography,
  Autocomplete,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  Box,
  Select,
  MenuItem,
} from '@mui/material';
import MainCard from 'components/MainCard';
import { TickCircle } from 'iconsax-react';
import axios from 'utils/axios';
import ClientService from 'services/ClientService';
import { RemoveCircle } from '@mui/icons-material';
import { format as currencyFormatter } from 'currency-formatter';
import axiosServices, { fetcherPost, fetcher } from 'utils/axios';
import countryCodes from 'common/countryCodes';
import AvektoiAPIClient from 'services/AvektoiAPIClient';

const SliderContainer = styled('div')({
  width: '70%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: '10px',
  color: '#EB3D9F',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '17px',
  lineHeight: '18px',
  letterSpacing: '0.5px',
  marginLeft: '15%',
  '@media (max-width: 1370px)': {
    width: '80%',
    marginLeft: '10%',
  },
  '@media (max-width: 955px)': {
    width: '90%',
    marginLeft: '5%',
    marginTop: '0px',
  },
});

const price = {
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: 1,
};


const CountryCodeSelect = styled(Select)(({ theme }) => ({
  width: '100%',
}));

const NewPolicyForm = ({ userType, openModal, setOpenModal, onSuccess }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [risks, setRisks] = useState([]);
  const [productId] = useState('19edb627-9ddb-4df4-bc60-8ac4ac8c2e37'); //TODO: replace with actual product ID
  const [maximumPrice, setMaximumPrice] = useState(500);
  const [clientData, setClientData] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('pay_directly');
  const [client, setClient] = useState(null);
  const [isCreatingClient, setIsCreatingClient] = useState(false);
  const [pricingTable, setPricingTable] = useState([]);

  //Formdata
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('+33');

  useEffect(() => {
    getRiskObjects();
    getClientData();
    getRiskPriceTable();
  }, []);

  const getClientData = () => {
    ClientService.getClientList()
      .then((response) => {
        if (response) {
          setClientData(response);
        }
      })
      .catch((err) => {
        console.log('client list error:', err);
      });
  };

  const getRiskObjects = async () => {
    let request = await axios.get(`/risks/list-by-product-id/${productId}`); //TODO: replace with actual API endpoint
    setRisks(request.data);
    request.data.map((risk) => handleSelect(risk.id));
  };

  const getRiskPriceTable = async () => {
    let request = await fetcher('/risk_price_combo');
    setPricingTable(request);
  };

  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((item) => item !== id) : [...prevSelected, id]
    );
  };

  const generateQuotation = () => {
    return {
      product_id: productId,
      client_id: client.id,
      maximum_amount_of_compensation_per_claim: maximumPrice,
      number_of_claim_per_year: 3,
      risk_ids: selected,
      total_amount: priceInfo.monthly,
      price_combo_id: priceInfo.price_combo_id,
    };
  };

  const createQuotation = async (quote) => {
    const request = await fetcherPost([`/quotes/admin-create`, quote]);
    console.log(request.quote_code);
  };

  const getQuotation = async (quote) => {
    try {
      console.log('Submitting quote for creation:', quote); // Log the quote being submitted
      const request = await fetcherPost([`/quotes/admin-create`, quote]);
      console.log('Response from fetcherPost:', request); // Log fetcherPost response
      if (!request || !request.quote_code) {
        console.error('Missing or invalid quote_code:', request);
        return null;
      }
      const response = await axiosServices.get(`/quotes/get-by-code/${request.quote_code}`);
      console.log('Fetched quotation details:', response.data); // Log fetched quotation
      const fetchedQuotation = response.data;
      return fetchedQuotation;
    } catch (error) {
      console.error('Error fetching quotation:', error.message); // Log error
      return null; // Return null explicitly
    }
  };

  const onSliderChange = (value) => {
    setMaximumPrice(value);
  };

  const sendPaymentLink = async (email, url) => {
    try {
      const emailPayload = {
        email,
        url,
      };

      await axiosServices.post('/quotes/send-payment-link', emailPayload);

      alert('Payment link sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again.');
    }
  };

  const handleCreatePolicy = async () => {
    if (selected.length > 0) {
      console.log('selected:', selected);
      console.log('client:', client);
      console.log('paymentMethod:', paymentMethod);

      const quote = generateQuotation();
      await createQuotation(quote);

      const fetchedQuotation = await getQuotation(quote); // Fetch and use quotation here
      console.log(fetchedQuotation);

      if (!fetchedQuotation || !fetchedQuotation.id) {
        console.error('Quotation or quotation.id is undefined');
        return;
      }

      const { data } = await axiosServices.post(
        '/clients/create-checkout-session',
        {
          quote_id: fetchedQuotation.id,
          amount: priceInfo.monthly,
          mode: 'subscription'
        },
        {
          headers: { client_id: client.id },
        }
      );
      const { url } = data;

      onSuccess();


      if (paymentMethod === 'send_link_to_email') {
        sendPaymentLink(client.email, url);
      } else {
        // Open the Stripe checkout page in a new tab
        window.open(url, '_blank');
      }

      console.log('Checkout initiated successfully');
    }
  };

  const calculatePrice = useCallback(
    (amount, selectedRisks) => {
      const allRisks = risks.map((risk) => risk.id);
      const defaultPrices = {};

      // Populate default prices for each individual risk
      allRisks.forEach((risk) => {
        const riskEntry = pricingTable.find(
          (item) => item.maximum_amount === amount.toString() && item.risks.length === 1 && item.risks.includes(risk)
        );

        if (riskEntry) {
          defaultPrices[risk] = riskEntry.price;
        } else {
          defaultPrices[risk] = 'N/A';
        }
      });

      const defaultObject = {
        price_combo_id: undefined,
        risks: defaultPrices,
        additionalPrices: {},
        annual: 0,
        monthly: 0,
      };

      // If no risks are selected, return default prices and total = 0
      if (!selectedRisks || selectedRisks.length === 0) {
        return defaultObject;
      }

      // Find the pricing entry for the selected risks
      const selectedEntry = pricingTable.find(
        (item) =>
          item.maximum_amount === amount.toString() &&
          item.risks.length === selectedRisks.length &&
          item.risks.every((risk) => selectedRisks.includes(risk))
      );

      if (!selectedEntry) {
        return defaultObject;
      }

      // Get the price for the selected risks
      const monthlyCost = selectedEntry.price;
      const annualCost = monthlyCost * 12;

      // Calculate additional prices for unselected risks
      const additionalPrices = {};
      const unselectedRisks = allRisks.filter((risk) => !selectedRisks.includes(risk));

      unselectedRisks.forEach((unselectedRisk) => {
        const additionalEntry = pricingTable.find(
          (item) =>
            item.maximum_amount === amount.toString() &&
            item.risks.length === selectedRisks.length + 1 &&
            selectedRisks.every((risk) => item.risks.includes(risk)) &&
            item.risks.includes(unselectedRisk)
        );

        if (additionalEntry) {
          additionalPrices[unselectedRisk] = additionalEntry.price - monthlyCost;
        } else {
          additionalPrices[unselectedRisk] = 'N/A';
        }
      });

      return {
        price_combo_id: selectedEntry.id,
        risks: defaultPrices,
        additionalPrices,
        annual: annualCost.toFixed(2),
        monthly: monthlyCost,
      };
    },
    [pricingTable, risks]
  );

  const priceInfo = useMemo(() => {
    if (!risks.length)
      return {
        risks: {},
        additionalPrices: {},
        annual: 0,
        monthly: 0,
      };

    return calculatePrice(maximumPrice, selected);
  }, [risks.length, calculatePrice, maximumPrice, selected]);

  const getRiskPrice = (risk) => {
    if (priceInfo.additionalPrices[risk.id]) {
      return `+ ${currencyFormatter(priceInfo.additionalPrices[risk.id] ?? 0, { code: 'EUR' })}`;
    }
    if (selected.includes(risk.id)) {
      return '-';
    }
    return currencyFormatter(priceInfo.risks[risk.id] ?? 0, { code: 'EUR' });
  };

  const CustomSlider = ({ onChange }) => {
    const [sliderValue, setSliderValue] = React.useState(500);

    // Transform the value for display purposes
    const transformValue = (value) => {
      switch (value) {
        case 0:
          return 500;
        case 50:
          return 1000;
        case 100:
          return 2000;
        default:
          return value;
      }
    };

    // Reverse transform the value for setting the slider value
    const reverseTransformValue = (value) => {
      switch (value) {
        case 500:
          return 0;
        case 1000:
          return 50;
        case 2000:
          return 100;
        default:
          return value;
      }
    };

    return (
      <Slider
        value={reverseTransformValue(sliderValue)}
        min={0}
        max={100}
        marks={[
          { value: 0, label: '500 €' },
          { value: 50, label: '1000 €' },
          { value: 100, label: '2000 €' },
        ]}
        step={null}
        onChange={(e, value) => {
          const transformedValue = transformValue(value);
          if (transformedValue !== 1500) {
            onChange(transformedValue);
            setSliderValue(transformedValue);
          }
        }}
      />
    );
  };

  const isValidClientForm = () => {
    return firstName.length > 0 && lastName.length > 0 && email.length > 0 && phone.length > 0;
  }

  const handleCreateClient = () => {
    const newClientObject = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      email: email,
      // client_type: clientObject.client_type,
      country_code: countryCode,
    };
    AvektoiAPIClient.createClient(newClientObject).then(data => {
      getClientData();
      setClient(data)
      setIsCreatingClient(false)              
    }).catch(err => {
      alert(err)
    })
  }

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          borderRadius: 2,
          pt: 2,
          px: 4,
          pb: 3,
        }}
      >
        {isCreatingClient && <>
          <h2 id="parent-modal-title">{t('policies.createClientTitle')}</h2>
          <List style={{ minWidth: 800 }}>
            <ListItem>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography>{t('signup.step1.firstName')}</Typography>
                  <TextField
                    id="outlined-basic"
                    placeholder={t('signup.step1.firstName')}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>{t('signup.step1.lastName')}</Typography>
                  <TextField
                    id="outlined-basic"
                    placeholder={t('signup.step1.lastName')}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography>{t('signup.step2.phoneNumber')}</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4} md={4}>
                      <CountryCodeSelect
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        renderValue={(selected) => selected}
                      >
                        {countryCodes.map((code) => (
                          <MenuItem key={code.code} value={code.code}>
                            {code.name} ({code.code})
                          </MenuItem>
                        ))}
                      </CountryCodeSelect>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <TextField
                        id="outlined-basic"
                        placeholder={t('signup.step2.phoneNumber')}
                        value={phone}
                        type="email"
                        onChange={(e) => setPhone(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>{t('signup.step4.email')}</Typography>
                  <TextField
                    id="outlined-basic"
                    placeholder={t('signup.step4.email')}
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Button
                disabled={!isValidClientForm()}
                variant="contained"
                color="primary"
                style={{ width: 250 }}
                onClick={handleCreateClient}
              >
                {t('policies.create')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ width: 250, marginLeft: 5 }}
                onClick={() => setIsCreatingClient(false)}
              >
                {t('policies.cancel')}
              </Button>
            </ListItem>
          </List>
        </>}
        {!isCreatingClient && <>
          <h2 id="parent-modal-title">{t('policies.createPolicyTitle')}</h2>
          <List>
            <ListItem>
              <Grid container spacing={3}>
                {risks.map((risk, index) => (
                  <Grid item xs={12} md={4} key={index} style={{ textAlign: 'center' }}>
                    <MainCard border={true} sx={{ height: '100%' }}>
                      <Typography variant="h3">{risk.risk_label}</Typography>
                      <div>
                        <div style={{ marginTop: 30, marginBottom: 30 }}>
                          <Typography variant="h1" sx={price}>
                            {getRiskPrice(risk)}
                          </Typography>
                          <Typography variant="h6" color="text.secondary">
                            {t('policies.month')}
                          </Typography>
                        </div>
                        {selected.includes(risk.id) ? (
                          <Button
                            onClick={() => handleSelect(risk.id)}
                            variant="contained"
                            color="success"
                            startIcon={<TickCircle />}
                            sx={{ width: 200 }}
                          >
                            {t('policies.selected')}
                          </Button>
                        ) : (
                          <Button
                            onClick={() => handleSelect(risk.id)}
                            variant="contained"
                            color="error"
                            startIcon={<RemoveCircle />}
                            sx={{ width: 200 }}
                          >
                            {t('policies.unselected')}
                          </Button>
                        )}
                      </div>
                    </MainCard>
                  </Grid>
                ))}
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <MainCard border={true} sx={{ height: '100%' }}>
                    <Grid container spacing={3} justifyContent="center">
                      <Grid item xs={12} md={5} style={{ textAlign: 'center' }}>
                        <Typography variant="h5">{t('signup.step9.maxCompensation')}</Typography>
                        <SliderContainer>
                          <CustomSlider onChange={onSliderChange} />
                        </SliderContainer>
                      </Grid>
                    </Grid>
                  </MainCard>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={3}>
                <Grid item xs={8} md={8}>
                  <Autocomplete
                    disablePortal
                    options={clientData}
                    value={client? clientData.find(o => o.id == client.id): null}
                    // inputValue={client}
                    getOptionLabel={(option) => `${option.first_name} ${option.last_name} - ${option.email}`}
                    renderInput={(params) => <TextField value={client} {...params} label={t('policies.selectClient')} />}
                    onChange={(event, value) => setClient(value)}
                  />
                </Grid>
                <Grid item xs={4} md={4} style={{ textAlign: 'right' }}>
                  <Button variant='contained' color='primary' onClick={() => setIsCreatingClient(true)}>{t('policies.createClient')}</Button>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <MainCard border={true}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">{t('policies.paymentMethod')}</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={paymentMethod}
                        onChange={(event, value) => setPaymentMethod(value)}
                      >
                        <FormControlLabel value="pay_directly" control={<Radio />} label={t('policies.payDirectly')} />
                        <FormControlLabel
                          value="send_link_to_email"
                          control={<Radio />}
                          label={t('policies.sendLinkToEmail')}
                        />
                      </RadioGroup>
                    </FormControl>
                  </MainCard>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <MainCard border={true} sx={{ textAlign: 'right' }}>
                    <Typography variant="h6" color="text.secondary">
                      {t('policies.total')}
                    </Typography>
                    <Typography variant="h1" sx={price}>
                      {currencyFormatter(priceInfo.monthly, { code: 'EUR' })}
                    </Typography>
                    <Typography color="text.secondary">
                      {`(${currencyFormatter(priceInfo.annual, { code: 'EUR' })} ${t('signup.step9.year')})`}
                    </Typography>
                  </MainCard>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={3} sx={{ textAlign: 'right' }}>
                <Grid item xs={12} md={12}>
                  <Button
                    disabled={selected.length === 0 || !client}
                    variant="contained"
                    color="primary"
                    style={{ width: 250 }}
                    onClick={handleCreatePolicy}
                  >
                    {t('policies.create')}
                  </Button>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </>}
      </Box>
    </Modal >
  );
};

export default NewPolicyForm;
