import React from "react";
import { Typography, Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const TitleSection = ({ 
  button,
  titleParts, 
  paragraph = [], 
  paragraphVisible = true,
  titleSizes = [], 
  titleColors = [] ,
  paragraphColors=[],
  textAlign,
  fontSize,
  isMobile,
  margin,
  underline, bulletPoints,
  newPoints=[],
  points = [],
  fin=[],
  newLine,
  first,
  sub=[],
  subColor=[]
}) => {
    const navigate = useNavigate()
  
  return (
    <Box sx={{ textAlign: textAlign? textAlign: "center", marginBottom: 4 , paddingLeft:"5px" ,paddingRight:"5px" }}>
      <Typography variant={"h3"} component="div" sx={{ fontWeight: "bold",   margin: margin? margin:"0 auto",  
}}>
        {titleParts.map((part, index) => (
        <Typography 
        key={index}
        component="span"
        sx={{
          color: titleColors[index] || "inherit",
          fontSize: titleSizes[index] || "inherit",
          display: "inline",
          fontWeight: "bold",
          textAlign: "center",
          lineHeight: 1.2,
        }}
        
      >
      
            {part}
            {index < titleParts.length - 1 && " "} 

        
          </Typography>
        ))}
      </Typography>
        {
          sub.length > 0 && <Box
          sx={{
            display: "flex",
            flexDirection:  "row",
            flexWrap:  "wrap",
            margin:  "10px auto",
            paddingLeft:isMobile ? "10px": "none",
            paddingRight:isMobile ? "10px": "none",
            marginBottom:"0px",
            alignItems: "center" ,
            gap:0.5
          }}>
          
             {sub.map((part, index) => (
         <Typography
           key={index}
           variant="body1"
           sx={{
             textAlign: "center",
             color: subColor[index] || "#888",
             fontWeight:  "normal",
             fontSize:  isMobile ? "16px":"18px",
           }}
         >
          
           {part}
     
             
            </Typography>))}

</Box>
        }

      {paragraphVisible && paragraph.length > 0 && (
       <Box
       sx={{
         display: "flex",
         flexDirection: newLine ? "column" : "row",
         flexWrap: newLine ? "nowrap" : "wrap",
         margin: newLine ? "0px auto" :  margin || "20px auto",
         alignItems:  "center",
       }}
     >
       {paragraph.map((part, index) => (
         <Typography
           key={index}
           variant="body1"
           sx={{
             textAlign: textAlign ||  newLine ? "left": "center",
             color: paragraphColors[index] || "#888",
             fontWeight: paragraphColors[index] === "#F066B5" ? "bold" : "normal",
             marginRight: newLine ? 0 : 0.5,
             fontSize: isMobile? "16px" :fontSize || "16px",
             paddingLeft: isMobile ? "10px":"none",
             paddingRight: isMobile ? "10px":"none",
          
           }}
         >
          
           {part}
     
              {index < paragraph.length - 1 && " "} 
              {(part == " nous sommes là") && <br></br>}
            </Typography>
          ))}
{ newPoints.length > 0 && 
  <ul style={{marginTop:"5px", marginBottom:"0px" ,
  
   }}>
{newPoints.map((part, index) => (
    
         <Typography
           key={index}
           variant="body1"
           sx={{
             textAlign: textAlign ||  newLine ? "left": "center",
             color:  "#888",
             fontWeight:   "normal",
             marginRight: newLine ? 0 : 0.5,
             fontSize: fontSize || "16px",
             paddingLeft: isMobile ? "5px":"none",
             paddingRight: isMobile ? "5px":"none",
          
           }}
         >
          <li>
           {part}
           </li>
              {index < paragraph.length - 1 && " "} 
              {(part == " nous sommes là") && <br></br>}
            </Typography>
          )) }           </ul>
}

        </Box>
      )}

{bulletPoints && points.length > 0 && (
        <Box sx={{ display: "inline-flex", flexWrap: "wrap", margin:  "-10px 50px" }}>
          <ul>
          {points.map((part, index) => (
            <li>
            <Typography
              key={index}
              variant="body1"
              sx={{
                textAlign: textAlign || "center",
                color: paragraphColors[index] || "#888",
                marginRight: 1,  
                fontSize: fontSize || "16px",
              }}
            >
              {part}
              {index < paragraph.length - 1 && " "} 
            </Typography>
            </li>
          ))}
          </ul>

          
        </Box>
      )}

      
{fin.length > 0 && 
<div style={{marginTop:"-15px"}}>
{fin.map((part, index) => (
            <Typography
              key={index}
              variant="body1"
              sx={{
                textAlign: textAlign || "center",
                color: paragraphColors[index] || "#888",
                display: "inline-block", 
                marginRight: 1,  
                fontSize: fontSize || "16px",
              }}
            >
              {part}
            </Typography>

          ))}</div>}

          
      
    </Box>
  );
};

export default TitleSection;
