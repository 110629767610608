import React, { useState } from 'react';
import {
  Grid,
  Box,
  Stack,
  useMediaQuery,
  List,
  ListItemText,
  Drawer,
  IconButton,
  ListItemButton
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoMain from 'components/logo/LogoMain';
import ButtonComponent from 'components/ButtonComponent';
import { useTranslation } from 'react-i18next';
import Footer from 'layouts/Dashboard/Footer';
import { useNavigate } from 'react-router-dom';
import mascott from "../../assets/mascot_2.png"
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import { initGA, trackPageView, trackEvent } from '../../utils/analytics';

const HomepageLayout = ({ children }) => {
  const {t} = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [cookies] = useCookies(['cookie_consent']);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    if (cookies.cookie_consent) {
      initGA();
      trackPageView(window.location.pathname);
    }
  }, [cookies.cookie_consent]);

  const handleNavigateLogin = () => {
    navigate('/login')
    trackEvent({ category: 'Homepage', action: 'login_button', label: t('homePage.login') });
  }

  const handleNavigateSignUp = () => {
    navigate('/sign-up')
    trackEvent({ category: 'Homepage', action: 'sign_up_button', label: t('homePage.requestQuote') });
  }

  const handleNavigateDiscount = () => setTimeout(() => {
    navigate('/sign-up?discount=AVEKTOI2025');
    trackEvent({ category: 'Homepage', action: 'discount_button', label: '2 mois gratuits' });
  });

  const drawerContent = (
    <Box
      sx={{width: 250}}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItemButton onClick={handleNavigateSignUp}>
          <ListItemText primary={t('homePage.requestQuote')}/>
        </ListItemButton>
       
  
        <ListItemButton onClick={handleNavigateLogin}>
          <ListItemText primary={t('homePage.login')}/>
        </ListItemButton>
        
      </List>
    </Box>
  );


  return (
    <div>
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        overflow: 'auto',
        position: 'relative',
        overflowX: 'hidden',
      }}
    >
      {!isMobile && (
        <Box
          sx={{
            position: 'absolute',
            top: 120,
            left: -20,
            zIndex: 1,
            width: '40%',
            height: '60%',
            overflow: 'hidden',
            '@media (max-width: 1400px)': {
              top: 170,
              width: '35%',
              height: '50%',
            },
            '@media (max-width: 1220px)': {
              top: 120,
              width: '30%',
              height: '55%',
            },
            '@media (max-width: 1140px)': {
              top: 100,
              width: '27%',
              height: '52%',
            },
          }}
        >
          
        </Box>
      )}

      {!isMobile && (
        <Box
          sx={{
            position: 'absolute',
            top: 300,
            right: 0,
            zIndex: 1,
            width: '36%',
            height: '60%',
            overflow: 'hidden',
            '@media (max-width: 1400px)': {
              width: '31%',
              height: '50%',
            },
            '@media (max-width: 1200px)': {
              width: '31%',
              height: '55%',
            },
            '@media (max-width: 1020px)': {
              width: '31%',
              height: '52%',
            },
          }}
        >
         
        </Box>
      )}


      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        position="sticky"
        top="0"
        sx={{
          px: 3,
          height: '100px',
          borderBottom: '1px solid lightgray',
          zIndex: 9999, backgroundColor: 'white',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          padding: '7px'
        }}
      >
        <Box sx={{paddingLeft: '15px', cursor:'pointer'}} onClick={()=> navigate('/')
}>
          <LogoMain
            height="50px"
            width="150px"

          />
        </Box>

        <Box sx={{display: {xs: 'block', md: 'none'}}}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <MenuIcon/>
          </IconButton>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{display: {xs: 'none', md: 'flex'}}}
          paddingRight="15px"
        >
          <ButtonComponent
            label={t('homePage.requestQuote')}
            width="150px"
            height="40px"
            onClick={handleNavigateSignUp}
            borderRadius="50px"

          />


     <ButtonComponent
            label={t('homePage.login')}
            width="150px"
            height="40px"
            backgroundColor="white"
            color="grey"
            border = "2px solid grey"
            onClick={handleNavigateLogin}
            borderRadius="50px"
          />
         
        </Stack>
      </Stack>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{ zIndex: 10000 }}
      >
        {drawerContent}
      </Drawer>

      <Grid
        container
        style={{
          flex: 0.9,
          textAlign: '-webkit-center',
          width: '100%',
          margin: '0 auto',
          zIndex: 2,
        }}
      >
        <div style={{width: '100%', paddingTop: 50}}>{children}</div>
      </Grid>
      <Footer isMobile={isMobile}/>
      
    </Box>
    
    <Box
      sx={{
        position: "fixed",
        bottom: -20,
        //left: -50,
        right:-50,
        zIndex: 9999,
        width: 'auto',
        height: 'auto',
        transition: 'all 5s ease-in-out',  
      }}
    >
      <img 
    src={mascott} 
    alt="Mascotte" 
    style={{ 
      width: "300px", 
      height: "200px", 
      objectFit: "contain" ,
      transition: 'all 3s ease-in-out',  

    }}
  
/>




</Box>


<Box
  sx={{
    position: "fixed",
    bottom: 90,  
    //left: 22,
    right:30,
    zIndex: 9999,
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    opacity: 0.9,
    animation: 'floatUp 4s ease-in-out infinite',
    cursor:"pointer"
  }}
  onClick={handleNavigateSignUp}

>
  <Box
    sx={{
      position: "relative",
      backgroundColor: '#F066B5',
      color: 'white',
      padding: '10px 20px',
      borderRadius: '15px',
      fontSize: '14px',
      fontWeight: 'bold',
      textAlign: 'center',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      maxWidth: '200px',
    }}
    onClick={handleNavigateDiscount}
  >
    <p style={{ margin: 0 }}>2 mois gratuits</p>
  </Box>

  <Box
    sx={{
      position: "absolute",
      bottom: '-10px',  
      left: '50%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderTop: '10px solid #F066B5',  
    }}
  />
</Box>

<style>{`
  @keyframes floatUp {
    0% { transform: translateY(0); }
    50% { transform: translateY(-15px); }
    100% { transform: translateY(0); }
  }
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
`}</style>

  </div>
  );
};

export default HomepageLayout;
