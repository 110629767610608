import React, { useEffect, useState } from "react";
import { Box, Button, Container, Typography, TextField ,Checkbox, FormControlLabel} from "@mui/material";
import PreSaleServices from "../../../services/PreSaleService"; 

import mascot from "../../../assets/mascot_2.png";
import background from "../../../assets/comingsoon1.png";
import Logo from "../../../assets/Logo2.png";
import { useWindowSize } from 'react-use'
import Confetti from 'react-confetti'
import IconButton from 'components/@extended/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import zIndex from "@mui/material/styles/zIndex";

const calculateTimeLeft = () => {
  const targetDate = new Date("2025-03-14T20:00:00").getTime();
  const now = new Date().getTime();
  const timeLeft = targetDate - now;

  if (timeLeft <= 0) return null;

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
}

export default function ComingSoonPage() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [email, setEmail] = useState(""); 
  const [message, setMessage] = useState(""); 
  const [isChecked, setIsChecked] = useState(true); 
  const [open2, setOpen2] = useState(false);
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [showConfetti, setShowConfetti] = useState(false);
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => setOpen2(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);


  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleEmailSubmit = async () => {
    if (!email) {
        setMessage("Veuillez saisir une adresse e-mail.");
        return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        setMessage("Veuillez saisir une adresse e-mail valide.");
        return;
    }

    try {
      console.log(email)
      const emailLower = email.toLowerCase()
      console.log(emailLower)

        const responseMessage = await PreSaleServices.presale(emailLower);
        if(responseMessage=== "Promo code sent successfully!"){
          setShowConfetti(true);
          setTimeout(() => {
            setShowConfetti(false);
          }, 5000);
        }
        const translatedMessage =
            responseMessage === "Promo code sent successfully!"
                ? "Code promo envoyé avec succès !" 
                : (responseMessage === "Email already registred in our database") ? "Cette adresse e-mail a déjà été utilisée." :responseMessage;
                
        setMessage(translatedMessage);
        
    } catch (error) {
        const errorMessage = error.response?.data?.error || error.response?.data?.message;

        if (errorMessage === "Email already registred in our database") {
            setMessage("Cette adresse e-mail a déjà été utilisée.");
        } else {
            setMessage(
                errorMessage || "Une erreur s'est produite. Veuillez réessayer."
            );
        }
    }
};

  
  
  
  
  

  return (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center",
        position: "relative",
        overflow: "hidden",
        color: "#000",
      }}
      >
        {showConfetti &&
        <Confetti
        recycle={false} 
          gravity={0.3}   
          numberOfPieces={500} 
        width={width}
        height={height}
      />
        }
        <Box
            sx={{
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 0,
                width: "95vw",    
            }}
        >
            <img
                src={mascot}
                alt="Mascotte"
                style={{
                    width: "100%", // Assure que l'image utilise toute la largeur du conteneur
                    height: "auto",
                    display:"block",
                }}
            />
        </Box>
      <Container>
        <Box sx={{ marginBottom: "20px", marginTop: "30px" }}>
          <img
            src={Logo}
            alt="Avektoi Logo"
            style={{ height: "50px", marginBottom: "10px" }}
          />
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "19px",
              fontWeight: "bold",
            }}
          >
            <span style={{ color: "#000" }}>L'assurance qui </span>
            <span style={{ color: "#F066B5" }}>pense à vous</span>
          </Typography>
        </Box>

        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            marginTop: "25px",
            marginBottom: "25px",
            fontSize: "40px",
            letterSpacing: "2px",
          }}
        >
          {timeLeft
            ? `${timeLeft.days}j : ${timeLeft.hours}h : ${timeLeft.minutes}m : ${timeLeft.seconds}s`
            : "We're Live!"}
        </Typography>

        <Typography
          sx={{
            fontSize: "18px",
            color: "#333",
          }}
        >
          Prêt à redécouvrir{" "}
          <span style={{ color: "#F066B5", fontWeight: "bold" }}>
            l'assurance
          </span>
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            marginBottom: "20px",
            color: "#333",
          }}
        >
          et profiter de votre offre{" "}
          <span style={{ color: "#F066B5", fontWeight: "bold" }}>
            exclusive
          </span>{" "}
          ?
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            marginBottom: "20px",
          }}
        >
          <TextField
            placeholder="Adresse e-mail"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setMessage(""); 
            }}
            sx={{
              background: "rgba(255,255,255,0.8)",
              borderRadius: "25px",
              width: "300px",
              "& .MuiOutlinedInput-root": {
                border: "2px solid #F066B5",
                borderRadius: "25px",
                "& input": {
                  textAlign: "center",
                  fontSize: "14px",
                },
              },
            }}
          />
          <Button
            variant="contained"
            disabled={!isChecked}
            onClick={handleEmailSubmit} 
            sx={{
              backgroundColor: isChecked ? "#F066B5" : "#ccc",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              padding: "8px 20px",
              borderRadius: "25px",
              "&:hover": {
                backgroundColor: isChecked ? "#e055a3" : "#ccc",
              },
            }}
          >
            Recevoir mon offre
          </Button>
        </Box>
        <Box
  sx={{
    position: "relative",

  }}
>
        <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
                sx={{ color: "#F066B5", "&.Mui-checked": { color: "#F066B5" },  }}
              />
            }
            label={"J'ai lu et j'accepte les conditions"}
            
          />
           <Tooltip title="Conditions" style={{marginLeft:"-15px"}}>
                      <IconButton onClick={() => handleOpen()}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                      <Typography variant="h6">Conditions</Typography>
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                      En renseignant votre adresse email, vous acceptez d’être contacté(e) par 
                      <strong> Tendanz Courtage</strong> (sous la marque avektoi) afin d’être informé(e) de la 
                      disponibilité de notre produit. Vous acceptez également de recevoir des 
                      informations sur nos offres et services.<br /><br />
                      Vous pouvez retirer votre consentement à tout moment en cliquant sur le lien 
                      de désinscription présent dans nos emails ou en nous contactant à 
                      <a href="mailto:contact@avektoi.fr"> contact@avektoi.fr</a>.
                    </DialogContent>
                  </Dialog>
          
        </Box>

        {message && ( 
          <Typography
            sx={{
              marginTop: "10px",
              color: message.includes("erreur") ? "red" : "green",
            }}
          >
            {message}
          </Typography>
        )}

        <Box
          sx={{
            position: "relative",
            zIndex: "1",
            display: "inline-block",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "18px",
              color: "#666",
              fontWeight: 600,
            }}
          >
            Je veux en savoir plus sur{" "}
            <span style={{ color: "#F066B5" }}>avektoi</span>
            <Tooltip title="avektoi">
                      <IconButton onClick={() => handleOpen2()}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
            <Dialog open={open2} onClose={handleClose2}>
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                      <Typography variant="h6">Bientôt...</Typography>
                      <IconButton
                        aria-label="close"
                        onClick={handleClose2}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                          
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                    avektoi, bien plus qu’une assurance, un allié pour votre bien-être mental. 🌿 Accédez à un accompagnement sur mesure, des experts à votre écoute et des solutions pensées pour vous. Parce que votre résilience mérite empathie et accompagnement 💙. Soyez parmi les premiers à en profiter ! 😊
                    </DialogContent>
                  </Dialog>
          </Typography>
        </Box>
      </Container>
          
    </Box>
  );
}
