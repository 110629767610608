import axios from "axios"
import Links from "../common/Links.json"
import {APP_API_BASE_URL, ACCESS_API_KEY_SAMPLE} from "../common/Constants";
import ApiHelper from "../common/ApiHelper";
import Headers from "../common/types/Headers";


const headers = new Headers();

const presale = async (email) => {
    try {

        const response = await axios.post(Links.presale, { email }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.message;
    } catch (error) {
        const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
        return errorMessage;
    }
};


export default {presale}