import React, { useEffect, useRef, useState } from 'react';
import { styled, Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import HomePageLayout from 'layouts/frontOffice/HomePageLayout';
import { Stack, useMediaQuery, Grid, CardMedia, Card, CardContent, Paper, TextField, IconButton, Button, DialogActions, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Constants from 'common/Constants';
import { Editor } from '@tinymce/tinymce-react';
import dateFormat, { masks } from "dateformat";
import BlogDetailComponent from 'components/BlogDetailComponent';
const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const BlogDetail = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <HomePageLayout>
      <Container style={{ maxWidth: 800, margin: 'auto' }}>
        <Stack
          direction="row"
          sx={{
            borderRadius: "50px",
            paddingTop: isMobile ? "50px" : "50px",
            paddingBottom: "50px",
            marginBottom: "30px",
            width: isMobile ? "90%" : "85%",
          }}
        >
          <BlogDetailComponent/>          
        </Stack>
      </Container>

    </HomePageLayout>
  );
};

export default BlogDetail;
