import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import MainCard from 'components/MainCard';
import dateFormat from 'dateformat';
import { useTranslation } from 'react-i18next';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ScrollX from 'components/ScrollX';
import { format as currencyFormatter } from 'currency-formatter';
import axiosServices, { fetcher } from 'utils/axios';
import { useState, useEffect } from 'react';
import NewButtonComponent from 'components/NewButton';
import Swal from 'sweetalert2';
import PoliciesService from 'services/PoliciesService';
import InputComponent from 'components/InputComponent';

export default function ExpandingPolicyDetail({ data , isAdmin}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [pricingTable, setPricingTable] = useState([]);
  const [priceComboRev, setPriceComboRev] = useState(null);
  const [risks, setRisks] = useState({});
  const [targetEmail, setTargetEmail] = useState('');

  const getRiskPriceTable = async () => {
    const request = await fetcher('/risk_price_combo');
    setPricingTable(request);
  };

  const getRiskObjects = async () => {
    const riskData = await fetcher(`/risks/list-by-product-id/${data.product_id}`);
    const formattedRiskData = riskData.reduce((acc, risk) => {
      acc[risk.id] = risk;
      return acc;
    }, {});
    setRisks(formattedRiskData);
  };

  const resendDocusignEmail = async () => {
    axiosServices.post(`/docusign/send-for-signature/`, { quotation_id: data.id, email: targetEmail }).then((response) => {
      alert('Success')
    }).catch((error) => {
      console.error(error);
    })
  }

  const handleSendCancellationRequest = async (policy) => {
    console.log('Sending cancellation request for policy:', policy);

    const confirmation = await Swal.fire({
      title: t('policies.cancelPolicyConfirmation'),
      icon: 'warning',
      confirmButtonText: t('buttons.yes'),
      cancelButtonText: t('buttons.no'),
      confirmButtonColor: theme.palette.primary.main,
      showCancelButton: true,
      reverseButtons: true,
    });
    if (!confirmation.isConfirmed) return;

    PoliciesService.sendCancellationRequest(policy.id)
      .then((response) => {
        console.log('Request response: ', response);
        Swal.fire({
          title: t('policies.cancelPolicySuccess'),
          icon: 'success',
          timer: 3000,
        });
      })
      .catch((err) => {
        console.log('Policy cancellation error: ', err);
        Swal.fire({
          title: t('policies.cancelPolicyError'),
          icon: 'error',
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    if (!data.price_combo_id) return;
    if (Object.keys(risks).length === 0) return;

    const priceComboRevData = pricingTable.find((item) => item.id === data.price_combo_id);
    if (!priceComboRevData) return;

    const formattedRisks = priceComboRevData.risks.map((riskId) => {
      const riskPrice = pricingTable.find(
        (item) =>
          item.maximum_amount === priceComboRevData.maximum_amount &&
          item.risks.length === 1 &&
          item.risks.includes(riskId)
      );

      return {
        ...risks[riskId],
        price: riskPrice ? riskPrice.price : 0,
      };
    });

    const totalAmountWithoutDiscount = formattedRisks.reduce((acc, risk) => acc + parseFloat(risk.price), 0);

    const priceComboRevDataFormatted = {
      ...priceComboRevData,
      risks: formattedRisks,
      total_amount_without_discount: totalAmountWithoutDiscount,
    };

    setPriceComboRev(priceComboRevDataFormatted);
  }, [pricingTable, data.price_combo_id, risks]);

  useEffect(() => {
    getRiskPriceTable();
    getRiskObjects();
  }, []);

  const renderPolicyStatus = (value) => {
    switch (value) {
      case 'ACTIVATED':
        return <Chip color="success" label="Actif" size="Default" variant="light" />;
      case 'EXPIRED':
        return <Chip color="info" label="Clos" size="Default" variant="light" />;
      case 'AWAITING_PAYMENT':
        return <Chip color="secondary" label="En attente de paiement" size="Default" variant="light" />;

      case 'CANCELLED':
        return <Chip color="error" label="Résilié" size="Default" variant="light" />;
      default:
        return <Chip color="default" label="" size="Default" variant="light" />;
    }
  };

  if (!data) {
    return <div>{t('policies.noPolicyData')}</div>; // Render a fallback if data is null or undefined
  }

  return (
    <Grid container spacing={2.5} sx={{ pl: { xs: 0, sm: 5, md: 6, lg: 10, xl: 12 } }}>
      <Grid item xs={12} sm={5} md={4} xl={3.5}>
        <MainCard title={t('policies.policyDetails')}>
          <List sx={{ py: 0 }}>
            <ListItem divider={!matchDownMD}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Stack spacing={0.5}>
                    <Typography color="secondary">{t('policies.policyNumber')}</Typography>
                    <Typography variant="h3">{data.amendment_number}</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem divider={!matchDownMD}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack direction="row" spacing={4} alignItems="center">
                    <Typography color="secondary">{t('policies.status')}:</Typography>
                    <Typography>{data.status_id && renderPolicyStatus(data.status_id)}</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </ListItem>

            <ListItem divider={!matchDownMD}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={0.5}>
                    <Typography color="secondary">{t('policies.registrationDate')}</Typography>
                    <Typography>{dateFormat(new Date(data.payment_date), 'dd-mm-yyyy')}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={0.5}>
                    <Typography color="secondary">{t('policies.expirationDate')}</Typography>
                    <Typography>
                      {data.end_date
                        ? dateFormat(new Date(data.end_date), 'dd-mm-yyyy')
                        : dateFormat(
                          new Date(
                            new Date(data.payment_date).setFullYear(new Date(data.payment_date).getFullYear() + 1)
                          ),
                          'dd-mm-yyyy'
                        )}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem divider={!matchDownMD}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={0.5}>
                    <Typography color="secondary">{t('policies.paymentFrequency')}</Typography>
                    <Typography>{data.payment_frequency}</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </MainCard>
      </Grid>
      <Grid item xs={12} sm={7} md={8} xl={8.5}>
        <Stack spacing={2.5}>
          <MainCard title={t('policies.additionalPolicyDetails')}>
            <List sx={{ py: 0 }}>
              {/* <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('policies.policyHolder')}</Typography>
                      <Typography variant="h3">{data.policy_holder ?? t('policies.noData')}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('policies.coverageAmount')}</Typography>
                      <Typography variant="h3">{data.coverage_amount ?? t('policies.noData')}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('policies.premiumAmount')}</Typography>
                      <Typography>
                        {data.premium_amount ? `${data.premium_amount} ${data.currency}` : t('policies.noData')}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('policies.policyType')}</Typography>
                      <Typography>{data.policy_type ?? t('policies.noData')}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('policies.policyDescription')}</Typography>
                      <Typography>{data.description ?? t('policies.noData')}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem> */}
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Compensation maximale par sinistre</Typography>
                      <Typography>
                        {currencyFormatter(data.maximum_amount_of_compensation_per_claim, { code: 'EUR' })}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Nombre d'avis par sinistre</Typography>
                      <Typography>{data.number_of_claim_per_year}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ScrollX>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ minWidth: 150 }}> Risque</TableCell>
                        <TableCell>Description du risque</TableCell>
                        <TableCell>Prix</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* New risks with price combo */}
                      {priceComboRev &&
                        priceComboRev.risks.map((risk) => (
                          <TableRow key={risk.id}>
                            <TableCell>{risk.risk_label}</TableCell>
                            <TableCell>{risk.description}</TableCell>
                            <TableCell>{currencyFormatter(risk.price, { code: 'EUR' })}</TableCell>
                          </TableRow>
                        ))}
                      {/* Old risks */}
                      {!priceComboRev &&
                        data.risks_rev &&
                        data.risks_rev.map((risk) => (
                          <TableRow key={risk.id}>
                            <TableCell>{risk.risk_label}</TableCell>
                            <TableCell>{risk.description}</TableCell>
                            <TableCell>{currencyFormatter(risk.price, { code: 'EUR' })}</TableCell>
                          </TableRow>
                        ))}
                      {priceComboRev && (
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography sx={{ textDecoration: 'line-through', fontWeight: 'bold' }}>
                              {currencyFormatter(priceComboRev.total_amount_without_discount ?? 0, { code: 'EUR' })}
                            </Typography>
                            <Typography sx={{ fontWeight: 'bold' }}>
                              {currencyFormatter(data.total_amount, { code: 'EUR' })}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                      {data.status_id === 'ACTIVATED' && (
                        <TableRow>
                          <TableCell colSpan={3} align="right" sx={{ paddingTop: 2 }}>
                            <NewButtonComponent
                              label={t('policies.buttons.cancelPolicy')}
                              onClick={() => handleSendCancellationRequest(data)}
                              size="small"
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ScrollX>
            </List>
          </MainCard>
        </Stack>
      </Grid>
      {isAdmin && 
        <Grid item xs={12} sm={12} md={12} xl={12}>
        <MainCard title={t('policies.resentContractDocusignEmail')}>
          <List>
            <InputComponent
              label="Email"
              customMargin={true}
              type="email"
              value={targetEmail}
              onChange={(e) => setTargetEmail(e.target.value)}
            />
            <p>
              <small><em>Laissez l'e-mail vide si vous souhaitez l'envoyer au propriétaire du contrat.</em></small>
            </p>
            <Button variant="contained" type="submit" onClick={resendDocusignEmail}>
              {t('policies.resentContractDocusignEmail')}
            </Button>
          </List>
        </MainCard>
      </Grid>
    }
    </Grid>
  );
}

ExpandingPolicyDetail.propTypes = { data: PropTypes.object.isRequired };
