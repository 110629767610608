import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import Slider from '@mui/material/Slider';
import { Button, Grid, List, ListItem, Typography, Modal, Box } from '@mui/material';
import MainCard from 'components/MainCard';
import { TickCircle } from 'iconsax-react';
import axios from 'utils/axios';
import { RemoveCircle } from '@mui/icons-material';
import { format as currencyFormatter } from 'currency-formatter';
import axiosServices, { fetcher } from 'utils/axios';

const SliderContainer = styled('div')({
  width: '70%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: '10px',
  color: '#EB3D9F',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '17px',
  lineHeight: '18px',
  letterSpacing: '0.5px',
  marginLeft: '15%',
  '@media (max-width: 1370px)': {
    width: '80%',
    marginLeft: '10%',
  },
  '@media (max-width: 955px)': {
    width: '90%',
    marginLeft: '5%',
    marginTop: '0px',
  },
});

const price = {
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: 1,
};

const UpgradePolicyForm = ({ userType, openModal, setOpenModal, currentPolicy }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [risks, setRisks] = useState([]);
  const [productId] = useState('19edb627-9ddb-4df4-bc60-8ac4ac8c2e37'); //TODO: replace with actual product ID
  const [maximumPrice, setMaximumPrice] = useState(500);
  const [pricingTable, setPricingTable] = useState([]);
  const [selectedPriceCombo, setSelectedPriceCombo] = useState({});

  useEffect(() => {
    getRiskObjects();
    getRiskPriceTable();
  }, []);

  useEffect(() => {
    if (!currentPolicy || !currentPolicy.price_combo_id) {
      return;
    }

    const currentPolicyPriceCombo = pricingTable.find((combo) => combo.id === currentPolicy.price_combo_id);
    if (currentPolicyPriceCombo) {
      setSelectedPriceCombo(currentPolicyPriceCombo);
    }
  }, [currentPolicy, pricingTable]);

  useEffect(() => {
    if (!selectedPriceCombo) {
      return;
    }

    const priceCombo = pricingTable.find((combo) => combo.id === selectedPriceCombo.id);
    if (priceCombo) {
      setSelected(priceCombo.risks);
    }
  }, [pricingTable, selectedPriceCombo]);

  const getRiskObjects = async () => {
    let request = await axios.get(`/risks/list-by-product-id/${productId}`);
    setRisks(request.data);
  };

  const getRiskPriceTable = async () => {
    let request = await fetcher('/risk_price_combo');
    setPricingTable(request);
  };

  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((item) => item !== id) : [...prevSelected, id]
    );
  };

  const handleUpgradePolicy = async () => {
    if (selected.length > 0 && paidAmount > 0) {
      const { data } = await axiosServices.post('/payments/create-checkout-session-for-upgrading-policy', {
        quote_id: currentPolicy.id,
        new_price_combo_id: priceInfo.price_combo_id,
        amount: paidAmount,
      });
      const { url } = data;

      window.location.href = url;
    }
  };

  const CustomSlider = ({ sliderValue, onChange }) => {
    const sliderMarks = [
      { value: 0, label: '500 €' },
      { value: 50, label: '1000 €' },
      { value: 100, label: '2000 €' },
    ];

    // Transform the value for display purposes
    const transformValue = (value) => {
      switch (value) {
        case 0:
          return 500;
        case 50:
          return 1000;
        case 100:
          return 2000;
        default:
          return value;
      }
    };

    // Reverse transform the value for setting the slider value
    const reverseTransformValue = (value) => {
      switch (value) {
        case 500:
          return 0;
        case 1000:
          return 50;
        case 2000:
          return 100;
        default:
          return value;
      }
    };

    return (
      <Slider
        value={reverseTransformValue(sliderValue)}
        min={0}
        max={100}
        marks={sliderMarks}
        step={null}
        onChange={(e, value) => {
          const transformedValue = transformValue(value);
          if (transformedValue !== 1500) {
            onChange(transformedValue);
          }
        }}
      />
    );
  };

  const onSliderChange = (value) => {
    setMaximumPrice(value);
  };

  const calculatePrice = useCallback(
    (amount, selectedRisks) => {
      const allRisks = risks.map((risk) => risk.id);
      const defaultPrices = {};

      // Populate default prices for each individual risk
      allRisks.forEach((risk) => {
        const riskEntry = pricingTable.find(
          (item) => item.maximum_amount === amount.toString() && item.risks.length === 1 && item.risks.includes(risk)
        );

        if (riskEntry) {
          defaultPrices[risk] = riskEntry.price;
        } else {
          defaultPrices[risk] = 'N/A';
        }
      });

      const defaultObject = {
        price_combo_id: undefined,
        risks: defaultPrices,
        additionalPrices: {},
        annual: 0,
        monthly: 0,
      };

      // If no risks are selected, return default prices and total = 0
      if (!selectedRisks || selectedRisks.length === 0) {
        return defaultObject;
      }

      // Find the pricing entry for the selected risks
      const selectedEntry = pricingTable.find(
        (item) =>
          item.maximum_amount === amount.toString() &&
          item.risks.length === selectedRisks.length &&
          item.risks.every((risk) => selectedRisks.includes(risk))
      );

      if (!selectedEntry) {
        return defaultObject;
      }

      // Get the price for the selected risks
      const monthlyCost = selectedEntry.price;
      const annualCost = monthlyCost * 12;

      // Calculate additional prices for unselected risks
      const additionalPrices = {};
      const unselectedRisks = allRisks.filter((risk) => !selectedRisks.includes(risk));

      unselectedRisks.forEach((unselectedRisk) => {
        const additionalEntry = pricingTable.find(
          (item) =>
            item.maximum_amount === amount.toString() &&
            item.risks.length === selectedRisks.length + 1 &&
            selectedRisks.every((risk) => item.risks.includes(risk)) &&
            item.risks.includes(unselectedRisk)
        );

        if (additionalEntry) {
          additionalPrices[unselectedRisk] = additionalEntry.price - monthlyCost;
        } else {
          additionalPrices[unselectedRisk] = 'N/A';
        }
      });

      return {
        price_combo_id: selectedEntry.id,
        risks: defaultPrices,
        additionalPrices,
        annual: annualCost.toFixed(2),
        monthly: monthlyCost,
      };
    },
    [pricingTable, risks]
  );

  const priceInfo = useMemo(() => {
    if (!risks.length)
      return {
        risks: {},
        additionalPrices: {},
        annual: 0,
        monthly: 0,
      };

    return calculatePrice(maximumPrice, selected);
  }, [risks.length, calculatePrice, maximumPrice, selected]);

  const paidAmount = useMemo(() => {
    if (!selectedPriceCombo) {
      return 0;
    }

    const total = priceInfo.monthly - parseInt(selectedPriceCombo.price);
    if (total <= 0) {
      return 0;
    }

    return total;
  }, [selectedPriceCombo, priceInfo]);

  const isAllowUpgrade = useMemo(
    () =>
      selected.length === 0 &&
      selectedPriceCombo &&
      maximumPrice < parseInt(selectedPriceCombo.maximum_amount) &&
      priceInfo.monthly < parseInt(selectedPriceCombo.price),
    [selected.length, selectedPriceCombo, maximumPrice, priceInfo.monthly]
  );

  const getRiskPrice = (risk) => {
    if (priceInfo.additionalPrices[risk.id]) {
      return `+ ${currencyFormatter(priceInfo.additionalPrices[risk.id] ?? 0, { code: 'EUR' })}`;
    }
    if (selected.includes(risk.id)) {
      return '-';
    }
    return currencyFormatter(priceInfo.risks[risk.id] ?? 0, { code: 'EUR' });
  };

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          borderRadius: 2,
          pt: 2,
          px: 4,
          pb: 3,
        }}
      >
        <h2 id="parent-modal-title">{t('policies.upgradePolicyTitle')}</h2>
        <List>
          <ListItem>
            <Grid container spacing={3}>
              {risks.map((risk, index) => (
                <Grid item xs={12} md={4} key={index} style={{ textAlign: 'center' }}>
                  <MainCard border={true} sx={{ height: '100%' }}>
                    <Typography variant="h3">{risk.risk_label}</Typography>
                    <div>
                      <div style={{ marginTop: 30, marginBottom: 30 }}>
                        <Typography variant="h1" sx={price}>
                          {getRiskPrice(risk)}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                          {t('policies.month')}
                        </Typography>
                      </div>
                      {selected.includes(risk.id) ? (
                        <Button
                          onClick={() => handleSelect(risk.id)}
                          variant="contained"
                          color="success"
                          startIcon={<TickCircle />}
                          sx={{ width: 200 , }}
                          style={{textTransform: 'none'}}
                          disabled={selectedPriceCombo ? selectedPriceCombo.risks.includes(risk.id) : false}
                        >
                          {t('policies.selected')}
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleSelect(risk.id)}
                          variant="contained"
                          color="error"
                          startIcon={<RemoveCircle />}
                          sx={{ width: 200 ,textTransform: 'none'}}
                        >
                          {t('policies.unselected')}
                        </Button>
                      )}
                    </div>
                  </MainCard>
                </Grid>
              ))}
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <MainCard border={true} sx={{ height: '100%' }}>
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} md={5} style={{ textAlign: 'center' }}>
                      <Typography variant="h5">{t('signup.step9.maxCompensation')}</Typography>
                      <SliderContainer>
                        <CustomSlider sliderValue={maximumPrice} onChange={onSliderChange} />
                      </SliderContainer>
                    </Grid>
                  </Grid>
                </MainCard>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <MainCard border={true} sx={{ textAlign: 'right' }}>
                  <Typography variant="h6" color="text.secondary">
                    {t('policies.total')}
                  </Typography>
                  <Typography variant="h1" sx={price}>
                    {currencyFormatter(paidAmount, { code: 'EUR' })}
                  </Typography>
                  {/* <Typography color="text.secondary">
                    {`(${currencyFormatter(priceInfo.annual, { code: 'EUR' })} ${t('signup.step9.year')})`}
                  </Typography> */}
                </MainCard>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container spacing={3} sx={{ textAlign: 'right' }}>
              <Grid item xs={12} md={12}>
                <Button
                  disabled={isAllowUpgrade || !paidAmount}
                  variant="contained"
                  color="primary"
                  style={{ width: 250 , textTransform: 'none',}}
                  onClick={handleUpgradePolicy}
                  
                >
                  {t('policies.upgrade')}
                </Button>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Box>
    </Modal>
  );
};

export default UpgradePolicyForm;
