import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project-imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

export default function LockGuard({ children }) {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // const beta_access = sessionStorage.getItem('AVEKTOI_BETA_ACCESSS');
    // if (!beta_access && window.location.href.indexOf('avektoi.') >= 0) {
    //   navigate('/coming-soon');
    //   return;
    // }
  }, [isLoggedIn, navigate, location]);

  return children;
}

LockGuard.propTypes = { children: PropTypes.any };
