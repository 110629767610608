// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DocumentCode2 } from 'iconsax-react';
import { ReactComponent as Complaint } from '../assets/feedback.svg';
import { ReactComponent as Claim } from '../assets/hand-claim.svg';
import { ReactComponent as Policy } from '../assets/policy.svg';

const PolicyIcon = () => <Policy width={25} height={25} />;
const ClaimIcon = () => <Claim width={25} height={25} />;
const ComplaintIcon = () => <Complaint width={30} height={30} />;

// type

// icons
const icons = {
  samplePage: DocumentCode2
};

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //


const clientMenu = {
  id: 'my-inventory',
  title: 'menu.insurance',
  type: 'group',
  children: [    
    {
      id: 'my-policies',
      title: 'menu.policies',
      type: 'item',
      url: '/my-insurances',
      icon: PolicyIcon,
    },
    {
      id: 'my-claims',
      title: 'menu.claims',
      type: 'item',
      url: '/my-claims',
      icon: ClaimIcon,
    },
    {
      id: 'my-complains',
      title: 'menu.complaints',
      type: 'item',
      url: '/my-complaints',
      icon: ComplaintIcon,
    }
  ]
};

export default clientMenu;
