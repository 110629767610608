import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import api from "../../../../services/AvektoiAPIClient";
import Constants from "common/Constants";
import IconButton from "components/@extended/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from "react-router";
import Loader from "components/Loader";

const NumberInputContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "50px",
});

const NumberButton = styled(Button)({
  // backgroundColor: '#90a4ae',
  // color: '#fff',
  height: "40px",
  fontSize: "40px",
});

const NumberInput = styled(TextField)({
  width: "60px",
  textAlign: "center",
  fontSize: "24px",
  lineHeight: "40px",
  "& input": {
    textAlign: "center",
    fontSize: "20px",
    padding: "8px 0",
    paddingLeft: 10,
  },
});

const PeopleStep = ({ nextStep, prevStep }) => {
  const { t } = useTranslation();
  const [numberOfPeople, setNumberOfPeople] = useState(0);
  const [clientObject, setClientObject] = useState({});
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { search } = useLocation();
  const discount = new URLSearchParams(search).get('discount');

  useEffect(() => {
    let clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));
    if (!clientObject) window.location.href = "/sign-up";
    setClientObject(clientObject);

    if (clientObject.nbr_family_members) {
      setNumberOfPeople(clientObject.nbr_family_members);
      navigate('/register-policy')
      return;
    }
  }, []);

  const handleIncrement = () => {
    setNumberOfPeople((prev) => (prev < 20 ? prev + 1 : prev));
  };

  const handleDecrement = () => {
    setNumberOfPeople((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) >= 0 && Number(value) <= 20)) {
      setNumberOfPeople(value === "" ? "" : Number(value));
    }
  };

  const handleNextClick = async () => {
    try {
      setIsLoading(true)
      await api.updateClient(clientObject.id, { nbr_family_members: numberOfPeople.toString() });
      const updatedClientObject = { ...clientObject, nbr_family_members: numberOfPeople };
      sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify(updatedClientObject));
      setIsLoading(false)
      navigate(discount ? `/register-policy?discount=${discount}` : '/register-policy')
      nextStep({ nbr_family_members: numberOfPeople });
    } catch (error) {
      console.error("Error updating family members number:", error);
    }
  };

  const handleBackClick = () => {
   
    prevStep();
  };

  return (
    <>
      {isLoading && <Loader />}
      <Typography variant="h2">{t("signup.step8personal.title")}</Typography>
      <Grid container>
        <Grid item xs={3} style={{ textAlign: '-webkit-center' }}>
          <IconButton onClick={handleBackClick} style={{marginTop:"30px"}} ><ArrowBackIcon style={{ width: 30, height: 30 }} /></IconButton>
        </Grid>
        <Grid item xs={6}>
          <div style={{ marginTop: 30, maxWidth: 600, marginLeft:"-20px"}}>
            <NumberInputContainer>
              <NumberButton onClick={handleDecrement}>-</NumberButton>
              <NumberInput value={numberOfPeople} onChange={handleChange} type="number" inputProps={{ min: 0, max: 20 }} />
              <NumberButton onClick={handleIncrement}>+</NumberButton>
            </NumberInputContainer>
          </div>
        </Grid>
      </Grid>
      <Stack direction="row" alignItems="center" justifyContent="center" columnGap={2}>
        <Button size="large" disabled={numberOfPeople === 0} variant="contained" color="primary" style={{ width: 250 }} onClick={handleNextClick}>
          {t("buttons.next")}
        </Button>
      </Stack>
    </>
  );
};

export default PeopleStep;
