import React, { useEffect, useRef, useState } from 'react';
import { styled, Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import HomePageLayout from 'layouts/frontOffice/HomePageLayout';
import { Stack, useMediaQuery, Grid, CardMedia, Card, CardContent, Paper, TextField, IconButton, Button, DialogActions, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Constants from 'common/Constants';
import { Editor } from '@tinymce/tinymce-react';
import dateFormat, { masks } from "dateformat";
const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const BlogComponent = ({ editable }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    //Admin 
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState(null);
    const [videoUrl, setVideoUrl] = useState('');
    const [posts, setPosts] = useState([]);
    const [editingPost, setEditingPost] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);

    const fileInputRef = useRef(null)

    useEffect(() => {
        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        try {
            const response = await fetch(Constants.APP_API_BASE_URL + '/blog');
            const data = await response.json();
            setPosts(data);
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    const handleSubmit = async (e) => {
        if (title.length == 0 || content.length == 0) {
            alert(t('blog.form_empty_error'))
            return;
        }
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('content', content);
        console.log(content)
        if (image) formData.append('image', image);
        // formData.append('videoUrl', videoUrl);

        try {
            await fetch(Constants.APP_API_BASE_URL + '/blog', {
                method: 'POST',
                body: formData
            });

            setTitle('');
            setContent('');
            setImage(null);
            setImagePreview(null);
            setVideoUrl('');
            fetchPosts();
        } catch (error) {
            console.error('Error creating post:', error);
        }
    };

    const handleDelete = async (id) => {
        let cfm = window.confirm(t('blog.delete_blog_comfirm_message'));
        if (!cfm) return;
        try {
            await fetch(Constants.APP_API_BASE_URL + `/blog/${id}`, {
                method: 'DELETE'
            });
            fetchPosts();
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    };

    const handleEdit = (post) => {
        setEditingPost(post);
        setOpenDialog(true);
    };

    const handleUpdate = async () => {
        if (editingPost.title.length == 0 || editingPost.content.length == 0) {
            alert(t('blog.form_empty_error'))
            return;
        }
        try {
            await fetch(Constants.APP_API_BASE_URL + `/blog/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: editingPost.id,
                    title: editingPost.title,
                    content: editingPost.content,
                }),
            });

            setOpenDialog(false);
            setEditingPost(null);
            fetchPosts();
        } catch (error) {
            console.error('Error updating post:', error);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const file = e.dataTransfer.files[0];
        if (file && file.type.startsWith("image/")) {
            setImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleImageChange = (e) => {
        debugger
        const file = e.target.files[0];
        if (file && file.type.startsWith("image/")) {
            setImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleBoxClick = () => {
        // Open the file dialog when the drag-and-drop box is clicked  
        fileInputRef.current.click();
    };

    const handleEditorChange = (value) => {
        console.log('Editor Content:', value);
        setContent(value); // Save the editor's content to state  
    };

    return (


        <Grid container spacing={2}>
            <Container id="AdminPanel">
                {editable &&
                    <Paper sx={{ p: 2, mb: 2 }} style={{ width: '100%' }}>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                label="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                margin="normal"
                            />
                            {/* Drag and Drop Box */}
                            <Box
                                sx={{
                                    border: "2px dashed #ccc",
                                    borderRadius: "5px",
                                    padding: 2,
                                    textAlign: "center",
                                    color: "#aaa",
                                    cursor: "pointer",
                                    "&:hover": {
                                        backgroundColor: "#f8f8f8",
                                    },
                                    mt: 2,
                                    mb: 2,
                                    height: 'auto'
                                }}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                onClick={handleBoxClick} // Open file dialog on click  
                            >
                                {!imagePreview ? (
                                    <div>{t('blog.blog_image_drag_drop')}</div>
                                ) : (
                                    <img
                                        src={imagePreview}
                                        alt="Preview"
                                        style={{ width: "100%", height: "auto" }}

                                    />
                                )}
                            </Box>

                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{ display: "none" }}
                                id="image-upload"
                                ref={fileInputRef}
                            />
                            <div style={{ border: '1px solid lightgray', borderRadius: 10, padding: 10 }}>
                                <Editor
                                    apiKey='29qin4r61rbyceyv7dfxsji2ftiii4dbppcqyc8o2vkik58i'
                                    init={{
                                        language: 'fr',
                                        plugins: [
                                            // Core editing features
                                            'autolink', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
                                            // Your account includes a free trial of TinyMCE premium features
                                            // Try the most popular premium features until Mar 21, 2025:
                                            'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'ai', 'mentions', 'tinycomments', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown', 'importword', 'exportword', 'exportpdf'
                                        ],
                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                        tinycomments_mode: 'embedded',
                                        tinycomments_author: 'Loc Tran',
                                        mergetags_list: [
                                            { value: 'First.Name', title: 'First Name' },
                                            { value: 'Email', title: 'Email' },
                                        ],
                                        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),
                                    }}
                                    initialValue=""
                                    value={content}
                                    onEditorChange={handleEditorChange}
                                />
                            </div>
                            <Button variant="contained" type="submit" sx={{ mt: 2 }}>
                                {t('blog.btn_post')}
                            </Button>
                        </form>
                    </Paper>
                }

                <Grid container spacing={2}>
                    {posts.map((post) => (
                        <Grid item xs={12} md={12} key={post.id}>
                            <Card>
                                <CardContent style={{ textAlign: 'left' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>{post.title}</div>
                                    <div style={{ marginBottom: 20 }}><small>{t('blog.blog_posted_date')}: {dateFormat(post.created_at, ' HH:MM dd-mm-yyyy')}</small></div>
                                    {post.image_url && (
                                        <CardMedia
                                            component="img"
                                            image={post.image_url}
                                            alt={post.title}
                                        />
                                    )}
                                    <div style={{ marginTop: 20 }} dangerouslySetInnerHTML={{ __html: post.content }}></div>
                                    {editable &&
                                        <div>
                                            <Button variant="contained" type="button" sx={{ mt: 2, mr: 1 }} onClick={() => handleEdit(post)}>
                                                {t('blog.btn_edit')}
                                            </Button>
                                            <Button variant="contained" type="button" sx={{ mt: 2 }} onClick={() => handleDelete(post.id)}>
                                                {t('blog.btn_delete')}
                                            </Button>
                                        </div>
                                    }

                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle>Edit Post</DialogTitle>
                    <DialogContent>
                        {editingPost && (
                            <>
                                <TextField
                                    fullWidth
                                    label="Title"
                                    value={editingPost.title}
                                    onChange={(e) => setEditingPost({ ...editingPost, title: e.target.value })}
                                    margin="normal"
                                />

                                <Editor
                                    apiKey='29qin4r61rbyceyv7dfxsji2ftiii4dbppcqyc8o2vkik58i'
                                    init={{
                                        language: 'fr',
                                        plugins: [
                                            // Core editing features
                                            'autolink', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
                                            // Your account includes a free trial of TinyMCE premium features
                                            // Try the most popular premium features until Mar 21, 2025:
                                            'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'ai', 'mentions', 'tinycomments', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown', 'importword', 'exportword', 'exportpdf'
                                        ],
                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                        tinycomments_mode: 'embedded',
                                        tinycomments_author: 'Loc Tran',
                                        mergetags_list: [
                                            { value: 'First.Name', title: 'First Name' },
                                            { value: 'Email', title: 'Email' },
                                        ],
                                        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),
                                    }}
                                    value={editingPost.content}
                                    onEditorChange={(e) => setEditingPost({ ...editingPost, content: e })}
                                />

                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                        <Button onClick={handleUpdate}>Update</Button>
                    </DialogActions>
                </Dialog>
            </Container >
        </Grid >
    );
};

export default BlogComponent;
