import PropTypes from 'prop-types';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Alert, Tabs, Tab, Chip } from '@mui/material';
import { Send, TableDocument } from 'iconsax-react';
import NewButtonComponent from 'components/NewButton';
// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import PoliciesService from '../../../services/PoliciesService';

// third-party
import { DndProvider } from 'react-dnd';
import { isMobile } from 'react-device-detect';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedMinMaxValues,
  getFacetedUniqueValues,
  getPaginationRowModel,
  getSortedRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  flexRender,
  useReactTable,
  sortingFns,
} from '@tanstack/react-table';
import { compareItems, rankItem } from '@tanstack/match-sorter-utils';

// project import
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import IconButton from 'components/@extended/IconButton';
import ExpandingPolicyDetail from 'sections/tables/react-table/ExpandingPolicyDetail';
import UpgradePolicyForm from 'components/frontOffice/UpgradePolicyForm';

import {
  DebouncedInput,
  EmptyTable,
  Filter,
  HeaderSort,
  RowSelection,
  TablePagination,
  RowEditable,
  DraggableColumnHeader,
} from 'components/third-party/react-table';

//assets
import { ArrowDown2, ArrowRight2, CloseCircle, Command, Edit2 } from 'iconsax-react';
import { useTranslation } from 'react-i18next';

export const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta(itemRank);
  return itemRank.passed;
};

export const fuzzySort = (rowA, rowB, columnId) => {
  let dir = 0;
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(rowA.columnFiltersMeta[columnId], rowB.columnFiltersMeta[columnId]);
  }
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

// ==============================|| REACT TABLE - EDIT ACTION ||============================== //

function EditAction({ row, table }) {
  const meta = table?.options?.meta;
  const setSelectedRow = (e) => {
    meta?.setSelectedRow((old) => ({
      ...old,
      [row.id]: !old[row.id],
    }));
    meta?.revertData(row.index, e?.currentTarget.name === 'cancel');
  };

  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {meta?.selectedRow[row.id] && (
        <Tooltip title={t('policies.cancel')}>
          <IconButton color="error" name="cancel" onClick={setSelectedRow}>
            <CloseCircle size="15" variant="Outline" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={meta?.selectedRow[row.id] ? t('policies.save') : t('policies.edit')}>
        <IconButton color={meta?.selectedRow[row.id] ? 'success' : 'primary'} onClick={setSelectedRow}>
          {meta?.selectedRow[row.id] ? <Send size="15" variant="Bold" /> : <Edit2 variant="Outline" />}
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ defaultColumns, data, onRefresh, onFiltered, fullData }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [grouping, setGrouping] = useState([]);
  const [columns] = useState(() => [...defaultColumns]);
  const [columnOrder, setColumnOrder] = useState(columns.map((column) => column.id));
  const [columnVisibility, setColumnVisibility] = useState({});
  const [selectedPolicy, setSelectedPolicy] = useState(null);

  const isHavingAwaitingPayment = useMemo(() => data.some((policy) => policy.status_id === 'AWAITING_PAYMENT'), [data]);

  const table = useReactTable({
    data,
    columns,
    defaultColumn: { cell: RowEditable },
    state: {
      rowSelection,
      columnFilters,
      globalFilter,
      sorting,
      grouping,
      columnOrder,
      columnVisibility,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onGroupingChange: setGrouping,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    getRowCanExpand: () => true,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    globalFilterFn: fuzzyFilter,
    getRowId: (row) => row.id.toString(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  });

  useEffect(() => setColumnVisibility({ id: false, role: false, contact: false, country: false, progress: false }), []);

  const backColor = alpha(theme.palette.primary.lighter, 0.1);

  let headers = [];
  table.getVisibleLeafColumns().map(
    (columns) =>
      columns.columnDef.accessorKey &&
      headers.push({
        label: typeof columns.columnDef.header === 'string' ? columns.columnDef.header : '#',
        key: columns.columnDef.accessorKey,
      })
  );

  const tabs = [
    { label: t('policies.tabs.all_policies'), status_ids: [] },
    { label: 'Actif', status_ids: ['ACTIVATED'] },
    { label: 'Expiré', status_ids: ['EXPIRED'] },
    { label: t('policies.tabs.awaiting_payment'), status_ids: ['AWAITING_PAYMENT'] },
    { label: 'Résilié', status_ids: ['CANCELLED'] },
  ];

  const handleTabChange = (event, tabIndex) => {
    setCurrentTabIndex(tabIndex);
    onFiltered(tabs[tabIndex].status_ids);
  };

  const countStatus = (status_ids) => {
    if (status_ids.length === 0) return fullData.length;
    return fullData.filter((item) => status_ids.includes(item.status_id)).length;
  };

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  return (
    <MainCard content={false}>
      {isHavingAwaitingPayment && (
        <Alert severity="error" sx={{ marginBottom: 1, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
          {t('policies.awaitingPaymentAlert')}
        </Alert>
      )}
      <Tabs value={currentTabIndex} onChange={handleTabChange} aria-label="policy tabs">
        {tabs.map((tab, index) => (
          <Tab
            label={tab.label}
            icon={<Chip label={countStatus(tab.status_ids)} color="primary" variant="light" />}
            style={{ textTransform: 'none' }}
            iconPosition="end"
          />
        ))}
      </Tabs>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        justifyContent="space-between"
        sx={{
          padding: 2,
          ...(matchDownSM && { '& .MuiOutlinedInput-root, & .MuiFormControl-root': { width: '100%' } }),
        }}
      >
        <DebouncedInput
          value={globalFilter ?? ''}
          onFilterChange={(value) => setGlobalFilter(String(value))}
          placeholder={t('policies.search_policies')}
        />
        <Stack direction="row" spacing={2} alignItems="center" sx={{ width: { xs: '100%', sm: 'auto' } }}></Stack>
      </Stack>

      <ScrollX>
        <RowSelection selected={Object.keys(rowSelection).length} />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    if (header.column.columnDef.meta !== undefined && header.column.getCanSort()) {
                      Object.assign(header.column.columnDef.meta, {
                        className: header.column.columnDef.meta.className + ' cursor-pointer prevent-select',
                      });
                    }

                    return (
                      <DraggableColumnHeader key={header.id} header={header} table={table}>
                        <>
                          {header.isPlaceholder ? null : (
                            <Stack direction="row" spacing={1} alignItems="center">
                              {header.column.getCanGroup() && (
                                <IconButton
                                  color={header.column.getIsGrouped() ? 'error' : 'primary'}
                                  onClick={header.column.getToggleGroupingHandler()}
                                  size="small"
                                  sx={{ p: 0, width: 24, height: 24, fontSize: '1rem', mr: 0.75 }}
                                >
                                  {header.column.getIsGrouped() ? (
                                    <Command size="32" color="#FF8A65" variant="Bold" />
                                  ) : (
                                    <TableDocument size="32" variant="Outline" />
                                  )}
                                </IconButton>
                              )}
                              <Box>{flexRender(header.column.columnDef.header, header.getContext())}</Box>
                              {header.column.getCanSort() && <HeaderSort column={header.column} sort />}
                            </Stack>
                          )}
                        </>
                      </DraggableColumnHeader>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell key={header.id} {...header.column.columnDef.meta}>
                      {header.column.getCanFilter() && <Filter column={header.column} table={table} />}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.length > 0 ? (
                table
                  .getRowModel()
                  .rows.sort((a, b) => {
                    const dateA = new Date(a.original.payment_date);
                    const dateB = new Date(b.original.payment_date);
                    return dateB - dateA;
                  })
                  .map((row) => (
                    <Fragment key={row.id}>
                      <TableRow row={row} reorderRow={() => {}}>
                        <>
                          {row.getVisibleCells().map((cell) => {
                            let bgcolor = 'background.paper';
                            if (cell.getIsGrouped()) bgcolor = 'primary.lighter';
                            if (cell.getIsAggregated()) bgcolor = 'warning.lighter';
                            if (cell.getIsPlaceholder()) bgcolor = 'error.lighter';

                            if (cell.column.columnDef.meta !== undefined && cell.column.getCanSort()) {
                              Object.assign(cell.column.columnDef.meta, {
                                style: { backgroundColor: bgcolor },
                              });
                            }

                            return (
                              <TableCell
                                key={cell.id}
                                {...cell.column.columnDef.meta}
                                sx={{ bgcolor }}
                                {...(cell.getIsGrouped() &&
                                  cell.column.columnDef.meta === undefined && {
                                    style: { backgroundColor: bgcolor },
                                  })}
                              >
                                {cell.getIsGrouped() ? (
                                  <Stack direction="row" alignItems="center" spacing={0.5}>
                                    <IconButton
                                      color="secondary"
                                      onClick={row.getToggleExpandedHandler()}
                                      size="small"
                                      sx={{ p: 0, width: 24, height: 24 }}
                                    >
                                      {row.getIsExpanded() ? (
                                        <ArrowDown2 size="32" variant="Outline" />
                                      ) : (
                                        <ArrowRight2 size="32" variant="Outline" />
                                      )}
                                    </IconButton>
                                    <Box>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Box>{' '}
                                    <Box>({row.subRows.length})</Box>
                                  </Stack>
                                ) : cell.getIsAggregated() ? (
                                  flexRender(
                                    cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
                                    cell.getContext()
                                  )
                                ) : cell.getIsPlaceholder() ? null : (
                                  flexRender(cell.column.columnDef.cell, cell.getContext())
                                )}
                              </TableCell>
                            );
                          })}
                        </>
                      </TableRow>
                      {row.getIsExpanded() && !row.getIsGrouped() && (
                        <TableRow
                          onClick={() => setSelectedPolicy(row.original)}
                          sx={{ bgcolor: backColor, '&:hover': { bgcolor: `${backColor} !important` } }}
                        >
                          <TableCell colSpan={row.getVisibleCells().length + 2}>
                            <ExpandingPolicyDetail data={selectedPolicy || row.original} />
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={table.getAllColumns().length}>
                    <EmptyTable msg={t('policies.no_data')} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              {table.getFooterGroups().map((footerGroup) => (
                <TableRow key={footerGroup.id}>
                  {footerGroup.headers.map((footer) => (
                    <TableCell key={footer.id} {...footer.column.columnDef.meta}>
                      {footer.isPlaceholder ? null : flexRender(footer.column.columnDef.header, footer.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableFooter>
          </Table>
        </TableContainer>
        <Divider />
        <Box sx={{ p: 2 }}>
          <TablePagination
            {...{
              setPageSize: table.setPageSize,
              setPageIndex: table.setPageIndex,
              getState: table.getState,
              getPageCount: table.getPageCount,
            }}
          />
        </Box>
      </ScrollX>
    </MainCard>
  );
}

// ==============================|| REACT TABLE - UMBRELLA ||============================== //

export default function MyPoliciesPage() {
  const [openModal, setOpenModal] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const theme = useTheme();

  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        id: 'expander',
        enableGrouping: false,
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <IconButton
              color={row.getIsExpanded() ? 'primary' : 'secondary'}
              onClick={row.getToggleExpandedHandler()}
              size="small"
            >
              {row.getIsExpanded() ? (
                <ArrowDown2 size="32" variant="Outline" />
              ) : (
                <ArrowRight2 size="32" variant="Outline" />
              )}
            </IconButton>
          ) : (
            <IconButton color="secondary" size="small" disabled>
              <CloseCircle />
            </IconButton>
          );
        },
      },
      {
        id: 'id',
        title: t('policies.id'),
        header: '#',
        accessorKey: 'id',
        dataType: 'text',
        enableColumnFilter: false,
        enableGrouping: false,
        meta: { className: 'cell-center' },
      },
      {
        id: 'amendment_number',
        header: t('policies.policy_number'),
        footer: t('policies.policy_number'),
        accessorKey: 'amendment_number',
        dataType: 'code',
        enableGrouping: false,
      },
      {
        id: 'payment_date',
        header: 'Date de début',
        footer: 'Date de début',
        accessorKey: 'payment_date',
        dataType: 'datetime',
        enableGrouping: false,
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return '';
          const date = new Date(value);
          return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} - ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        },
      },
      {
        id: 'expiration_date',
        header: t('policies.end_date'),
        footer: t('policies.end_date'),
        accessorKey: 'expiration_date',
        dataType: 'datetime',
        enableGrouping: false,
        cell: ({ row, getValue }) => {
          const expirationDate = getValue();
          const paymentDate = row.original.payment_date;

          const formatDate = (date) => {
            return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} - ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
          };

          if (expirationDate) {
            return formatDate(new Date(expirationDate));
          }

          if (paymentDate) {
            const calculatedExpiration = new Date(paymentDate);
            calculatedExpiration.setFullYear(calculatedExpiration.getFullYear() + 1);
            return formatDate(calculatedExpiration);
          }

          return '';
        },
      },
      {
        id: 'status_id',
        header: t('policies.policy_status'),
        footer: t('policies.policy_status'),
        accessorKey: 'status_id',
        dataType: 'select',
        enableGrouping: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          const status = row.original.status_id;

          let statusText = '';
          let chipColor = '';

          switch (status) {
            case 'ACTIVATED':
              statusText = 'Actif';
              chipColor = 'success';
              break;
            case 'EXPIRED':
              statusText = 'Clos';
              chipColor = 'info';
              break;
            case 'AWAITING_PAYMENT':
              statusText = 'En attente de paiement';
              chipColor = 'error';
              break;
            case 'CANCELLED':
              statusText = 'Résilié';
              chipColor = 'error';
              break;
            default:
              statusText = '';
              chipColor = 'default';
              break;
          }

          return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
              <Chip label={statusText} color={chipColor} variant="light" />
            </Box>
          );
        },
      },
      {
        id: 'action',
        header: t('policies.action'),
        cell: ({ row }) => {
          const statusId = row.original.status_id;

          const buttonConfig = {
            ACTIVATED: {
              label: t('policies.buttons.improvePolicy'),
              onClick: () => handleUpgradePolicy(row.original),
            },
            AWAITING_PAYMENT: {
              label: t('policies.buttons.pay'),
              onClick: () => handlePayer(row.original),
            },
          };

          const { label, onClick } = buttonConfig[statusId] || {};

          return (
            <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
              {label && <NewButtonComponent label={label} onClick={onClick} size="small" />}
            </Box>
          );
        },
        enableSorting: false,
        enableColumnFilter: false,
        enableGrouping: false,
        meta: { className: 'cell-center' },
      },
    ],
    [t]
  );

  const handleUpgradePolicy = (policy) => {
    console.log('Upgrading policy:', policy);
    setSelectedPolicy(policy);
    setOpenModal(true);
  };

  const handlePayer = (policy) => {
    console.log('Paying for policy:', policy);
  };

  const [policiesData, setPoliciesData] = useState([]);
  const [policiesDataFull, setPoliciesDataFull] = useState([]);

  useEffect(() => {
    getPolicyListData();
  }, []);

  function getPolicyListData() {
    PoliciesService.getMyPoliciesList()
      .then((response) => {
        if (response) {
          setPoliciesData(response);
          setPoliciesDataFull(response);
        }
      })
      .catch((err) => {
        console.log('policies list error:', err);
      });
  }

  const onRefresh = () => {
    getPolicyListData();
  };

  const onFiltered = (status_ids) => {
    if (status_ids.length === 0) setPoliciesData(policiesDataFull);
    else {
      setPoliciesData(policiesDataFull.filter((policy) => status_ids.includes(policy.status_id)));
    }
  };

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <ReactTable
        {...{ data: policiesData, defaultColumns: columns, onRefresh, onFiltered, fullData: policiesDataFull }}
      />
      <UpgradePolicyForm currentPolicy={selectedPolicy} openModal={openModal} setOpenModal={setOpenModal} />
    </DndProvider>
  );
}

EditAction.propTypes = { row: PropTypes.object, table: PropTypes.object };

ReactTable.propTypes = { defaultColumns: PropTypes.array, data: PropTypes.array, onRefresh: PropTypes.func };
