import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useCookies } from 'react-cookie';
import ButtonComponent from 'components/ButtonComponent';
import { useTranslation } from 'react-i18next';
import CookieImage from '../assets/cookies.png';

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(['cookie_consent']);
  const { t } = useTranslation();

  const isMobile = useMediaQuery('(max-width:600px)');

  const giveCookieConsent = (action) => {
    setCookie('cookie_consent', action, { path: '/' });
  };

  if (cookies.cookie_consent) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 10,
        left: '50%' ,
        transform: 'translateX(-50%)' ,
        width: isMobile ? '90%' : '80%', 
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',  
        alignItems: 'center',
        gap: '15px',
        zIndex: 9999,
      }}
    >
      {!isMobile && (
        <img
          src={CookieImage}
          alt="Cookie"
          style={{ width: '100px', height: '100px' }}
        />
      )}

      <Box
        sx={{
          backgroundColor: '#fff',
          padding: isMobile ? '15px' : '20px 23px', 
          borderRadius: '10px',
          position: 'relative',
          flex: 1,
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',  
          alignItems: 'center',  
          textAlign: isMobile ? "center": 'left', 
          boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
          background:" rgba(255, 255, 255, 0.90);"
        }}
      >
        <Box sx={{ flex: 1, marginBottom: isMobile ? '10px' : '0' }}>
          <Typography 
            variant="h5" 
            sx={{ 
              fontWeight: 'bold', 
              color: '#333', 
              marginBottom: '5px', 
              fontSize: isMobile ? '16px' : '20px'
            }}
          >
            {t('cookieConsent.title')} 
            <span style={{ fontSize:"15px", marginLeft:"10px"}}>
            <a href='/data-policy'>Consultez notre politique de confidentialité</a>
            </span>
          </Typography>
          <Typography 
            sx={{ 
              color: '#555', 
              fontSize: isMobile ? '13px' : '15px' 
            }}
          >
            {t('cookieConsent.message')}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: '10px', flexShrink: 0 , marginLeft:"10px"}}>
          <ButtonComponent
            label={t('cookieConsent.accept')}
            width="100px"
            height="30px"
            backgroundColor="#F3AD61"
            color="white"
            border="none"
            onClick={() => giveCookieConsent('accept')}
            borderRadius="50px"
            fontSize={isMobile ? '12px' : '14px'}
          />
          <ButtonComponent
            label={t('cookieConsent.refuse')}
            width="100px"
            height="30px"
            backgroundColor="white"
            color="#F3AD61"
            border="2px solid #F3AD61"
            onClick={() => giveCookieConsent('refuse')}
            borderRadius="50px"
            fontSize={isMobile ? '12px' : '14px'} 
            sx={{
              cursor:"pointer",
            }
            }
          />
        </Box>

        {!isMobile && (
          <Box
            sx={{
              position: 'absolute',
              left: '-10px',
              top: '50%',
              width: 0,
              height: 0,
              borderTop: '10px solid transparent',
              borderBottom: '10px solid transparent',
              borderRight: '10px solid white',
              transform: 'translateY(-50%)',
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default CookieConsent;
