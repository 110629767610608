import React, {useState, useEffect} from "react";
import InputComponent from "../../../../components/InputComponent";
import {useTranslation} from "react-i18next";
import validator from "email-validator";
import userAPI from "services/AvektoiAPIClient";
import api from 'services/AvektoiAPIClient';
import {Grid, Button, Typography, Link} from "@mui/material";
import Constants from "common/Constants";
import {Stack} from "@mui/system";
import {useNavigate} from 'react-router-dom';
import {useSnackbar} from "notistack";
import Loader from "components/Loader";

const EmailStep = ({nextStep, prevStep}) => {
    const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));
    const {t} = useTranslation();
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [selection, setSelection] = useState(clientObject.client_type);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    useEffect(() => {
        let clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));
        setSelection(clientObject.client_type);

        let localClient = JSON.parse(localStorage.getItem(Constants.CLIENT_OBJECT));
        if (clientObject?.email && localClient?.verifiedPassword) {
            nextStep({...clientObject});
            return;
        }
        clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) || {};
        if (clientObject.email) {
            setEmail(clientObject.email);
        }
    }, [nextStep]);

    useEffect(() => {
        if (error) {
            setError('');
        }
    }, [email]);

    const handleNextClick = async () => {
        const emailValue = email.toLowerCase()

        if (validator.validate(emailValue)) {
            if (password !== confirmPassword) {
                setError("passwords_do_not_match");
                return;
            }
            setError("");
            setIsLoading(true);
            try {
                const response = await userAPI.checkEmail(emailValue);
                if (response === true) {
                    setError("already_used");
                    setIsLoading(false);
                    return;
                }

                const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) || {};

                // Save email and password in sessionStorage
                sessionStorage.setItem(
                    Constants.CLIENT_OBJECT,
                    JSON.stringify({ ...clientObject, email: emailValue, password })
                );

                const newClientObject = {
                    first_name: clientObject.first_name,
                    last_name: clientObject.last_name,
                    phone_number: clientObject.phone_number,
                    email: emailValue,
                    client_type: clientObject.client_type,
                    country_code: clientObject.country_code,
                    date_of_birth: clientObject.date_of_birth,
                    password: password // Ensure password is included here
                };

                console.log("New Client Object before sending to backend:", newClientObject);

                const createClientResponse = await api.createClient(newClientObject);
                console.log("Backend response for createClient:", createClientResponse);

                setIsLoading(false);

                if (createClientResponse.id != null) {
                    localStorage.setItem(Constants.ACCESS_AUTH_TOKEN, createClientResponse.login_token);
                    sessionStorage.setItem(Constants.ACCESS_AUTH_TOKEN, createClientResponse.login_token);
                    localStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify({ ...createClientResponse, ...clientObject }));
                    sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify({
                        ...clientObject,
                        id: createClientResponse.id,
                        email: emailValue,
                        password, // Ensure password is correctly stored
                        verifiedPassword: 'pending'
                    }));

                    nextStep({ email: emailValue });
                } else {
                    setIsLoading(false);
                    setError('creation_failed');
                }
            } catch (ex) {
                const errorMessage = ex?.message || "An unexpected error occurred. Please try again.";
                enqueueSnackbar(errorMessage, { variant: 'default' });
                console.error("Error during handleNextClick:", ex);
                setIsLoading(false);
            }
        } else {
            setError("invalid");
        }
    };

    return (
        <>
            {isLoading && <Loader/>}
            {/*<TypeSwitcher selection={selection} toggleSelection={toggleSelection} homePage={true}/>*/}

            <Typography variant="h2" style={{ marginTop: 20 }}>{t("signup.step4.title1")}</Typography>
            <Typography variant="h2">{t("signup.step4.title2")}</Typography>
            <div style={{marginTop: 30}}>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{maxWidth: 350}}>
                            <InputComponent
                                label={t("signup.step4.email")}
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={error === "already_used" || error === "invalid"}
                            />
                        </div>
                        {error === 'already_used' && (
                            <Typography variant="body2" color="error" marginTop="-10px" marginBottom="10px">
                                {t('signup.step4.errorMessage')}
                                <Link component="button" variant="body2" marginTop="-10px" marginBottom="10px"
                                      sx={{textDecoration: "underline"}} onClick={() => navigate('/login')}>
                                    {t('signup.step4.linkLogin')}
                                </Link>
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ maxWidth: 350 }}>
                            <InputComponent
                                label={t("signup.step4.password")}
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                error={error === "passwords_do_not_match"}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div style={{ maxWidth: 350 }}>
                            <InputComponent
                                label={t("signup.step4.confirmPassword")}
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => {setConfirmPassword(e.target.value)}}
                                error={error === "passwords_do_not_match"}
                            />
                        </div>
                        {error === 'passwords_do_not_match' && (
                            <Typography variant="body2" color="error" marginTop="-10px" marginBottom="10px">
                                {t('signup.step4.passwordMismatchMessage')}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Stack direction="row" alignItems="center" justifyContent="center" columnGap={2}>
                  <Button size="large"
                          disabled={!email || !password || !confirmPassword || error === "already_used" || isLoading}
                          variant="contained"
                          color="primary"
                          style={{ width: 250 }}
                          onClick={handleNextClick}>
                    {t("signup.step4.sendMail")}
                  </Button>
                </Stack>
            </div>
        </>
    );
};

export default EmailStep;
