import React from 'react';
import SignupLayout from 'layouts/frontOffice/StyledSignupLayout';
import { SnackbarProvider } from 'notistack';
import MultiFileUpload from 'components/third-party/dropzone/MultiFile';
import { useState } from 'react';
import { uploadDocuments } from 'services/DocumentsService';
import { Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Constants from 'common/Constants';
import Swal from 'sweetalert2';

const Divider = styled('div')(({ theme }) => ({
  margin: theme.spacing(5, 0),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  borderStyle: 'dashed',
  borderWidth: 1,
  borderTop: 'none',
  borderColor: theme.palette.secondary.main,
  width: '95%',
}));

const UploadClientDocuments = () => {
  const { t } = useTranslation();
  const client = JSON.parse(localStorage.getItem(Constants.CLIENT_OBJECT));

  const [proofOfResidenceFiles, setProofOfResidenceFiles] = useState([]);
  const [idCardFiles, setIdCardFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveFile = (fileName, setFiles) => {
    setFiles((prev) => prev.filter((file) => file.name !== fileName));
  };

  const handleAddFiles = (newFiles, setFiles) => {
    setFiles((prev) => [...prev, ...newFiles]);
  };

  const handleUploadFiles = async () => {
    try {
      if (proofOfResidenceFiles.length === 0 || idCardFiles.length === 0 || !client || !client.id) {
        return;
      }

      setIsLoading(true);

      const uploadPORResponse = await uploadDocuments(proofOfResidenceFiles, {
        client_id: client.id,
        type: 'proof_of_residence',
      });
      const uploadIDCardResponse = await uploadDocuments(idCardFiles, { client_id: client.id, type: 'id_card' });

      const uploadedPORFileRecords = uploadPORResponse.data.records;
      const uploadedIDCardFileRecords = uploadIDCardResponse.data.records;

      if (uploadedPORFileRecords.length && uploadedIDCardFileRecords.length) {
        Swal.fire({
          title: t('signup.uploadDocuments.success'),
          icon: 'success',
          timer: 3000,
        });

        setProofOfResidenceFiles([]);
        setIdCardFiles([]);
      }
    } catch (error) {
      Swal.fire({
        title: t('signup.uploadDocuments.error'),
        icon: 'error',
        timer: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SnackbarProvider maxSnack={3}>
      <SignupLayout>
        <Typography variant="h2" gutterBottom sx={{ marginBottom: 4 }}>
          {t('signup.uploadDocuments.title')}
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ marginBottom: 2 }}>
          {t('signup.uploadDocuments.proofOfResidence')}
        </Typography>
        <MultiFileUpload
          onRemoveFile={(fileName) => handleRemoveFile(fileName, setProofOfResidenceFiles)}
          onAddFile={(newFiles) => handleAddFiles(newFiles, setProofOfResidenceFiles)}
          maxFiles={2}
        />

        <Divider />

        <Typography variant="h4" gutterBottom sx={{ marginBottom: 2 }}>
          {t('signup.uploadDocuments.idCard')}
        </Typography>
        <MultiFileUpload
          onRemoveFile={(fileName) => handleRemoveFile(fileName, setIdCardFiles)}
          onAddFile={(newFiles) => handleAddFiles(newFiles, setIdCardFiles)}
          maxFiles={2}
        />

        <Stack direction="row" alignItems="center" justifyContent="center" columnGap={2}>
          <Button
            size="large"
            disabled={proofOfResidenceFiles.length === 0 || idCardFiles.length === 0 || isLoading}
            variant="contained"
            color="primary"
            style={{ width: 250, marginTop: 20 }}
            onClick={handleUploadFiles}
          >
            {isLoading ? t('signup.uploadDocuments.loading') : t('buttons.next')}
          </Button>
        </Stack>
      </SignupLayout>
    </SnackbarProvider>
  );
};

export default UploadClientDocuments;
