import { useState } from 'react';
import { Button } from '@mui/material';

const NewButton= ({ label, onClick, cancel, size, marginTop, color , disabled, startIcon, type, component,to}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <Button
      variant="contained"
      component={component}
      to={to}
      color="primary"
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      size={size ? size : "none"}
      type={type}
      style={{
        backgroundColor: cancel ? '#d32f2f' : isHovered ? '#D71683' : 'white',
        color: cancel ? 'white' : isHovered ? 'white' : 'grey',
        border: cancel ? '2px solid #d32f2f' : isHovered ? '2px solid #D71683' : '2px solid grey',
        borderRadius: '50px',
        fontSize: 12,
        fontWeight: 'bolder',
        letterSpacing: '0.5px',
        textTransform: 'none',
        marginTop: marginTop ? marginTop : "none",
        transition: 'all 0.3s ease, transform 0.3s ease', 
        transform: isHovered ? 'scale(1.01)' : 'scale(1)', 
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {label}
    </Button>
  );
};

export default NewButton;
