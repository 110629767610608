import React, { useEffect, useRef, useState } from 'react';
import { styled, Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import HomePageLayout from 'layouts/frontOffice/HomePageLayout';
import { Stack, useMediaQuery, Grid, CardMedia, Card, CardContent, Paper, TextField, IconButton, Button, DialogActions, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Constants from 'common/Constants';
import { Editor } from '@tinymce/tinymce-react';
import dateFormat, { masks } from "dateformat";
const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const BlogDetailComponent = ({ editable }) => {
    const { t } = useTranslation();
    const [post, setPost] = useState(null);
    useEffect(() => {
        fetchPost();
    }, []);

    const fetchPost = async () => {
        try {
            const response = await fetch(Constants.APP_API_BASE_URL + '/blog/a42aa8d6-49c2-4634-a9f8-6b42f3df2055');
            const data = await response.json();
            setPost(data);
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    const handleBack = () => {
        window.location.href = "/blog"
    }


    return (
        <Grid container spacing={2}>
            <Container id="AdminPanel">
                {post && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} key={post.id}>

                            <Card>
                                <CardContent style={{ textAlign: 'left' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>{post.title}</div>
                                    <div style={{ marginBottom: 20 }}><small>{t('blog.blog_posted_date')}: {dateFormat(post.created_at, ' HH:MM dd-mm-yyyy')}</small></div>
                                    {post.image_url && (
                                        <CardMedia
                                            component="img"
                                            image={post.image_url}
                                            alt={post.title}
                                        />
                                    )}
                                    <div style={{ marginTop: 20 }} dangerouslySetInnerHTML={{ __html: post.content }}></div>
                                    <Button onClick={handleBack} variant="contained" type="submit" sx={{ mt: 2 }}>
                                        {t('blog.back')}
                                    </Button>
                                </CardContent>
                            </Card>

                        </Grid>
                    </Grid>
                )}
            </Container >
        </Grid >
    );
};

export default BlogDetailComponent;
