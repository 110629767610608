import serviceLinks from 'common/Links.json';
import axios from 'utils/axios';

class PaymentMethodService {
  static async createSetupIntent() {
    const url = serviceLinks['PaymentMethods']['create-setup-intent'].url;

    return await axios.post(
      url,
      {},
      { headers: { 'Content-Type': 'application/json', business_partner_id: 'a5f5c9f4-d1d8-4c6d-9cde-9e8e38dbb6bb' } }
    );
  }

  static async getPaymentMethods() {
    const url = serviceLinks['PaymentMethods']['get'].url;

    return await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        business_partner_id: 'a5f5c9f4-d1d8-4c6d-9cde-9e8e38dbb6bb', // TODO: should get this one from API
      },
    });
  }

  static async deletePaymentMethod(paymentMethodId) {
    const url = serviceLinks['PaymentMethods']['delete'].url.replace('{paymentMethodId}', paymentMethodId);

    return await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        business_partner_id: 'a5f5c9f4-d1d8-4c6d-9cde-9e8e38dbb6bb', // TODO: should get this one from API
      },
    });
  }
}

export default PaymentMethodService;
