import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// material-ui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// project-imports
import { APP_DEFAULT_PATH } from 'config';

// assets
import imgsoon from 'assets/images/maintenance/img-soon-2.svg';
import React, { useEffect } from 'react';
import axiosServices from 'utils/axios';
import { useWindowSize } from 'react-use'
import Confetti from 'react-confetti'

export default function PolicyRegisterSuccess() {
  const { width, height } = useWindowSize()
  const { t } = useTranslation();
  const { id } = useParams()
  const [policy, setPolicy] = React.useState(null);

  useEffect(() => {
    axiosServices.get(`/quotes/get-by-id/${id}`).then((response) => {
      setPolicy(response.data);
    }).catch((error) => {
      console.error(error);
    })

    axiosServices.post(`/docusign/send-for-signature/`, { quotation_id: id }).then((response) => {
    }).catch((error) => {
      console.error(error);
    })
  }, []);

  if (!policy) {
    return (<div>Loading...</div>)
  }
  if (policy)
    return (
      <Grid
        container
        spacing={10}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', pt: 2, pb: 1, overflow: 'hidden' }}
      >
        <Confetti
          width={width}
          height={height}
        />
        <Grid item xs={12}>
          <Stack direction="row">
            <Grid item>
              <Box sx={{ width: { xs: 250, sm: 590 }, height: { xs: 130, sm: 300 } }}>
                <img src={imgsoon} alt={t('error404.alt_text')} style={{ width: '100%', height: '100%' }} />
              </Box>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography variant="h1">{t('policy_register_success.title')}</Typography>
            <Typography variant='h4' align="center">
              {t('policy_register_success.contract_number')}: {policy.amendment_number}
            </Typography>
            <Typography variant='h5' align="center">
              {policy.client.first_name} {policy.client.last_name} ({policy.client.client_type})<br />
              {policy.client.email}<br />
              {policy.client.address_line_1} {policy.client.address_line_2}
            </Typography>
            <Typography color="text.secondary" align="center">
              {t('policy_register_success.message').replace('{email}', policy.client.email)}
            </Typography>
            <Link to="/my-insurances">
              <Button component={Link} to={APP_DEFAULT_PATH} variant="contained">
                {t('policy_register_success.go_to_dashboard')}
              </Button>
            </Link>
          </Stack>
        </Grid>
      </Grid>
    );
}
