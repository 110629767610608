import React, { useState, useEffect } from "react";
import { Stack, styled } from "@mui/system";
import InputComponent from "../../../../components/InputComponent";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography, Autocomplete, TextField } from "@mui/material";
import api from "../../../../services/AvektoiAPIClient";
import Constants from "common/Constants";
import Loader from "components/Loader";
import axios from "axios";

const SelectContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

const StyledTextField = styled(TextField)({
  width: "48%",
  height: "56px",
  marginBottom: "20px",
});

const CountryStep = ({ nextStep }) => {
  const { t } = useTranslation();
  const [country] = useState("FR");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);

  useEffect(() => {
    const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));
    if (!clientObject) window.location.href = "/sign-up";
   console.log(clientObject)
    if(clientObject.city) {
      setCity(clientObject.city)
    }
    if(clientObject.address_line_1) {
      setAddress(clientObject.address_line_1)
     
    }
    if(clientObject.zip_code) {
      setZipCode(clientObject.zip_code)
    }
  
  }, [nextStep]);

  const handleNextClick = async () => {
    if (validateZipCode(zipCode)) {
      setZipCodeError("");
      const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) || {};
      const newClientObject = {
        ...clientObject,
        country_id: country,
        city: city,
        address_line_1: address,
        zip_code: zipCode,
      };
      sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify(newClientObject));
      localStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify(newClientObject));
      setIsLoading(true);

      await api.updateClient(clientObject.id, {
        country_id: country,
        city: city,
        address_line_1: address,
        zip_code: zipCode,
        client_type: clientObject.client_type,
        date_of_birth: clientObject.date_of_birth,
        first_name: clientObject.first_name,
        last_name: clientObject.last_name,
      });
      setIsLoading(false);
      nextStep({ country_id: country, city, address, zipCode });
    } else {
      setZipCodeError(t("signup.step7.errorInvalidZip"));
    }
  };

  const handleAddressChange = async (event, value) => {
    if (value === null || value === "") {
      setAddress("");
      setZipCode("");
      setCity("");
      setAddressOptions([]);
      return;
    }

    setAddress(value);
    setZipCode("");
    setCity("");
    setAddressOptions([]);

    if (value.length > 0) {
      try {
        const response = await axios.get(
          `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=5`
        );
        const addresses = response.data.features.map((feature) => ({
          label: feature.properties.label,
          city: feature.properties.city,
          postcode: feature.properties.postcode,
        }));
        setAddressOptions(addresses);
      } catch (error) {
        console.error("Error fetching address suggestions:", error);
        setAddressOptions([]);
      }
    } else {
      setAddressOptions([]);
    }
  };

  const handleAddressSelect = (event, value) => {
    if (value) {
      setAddress(value.label);
      setCity(value.city);
      setZipCode(value.postcode);
    } else {
      setAddress("");
      setCity("");
      setZipCode("");
    }
  };

  const validateZipCode = (zipCode) => {
    return zipCode.length > 0;
  };

  return (
    <>
      {isLoading && <Loader />}
      <Typography variant="h2">{t("signup.step7.title1")}</Typography>
      <Box position="relative">
        <div style={{ marginTop: 30, maxWidth: 600 }}>
        <SelectContainer>
            <StyledTextField
              value="France"
              label={t("signup.step7.country")}
              variant="outlined"
              disabled
            />
            <StyledTextField
              value={city}
              label={t("signup.step7.city")}
              variant="outlined"
              disabled
              placeholder={t("signup.step7.city")}
            />
          </SelectContainer>
          <Autocomplete
              freeSolo
              options={addressOptions}
              getOptionLabel={(option) => option.label}
              value={address ? { label: address, city, postcode: zipCode } : null} 
              onInputChange={(event, newValue) => handleAddressChange(event, newValue)}
              onChange={handleAddressSelect}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("signup.step7.address")}
                  variant="outlined"
                  fullWidth
                />
              )}
            />

          <InputComponent
            label={t("signup.step7.zipCode")}
            type="text"
            value={zipCode}
            disabled
            customStyles={{ width: "300px", height: "56px", marginTop: "20px"}}
            error={zipCodeError}
            helperText={zipCodeError}
          />
        </div>
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="center" columnGap={2} sx={{marginTop:"10px"}}>
        <Button
          disabled={!city || !address || !zipCode}
          variant="contained"
          color="primary"
          style={{ width: 250 }}
          onClick={handleNextClick}
        >
          {t("buttons.next")}
        </Button>
      </Stack>
    </>
  );
};

export default CountryStep;
