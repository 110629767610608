import React from "react";
import LogoMain from "components/logo/LogoMain";
import { Grid, useMediaQuery } from "@mui/material";
import { Box, Stack } from "@mui/system";

const SignupLayout = ({ children }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center" // Center the logo on the navbar
          sx={{
            px: 4,
            borderBottom: "1px solid lightgray",
            paddingBottom: isMobile ? 0 : "1px",
            paddingTop: isMobile ? 0 : "1px",
            position: "fixed", // Fixed position
            top: 0, // Always stick to the top
            width: "100%", // Ensure it takes full width
            zIndex: 1000, // Ensures it stays above other content
            backgroundColor: "white" // Background color to cover content when scrolling
          }}
        >
          <Box>
            <LogoMain />
          </Box>
        </Stack>

        <Grid container style={{ flex: 1, textAlign: "-webkit-center", maxWidth: "1200px", margin: "0 auto", paddingTop: isMobile ? "70px" : "100px" }}>
          <Grid 
            container 
            alignItems="center" 
            style={{ display: 'flex', flexDirection: 'column', width: "90%", margin: '30px auto', textAlign: "-webkit-center" }}
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignupLayout;
