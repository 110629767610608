import { useNavigate, Link } from 'react-router-dom';

// material-ui
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import NewButton from 'components/NewButton';
// third-party
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

// project-imports
import useAuth from 'hooks/useAuth';
import { openSnackbar } from 'api/snackbar';

// ============================|| FORGOT PASSWORD ||============================ //

export default function AuthForgotPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isLoggedIn, resetPassword } = useAuth();

  return (
    <Formik
      initialValues={{
        email: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Doit être une adresse e-mail valide')
          .max(255, 'L\'e-mail ne doit pas dépasser 255 caractères')
          .required('L\'adresse e-mail est obligatoire'),
      })}
      
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await resetPassword(values.email);
          setStatus({ success: true });
          setSubmitting(false);
          openSnackbar({
            open: true,
            message: 'Vérifiez votre e-mail pour le lien de réinitialisation du mot de passe',
            variant: 'alert',
            alert: {
              color: 'success',
            },
          })

          setTimeout(() => {
            navigate(isLoggedIn ? '/auth/check-mail' : '/check-mail', { replace: true });
          }, 1500);
        } catch (err) {
          setStatus({ success: false });
          if (err === 'Client not found') {
      setErrors({submit: t('login.emailNonExistant') });
    } else {
      setErrors({ submit: err});
    }
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="email-forgot">Addresse Email:</InputLabel>
                <OutlinedInput
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  id="email-forgot"
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Entrez votre addresse email"
                />
              </Stack>
              {touched.email && errors.email && (
                <FormHelperText error id="helper-text-email-forgot">
                  {errors.email}
                </FormHelperText>
              )}
            </Grid>
            {errors.submit && (
              <Grid item xs={12}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Grid>
            )}
            <Grid item xs={12} sx={{ mb: -2 }}>
              <Typography variant="caption">N'oubliez pas de vérifier votre dossier SPAM.</Typography>
            </Grid>

            {/* Buttons in a Row */}
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} justifyContent="center">
                {/* Back to Login Button */}
          

<NewButton component={Link}
                  to={isLoggedIn ? '/auth/login' : '/login'}
                  label= {t('login.back')}
                  cancel={true}/>
 <NewButton label={t('login.resetPassword')} type="submit"/>
               
              </Stack>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
