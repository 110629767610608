import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import InputComponent from '../../../components/InputComponent';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
  TextField
} from '@mui/material';
import MainCard from 'components/MainCard';
import axiosServices, { fetcher, fetcherPost } from 'utils/axios';
import { RemoveCircle } from '@mui/icons-material';
import { format as currencyFormatter } from 'currency-formatter';
import IconButton from 'components/@extended/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import ClientService from '../../../services/ClientService';
import Constants from '../../../common/Constants';
import { loadStripe } from '@stripe/stripe-js';
import Swal from 'sweetalert2';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import PromotionCodeService from 'services/PromotionCodeService';

const stripePromise = loadStripe(Constants.STRIPE_PUBLIC_KEY);
const NumberInputContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const NumberButton = styled(Button)({
  // backgroundColor: '#90a4ae',
  // color: '#fff',
  height: "40px",
  fontSize: "30px",
});

const NumberInput = styled(TextField)({
  width: "60px",
  textAlign: "center",
  fontSize: "18px",
  lineHeight: "20px",
  "& input": {
    textAlign: "center",
    fontSize: "15px",
    padding: "8px 0",
    appearance: "textfield",
    MozAppearance: "textfield",
  },
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",

  },
  "& input:disabled": {
    color: "black",
    WebkitTextFillColor: "black",
    opacity: 1,
  },


});

const price = {
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: 1,
};

const Step9 = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { search } = useLocation();
  const discount = new URLSearchParams(search).get('discount');

  const [selected, setSelected] = useState([]);
  const [risks, setRisks] = useState([]);
  const [pricingTable, setPricingTable] = useState([]);
  const productId = '19edb627-9ddb-4df4-bc60-8ac4ac8c2e37'; // TODO: replace with actual product ID
  const [maximumPrice, setMaximumPrice] = useState(500);
  const [open, setOpen] = useState(false);
  const [explanation, setExplanation] = useState();
  const client = JSON.parse(localStorage.getItem(Constants.CLIENT_OBJECT));
  const [clientID, setClientID] = useState([]);
  const [quotation, setQuotation] = useState({});
  const [open2, setOpen2] = useState(false);
  const [isConfirmObject, setIsConfirmObject] = useState({});
  const [open3, setOpen3] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [promoCode, setPromoCode] = useState('');

  const navigate = useNavigate()


  const handleIncrement = () => {
    if (maximumPrice < 2000) {
      setMaximumPrice((prev) => (prev === 500 ? 1000 : prev === 1000 ? 2000 : prev));
    }
  };

  const handleDecrement = () => {
    if (maximumPrice > 500) {
      setMaximumPrice((prev) => (prev === 2000 ? 1000 : prev === 1000 ? 500 : prev));
    }
  };
  useEffect(() => {
    getRiskObjects();
    getRiskPriceTable();
    fetchClientId();

    if (discount) {
      setPromoCode(discount);
    }
  }, []);

  const generateQuotation = () => {

    return {
      product_id: productId,
      maximum_amount_of_compensation_per_claim: maximumPrice,
      number_of_claim_per_year: 3,
      risk_ids: selected,
      total_amount: priceInfo.monthly,
      price_combo_id: priceInfo.price_combo_id,
    };
  };

  const fetchClientId = async () => {
    try {
      const clientId = await ClientService.getClientIdByEmail(client.email);
      setClientID(clientId); // Update the client object with fetched ID
      console.log('je suis ici!: ' + clientId);
    } catch (error) {
      console.error('Error fetching client ID:', error.message);
    }
  };

  const createQuotation = async (quote) => {
    try {
      const response = await fetcherPost([`/quotes/`, quote]);
      return response;
    } catch (error) {
      console.error('Error creating quotation:', error);
    }
  };

  const sendEmailWithAttachments = async () => {
    try {
      if (!client.email) {
        console.error('Email is missing');
        return;
      }

      const emailPayload = {
        email: client.email,
        subject: "Documents pré-contractuels relatifs à votre contrat d'assurance avektoi",
        message: " "
      };

      await axiosServices.post('/quotes/send-email-with-pdfs', emailPayload);

      Swal.fire({
        title: t('signup.step9.emailSentSuccessfully'),
        icon: 'success',
        timer: 3000,
      });
    } catch (error) {
      console.error('Error sending email:', error);
      Swal.fire({
        title: t('signup.step9.emailSentFailed'),
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const getRiskObjects = async () => {
    let request = await fetcher(`/risks/list-by-product-id/${productId}`);
    setRisks(request.sort((a, b) => a.price - b.price));
  };

  const getRiskPriceTable = async () => {
    let request = await fetcher('/risk_price_combo');
    setPricingTable(request);
  };

  const calculatePrice = useCallback(
    (amount, selectedRisks) => {
      const allRisks = risks.map((risk) => risk.id);
      const defaultPrices = {};

      // Populate default prices for each individual risk
      allRisks.forEach((risk) => {
        const riskEntry = pricingTable.find(
          (item) => item.maximum_amount === amount.toString() && item.risks.length === 1 && item.risks.includes(risk)
        );

        if (riskEntry) {
          defaultPrices[risk] = riskEntry.price;
        } else {
          defaultPrices[risk] = 'N/A';
        }
      });

      const defaultObject = {
        price_combo_id: undefined,
        risks: defaultPrices,
        additionalPrices: {},
        annual: 0,
        monthly: 0,
      };

      // If no risks are selected, return default prices and total = 0
      if (!selectedRisks || selectedRisks.length === 0) {
        return defaultObject;
      }

      // Find the pricing entry for the selected risks
      const selectedEntry = pricingTable.find(
        (item) =>
          item.maximum_amount === amount.toString() &&
          item.risks.length === selectedRisks.length &&
          item.risks.every((risk) => selectedRisks.includes(risk))
      );

      if (!selectedEntry) {
        return defaultObject;
      }

      // Get the price for the selected risks
      const monthlyCost = selectedEntry.price;
      const annualCost = monthlyCost * 12;

      // Calculate additional prices for unselected risks
      const additionalPrices = {};
      const unselectedRisks = allRisks.filter((risk) => !selectedRisks.includes(risk));

      unselectedRisks.forEach((unselectedRisk) => {
        const additionalEntry = pricingTable.find(
          (item) =>
            item.maximum_amount === amount.toString() &&
            item.risks.length === selectedRisks.length + 1 &&
            selectedRisks.every((risk) => item.risks.includes(risk)) &&
            item.risks.includes(unselectedRisk)
        );

        if (additionalEntry) {
          additionalPrices[unselectedRisk] = additionalEntry.price - monthlyCost;
        } else {
          additionalPrices[unselectedRisk] = 'N/A';
        }
      });

      return {
        price_combo_id: selectedEntry.id,
        risks: defaultPrices,
        additionalPrices,
        annual: annualCost.toFixed(2),
        monthly: monthlyCost,
      };
    },
    [pricingTable, risks]
  );

  const priceInfo = useMemo(() => {
    if (!risks.length)
      return {
        risks: {},
        additionalPrices: {},
        annual: 0,
        monthly: 0,
      };

    return calculatePrice(maximumPrice, selected);
  }, [risks.length, calculatePrice, maximumPrice, selected]);

  const handleSelect = async (id) => {
    const selectedRisk = risks.find((risk) => risk.id === id);
    const isSelected = selected.includes(id);

    if (selectedRisk?.is_required_to_confirm) {
      if (!isSelected) {
        const confirmation = await Swal.fire({
          title: t('signup.step9.riskConfirmationMessage'),
          icon: 'warning',
          confirmButtonText: t('buttons.yes'),
          cancelButtonText: t('buttons.no'),
          confirmButtonColor: theme.palette.primary.main,
          showCancelButton: true,
          reverseButtons: true,
        });
        if (!confirmation.isConfirmed) return;
      }

      setIsConfirmObject((prev) => ({ ...prev, [id]: !isSelected }));
    }

    const selectedRiskIds = isSelected ? selected.filter((item) => item !== id) : [...selected, id];
    setSelected(selectedRiskIds);
  };

  const handleConfirm = async (id) => {
    if (!isConfirmObject[id]) {
      const confirmation = await Swal.fire({
        title: t('signup.step9.riskConfirmationMessage'),
        icon: 'warning',
        confirmButtonText: t('buttons.yes'),
        cancelButtonText: t('buttons.no'),
        confirmButtonColor: theme.palette.primary.main,
        showCancelButton: true,
        reverseButtons: true,
      });

      if (!confirmation.isConfirmed) return;
    } else {
      setSelected(selected.filter((item) => item !== id));
    }

    setIsConfirmObject((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const getQuotation = async (quote) => {
    try {
      console.log('Response from fetcherPost:', quote); // Log fetcherPost response
      if (!quote || !quote.quote_code) {
        console.error('Missing or invalid quote_code:', quote);
        return null;
      }
      const response = await axiosServices.get(`/quotes/get-by-code/${quote.quote_code}`);
      console.log('Fetched quotation details:', response.data); // Log fetched quotation
      const fetchedQuotation = response.data;
      setQuotation(fetchedQuotation);
      return fetchedQuotation;
    } catch (error) {
      console.error('Error fetching quotation:', error.message); // Log error
      return null; // Return null explicitly
    }
  };

  const checkPromoCode = async () => {
    try {
      if (!promoCode) {
        return true;
      }

      await PromotionCodeService.checkPromoCode(promoCode);
      return true;
    } catch (error) {
      console.error('Error checking promo code:', error.message);
      Swal.fire({
        title: t('signup.step9.promoCodeInvalid'),
        icon: 'error',
        timer: 3000,
      });
      return false;
    }
  }

  const handleCheckout = async (isCheckoutLater = false) => {
    setIsSubmitting(true);

    try {
      await fetchClientId();
      const clientId = clientID;

      if (!clientId) {
        console.error('Client ID is undefined');
        return;
      }

      const isPromoCodeValid = await checkPromoCode();
      if (!isPromoCodeValid) {
        setIsSubmitting(false);
        return;
      }

      const quote = generateQuotation();
      const createdQuote = await createQuotation(quote);
      setQuotation(createdQuote);
      console.log('Created quote: ', createdQuote);

      if (!createdQuote || !createdQuote.id) {
        console.error('Quotation or quotation.id is undefined');
        return;
      }

      const { data } = await axiosServices.post(
        '/clients/create-checkout-session',
        {
          quote_id: createdQuote.id,
          amount: priceInfo.monthly,
          mode: 'subscription',
          is_checkout_later: isCheckoutLater,
          promo_code: promoCode,
        },
        {
          headers: { client_id: clientId },
        }
      );

      // Remove the client object from session storage
      sessionStorage.removeItem(Constants.CLIENT_OBJECT);

      if (isCheckoutLater) {
        console.log('Checkout later');
        setIsSubmitting(false);
        navigate('/dashboard');
        return;
      }

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId: data.sessionId });

      if (error) {
        console.error('Stripe checkout error:', error);
        setIsSubmitting(false);
        return;
      }

      console.log('Checkout initiated successfully');
    } catch (error) {
      console.error('Error during checkout:', error);
    }
    setIsSubmitting(false);
  };

  const normalizeText = (text) => text.toLowerCase().replaceAll(' ', '-');
  const handleOpen = (keyword) => {
    setExplanation({ label: keyword, content: normalizeText(keyword) });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => setOpen2(false);

  const handleOpen3 = () => {
    setOpen3(true);
  };
  const handleClose3 = () => setOpen3(false);

  const explanationContentMap = {
    'ma-maison': {
      title: 'Protégez votre foyer face aux imprévus !',
      content: [
        <span key="1">
          ✅ <strong>Catastrophe naturelle</strong> (inondation, tempête, séisme…)
        </span>,
        <span key="2">
          ✅ <strong>Expropriation</strong> forcée de votre résidence principale
        </span>,
        <span key="3">
          ✅ <strong>Incendie grave</strong> impactant plusieurs pièces
        </span>,
        <span key="4">
          ✅ <strong>Attaque terroriste</strong> causant des dommages
        </span>,
        <span key="5">
          ✅ <strong>Vol</strong> avec effraction ou agression
        </span>,
        <span key="6">
          ✅ <strong>Coupure d’électricité prolongée après un événement naturel</strong>
        </span>,
       
      ],
      events: [
        "Catastrophe naturelle",
        "Expropriation",
        "Incendie grave",
        "Attaque terroriste",
        "Vol",
        "Coupure d’électricité prolongée après un événement naturel",
      ],
      conclusion: (
        <span>
          📌
          <strong>
            {' '}
            Une prise en charge rapide et un accompagnement complet pour faire face aux moments difficiles.
          </strong>
        </span>
      ),
    },
    'mon-travail': {
      title: 'Sécurisez votre avenir professionnel !',
      content: [
        <span key="1">
          ✅ <strong>Licenciement</strong> (hors rupture conventionnelle ou économique préexistante)
        </span>,
        <span key="2">
          ✅ <strong>Harcèlement au travail</strong> (y compris cyberharcèlement)
        </span>,
        <span key="3">
          ✅ <strong>Burn-out</strong> reconnu médicalement
        </span>,
        <span key="4">
          ✅ <strong>Litige avec votre employeur</strong> (hors conflits collectifs)
        </span>,
        
      ],
      events: [
        "Licenciement", "Harcelement", "Burn out", " Litige avec votre employeur", 
      ],
      conclusion: (
        <span>
          📌<strong> Un accompagnement complet pour faire face aux défis professionnels.</strong>
        </span>
      ),
    },
    'ma-famille': {
      title: 'Protégez ceux qui comptent le plus !',
      content: [
        <span key="1">
          ✅ <strong>Décès d'un membre de la famille, y compris animal de compagnie",</strong> - Perte d’un proche avec un accompagnement renforcé
        </span>,
        <span key="2">
          ✅ <strong>Maladies graves</strong> - Maladie grave nécessitant un suivi médical important
        </span>,
        <span key="3">
          ✅ <strong>Accidents graves</strong> - Accident grave entraînant une incapacité prolongée
        </span>,
        <span key="4">
          ✅ <strong>Violences physiques</strong> - Violence physique ou agression (y compris violences sexuelles)
        </span>,
        <span key="5">
          ✅ <strong>Harcèlement</strong> - Harcèlement (scolaire, cyber, familial)
        </span>,
        <span key="6">
          ✅ <strong>Naissance avec complication</strong> - Naissance avec complications nécessitant une hospitalisation
        </span>,
        <span key="7">
          ✅ <strong>Témoin d'un événement traumatissant</strong> - Témoin ou victime d’un événement traumatisant
        </span>

      ],
      events: [
        "Décès d'un membre de la famille, y compris animal de compagnie",
        "Maladies graves",
        "Accidents graves",
        "Violences physiques",
        "Harcèlement",
        "Naissance avec complication",
        "Témoin d'un événement traumatissant"
      ],
      conclusion: (
        <span>
          📌
          <strong>
            {' '}
            Un accompagnement humain et une prise en charge adaptée pour affronter les épreuves de la vie.
          </strong>
        </span>
      ),
    },
  };

  const getRiskPrice = (risk) => {
    if (priceInfo.additionalPrices[risk.id]) {
      return `+ ${currencyFormatter(priceInfo.additionalPrices[risk.id] ?? 0, { code: 'EUR' })}`;
    }
    if (selected.includes(risk.id)) {
      return risk.little_message ?? '-';
    }
    return currencyFormatter(priceInfo.risks[risk.id] ?? 0, { code: 'EUR' });
  };

  const PriceAndPromo = ({ defaultValue, onChange }) => <Grid container spacing={3} >
    <Grid item xs={12} md={12}>
      <MainCard border={true} sx={{ height: '100%' }} style={{borderColor:"#F066B5",border:" 2px solid #F066B5"}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
            <Typography variant="h5">
              {t('signup.step9.maxCompensation')}
              <Tooltip title="Indemnité financière">
                <IconButton onClick={() => handleOpen3()}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <Dialog open={open3} onClose={handleClose3}>
              <DialogTitle sx={{ m: 0, p: 2 }}>
                <Typography variant="h6">Indemnité financière</Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleClose3}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                C'est le montant maximum que nous prenons en charge si un événement couvert par votre contrat
                survient. Vous pouvez choisir un plafond de 500€, 1000€ ou 2000€ lors de la souscription. Dans
                certains cas, comme le décès d’un proche, ce montant peut être doublé. Votre plafond est précisé
                dans vos Conditions Particulières et vous pouvez le modifier à tout moment depuis votre espace
                client.
              </DialogContent>
            </Dialog>
            <NumberInputContainer style={{ marginLeft: '-30px', marginTop: '7px' }}>
              <NumberButton onClick={handleDecrement} disabled={maximumPrice === 500}>
                -
              </NumberButton>
              
              <NumberInput
                value={maximumPrice}
                onChange={setMaximumPrice}
                disabled={true}
                type="number"
                inputProps={{ min: 500, max: 2000, style: { appearance: 'textfield' } }}
              />
              <NumberButton onClick={handleIncrement} disabled={maximumPrice === 2000}>
                +
              </NumberButton>
            </NumberInputContainer>
            {/* <SliderContainer>
            <CustomSlider sliderValue={maximumPrice} onChange={setMaximumPrice} />
          </SliderContainer> */}
          </Grid>
          <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
          <br/>
          <br/>
            <Typography variant="h5">{t('signup.step9.code')}</Typography>
            <InputComponent
              customStyles={{
                width: '210px',
                height: '40px',
                borderRadius: '4px',
                marginTop: '15px',
              }}
              label={t('signup.step9.promoCode')}
              type="text"
              onChange={onChange}
              defaultValue={defaultValue}
            />
          </Grid>
        </Grid>
      </MainCard>
    </Grid>
  </Grid>

  const TotalPrice = () => <MainCard border={true} sx={{ width: '100%', marginTop: 2 }} style={{border:" 2px solid #F066B5"}}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Link
          sx={{
            typography: { xs: 'h6' },
            textDecoration: 'underline',
            cursor: 'pointer',
            marginBottom:"10px"
          }}
          color="grey"
          onClick={sendEmailWithAttachments}
        >
          {t('termsAndCondition')}
        </Link>
        <br />
        <br/>

        {selected.length > 0 && !isSubmitting ? (
          <Link
            sx={{
              typography: { xs: 'h6' },
              textDecoration: 'underline',
              cursor: 'pointer',
              marginTop:"10px"
            }}
            color="grey"
            onClick={() => handleCheckout(true)}
          >
            {t('establishAQuote')}
          </Link>
        ) :           <br/>
      }
      </Grid>
      <Grid item xs={12} md={12} alignItems="flex-end">
        <Stack
          alignItems="flex-end"
          justifyContent="space-between"
          sx={{ flexDirection: { xs: 'row', md: 'column' } }}
        >
          <br/>

          <Typography
            color="text.secondary"
            sx={{ typography: { xs: 'h4', md: 'h5' }, textAlign: { md: 'right' } }}
          >
            {t('signup.step9.total')}
          </Typography>
          <Typography variant="h1" sx={price}>
            {currencyFormatter(priceInfo.monthly, { code: 'EUR' })}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={0}>
            <Typography color="text.secondary">
              <i>par mois</i>
            </Typography>
            <Tooltip title="Droit de rétractation et conditions de résiliation">
              <IconButton onClick={() => handleOpen2()}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Stack>

          <Dialog open={open2} onClose={handleClose2}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
              <Typography variant="h6">Droit de rétractation et conditions de résiliation</Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose2}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <i>
                Vous disposez d’un délai de 14 jours calendaires révolus à compter de la date de souscription
                pour exercer son droit de rétractation, par lettre recommandée avec accusé de réception,
                conformément à l’article L112-2-1 du Code des assurances. Passez ce délai, la durée du contrat
                minimale est d'un an. Vous pourrez alors résilier votre contrat à tout moment. Des frais de
                gestion d’un montant de 5,00 € TTC sont perçus lors de la souscription du contrat. Ce montant
                couvre les frais de mise en service. En cas de résiliation anticipée, ces frais ne sont pas
                remboursables.
              </i>
            </DialogContent>
          </Dialog>
          <Typography
            color="text.secondary"
            sx={{ typography: { xs: 'h4', md: 'h5' }, textAlign: { md: 'right' } }}
          >
            {t('signup.step9.TTC')}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  </MainCard>

  const debounceTimeout = useRef(null);

  const handlePromoCodeChange = (e) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => setPromoCode(e.target.value), 1000)
  }

  return (
    <>
    <Typography variant="h2" gutterBottom>
      Veuillez choisir votre formule.<br/> Optez pour plusieurs formules et profitez d'une remise exclusive !
      </Typography>
      <List>
        <ListItem sx={{ px: 0 }}>
          <Grid container spacing={3}>
            {risks.map((risk, index) => (
              <Grid item xs={12} md={3} key={index} style={{ textAlign: 'center' }}>
                <MainCard
                  border={true}
                  sx={{
                    px: 0,
                    border: selected.includes(risk.id) ? '2px solid' : 'none',
                    borderColor: selected.includes(risk.id) ? 'primary.main' : 'transparent',
                    height: '100%',
                  }}
                >
                  <Stack alignItems="flex-end">
                    <Tooltip title={risk.risk_label}>
                      <IconButton onClick={() => handleOpen(risk.risk_label)}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="center" columnGap={1}>
                    <Typography variant="h3">{risk.risk_label}</Typography>
                  </Stack>
                  <div>
                    <div style={{ marginTop: 30, marginBottom: 30 }}>
                      {selected.includes(risk.id) ? (
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                          {getRiskPrice(risk)}
                        </Typography>
                      ) : (
                        <>
                          <Typography variant="h1" sx={price} color="primary" className="price-change">
                            {getRiskPrice(risk)}
                          </Typography>
                          <Typography variant="h6" color="text.secondary">
                            {selected.length > 0 ? ` ${t('signup.step9.monthPrice', { price: priceInfo.risks[risk.id] })}` : t('signup.step9.month')}
                          </Typography>
                        </>
                      )}
                    </div>
                    {selected.includes(risk.id) ? (
                      <Button
                        onClick={() => handleSelect(risk.id)}
                        style={{ width: '100%', border: '1px solid', borderColor: 'grey.500' }}
                        variant="contained"
                        color="grey"
                        startIcon={<RemoveCircle />}
                      >
                        {t('signup.step9.retirer')}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleSelect(risk.id)}
                        style={{ width: '100%' }}
                        variant="contained"
                        color="primary"
                        startIcon={<AddCircleIcon />}
                      >
                        {t('signup.step9.ajouter')}
                      </Button>
                    )}
                  </div>

                  <div style={{ textAlign: 'left', marginTop: 50 }}>
                    {risk.id === 'ecd22aea-7412-459d-858c-340640675d16' && explanationContentMap['ma-maison'] && (
                      <div>
                        {explanationContentMap['ma-maison'].events.map((event, idx) => (
                          <div key={idx} style={{ marginBottom: 10 }}>
                            <strong>✅ {event}</strong>
                          </div>
                        ))}
                      </div>
                    )}
                    {risk.id === '2c1bac5d-e013-4d15-9d09-cb5ee408fabb' && explanationContentMap['mon-travail'] && (
                      <div>
                        {explanationContentMap['mon-travail'].events.map((event, idx) => (
                          <div key={idx} style={{ marginBottom: 10 }}>
                            <strong>✅ {event}</strong>
                          </div>
                        ))}
                      </div>
                    )}
                    {risk.id === 'a5872db0-bc1d-4f7a-8b27-ef14706f8ed2' && explanationContentMap['ma-famille'] && (
                      <div>
                        {explanationContentMap['ma-famille'].events.map((event, idx) => (
                          <div key={idx} style={{ marginBottom: 10 }}>
                            <strong>✅ {event}</strong>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  {risk?.is_required_to_confirm && (
                    <FormControlLabel style={{textAlign: 'left', marginTop: 30, fontSize: 8}}
                      label="Je confirme qu'au moins une personne dans mon foyer est salariée"
                      control={
                        <Checkbox checked={isConfirmObject[risk.id] ?? false} onChange={() => handleConfirm(risk.id)} />
                      }
                    />
                  )}
                </MainCard>
              </Grid>
            ))}
            
            <Grid item xs={12} md={3} style={{marginTop:"auto"}}>
              <PriceAndPromo defaultValue={promoCode} onChange={handlePromoCodeChange} />
              <TotalPrice />
            </Grid>

            {explanation && (
              <Dialog onClose={handleClose} open={open}>
                <DialogTitle sx={{ m: 0, p: 2 }}>{explanation.label}</DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                  {explanationContentMap[explanation.content] ? (
                    <>
                      <Typography gutterBottom>{explanationContentMap[explanation.content].title}</Typography>
                      <Typography gutterBottom>
                        Bénéficiez d’un <strong>soutien financier</strong>, d’une <strong>protection juridique</strong>,
                        d’une <strong>assistance administrative</strong> et d’un{' '}
                        <strong>accompagnement psychologique</strong> en cas de :
                      </Typography>
                      <ul>
                        {explanationContentMap[explanation.content].content.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                      <Typography gutterBottom>{explanationContentMap[explanation.content].conclusion}</Typography>
                      <Typography gutterBottom>
                        ℹ️{' '}
                        <i>
                          Pour plus de détails sur les garanties et les exclusions, veuillez consulter les Conditions
                          Générales.
                        </i>
                      </Typography>
                    </>
                  ) : (
                    <Typography gutterBottom>{explanation.content}</Typography>
                  )}
                </DialogContent>
              </Dialog>
            )}
          </Grid>
        </ListItem>
        <ListItem style={{marginLeft:!isMobile &&"15px"}}>
          <Grid container spacing={3} sx={{ textAlign: 'right' }}>
            <Grid item xs={12} md={12}>
              <Button
                disabled={selected.length === 0 || isSubmitting}
                variant="contained"
                color="primary"
                sx={{ width: { xs: '100%', md: 250 } }}
               
                onClick={() => handleCheckout(false)}
              >
                Payer
              </Button>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </>
  );
};

export default Step9;
