import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import api from "services/AvektoiAPIClient";
import Constants from "common/Constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "components/@extended/IconButton";
import { useNavigate } from "react-router";
import Loader from "components/Loader";

const NumberInputContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "50px",
});

const NumberButton = styled(Button)({
  height: "40px",
  fontSize: "40px",
});

const NumberInput = styled(TextField)({
  width: "60px",
  textAlign: "center",
  fontSize: "24px",
  lineHeight: "40px",
  "& input": {
    textAlign: "center",
    fontSize: "20px",
    padding: "8px 0",
    paddingLeft: 10,
  },
});

const EmployeesStep = ({ nextStep, prevStep }) => {
  const { t } = useTranslation();
  const [employees, setEmployees] = useState(0);
  const [clientObject, setClientObject] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));
    setClientObject(clientObject);
    if (clientObject.nbr_employees) {
      setEmployees(clientObject.nbr_employees);
      navigate('/register-policy')
      return;
    }
  }, []);

  const handleIncrement = () => {
    setEmployees((prev) => (prev < 100 ? prev + 1 : prev));
  };

  const handleDecrement = () => {
    setEmployees((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
      setEmployees(value === "" ? "" : Number(value));
    }
  };

  const handleNextClick = async () => {
    if (employees > 20) {
      setShowMessage(true);
    } else {
      try {
        setIsLoading(true)
        await api.updateClient(clientObject.id, { nbr_employees: employees.toString() });
        const updatedClientObject = { ...clientObject, nbr_employees: employees };
        sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify(updatedClientObject));
        setIsLoading(false)
        navigate(Constants.SITE_MAP.REGISTER_POLICY);
        nextStep({ employees });
      } catch (error) {
        console.error("Error updating number of employees:", error);
      }
    }
  };

  const handleProceedClick = () => {
    navigate("/my-insuranes"); // Adjust this path to where the user should go
  };

  const handleBackClick = () => {
   
    prevStep();
  };

  return (
    <>
      {isLoading && <Loader />}
      <Typography variant="h2">{t("signup.step8.title")}</Typography>
      <Grid container>
        <Grid item xs={3} style={{ textAlign: '-webkit-center' }}>
          <IconButton onClick={handleBackClick} style={{ padding: 5, height: 100, width: 60 }}><ArrowBackIcon style={{ width: 46, height: 46 }} /></IconButton>
        </Grid>
        <Grid item xs={6}>
          <div style={{ marginTop: 30, maxWidth: 600 }}>
            {!showMessage ? (
              <NumberInputContainer>
                <NumberButton color="primary" onClick={handleDecrement}>
                  -
                </NumberButton>
                <NumberInput
                  value={employees}
                  onChange={handleChange}
                  type="number"
                  inputProps={{ min: 1, max: 100 }}
                />
                <NumberButton onClick={handleIncrement}>+</NumberButton>
              </NumberInputContainer>
            ) : (
              <Typography variant="body1" align="center" marginBottom="10px">
                {t("signup.step8.largeNumberMessage")}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    

      <Stack direction="row" alignItems="center" justifyContent="center" columnGap={2}>
        {!showMessage ? (
          <Button
            disabled={employees === 0}
            variant="contained"
            color="primary"
            style={{ width: 250 }}
            onClick={handleNextClick}
          >
            {t("buttons.next")}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{ width: 250 }}
            onClick={handleProceedClick}
          >
            {t("buttons.next")}
          </Button>
        )}
      </Stack>
    </>
  );
};

export default EmployeesStep;
