import serviceLinks from "../common/Links.json"
import ApiHelper from "../common/ApiHelper";
import Headers from "../common/types/Headers";
import Constants from "../common/Constants";
import axios from "axios"

const headers = new Headers();

class ClaimService {
    static getMyClaimList() {
        var clientId = Constants.CLIENT_ID_SAMPLE; // TODO: Get from loggedin client
        if(clientId){
            const url = serviceLinks.getMyClaimList;
            let params = {};
            let hdrs = headers.GetHeaders();
            return new Promise((resolve, reject) => {
                ApiHelper('GET', url, params, hdrs                
                ).then(data => {
                    resolve(data);              
                }).catch(err => {
                    reject(err);
                });
            });                
        }   
    }

    static getAllClaimList() {
        var clientId = Constants.CLIENT_ID_SAMPLE; // TODO: Get from loggedin client
        if(clientId){
            const url = serviceLinks['Claims']['list'].url;
            let params = {};
            let hdrs = headers.GetHeaders();
            return new Promise((resolve, reject) => {
                ApiHelper('GET', url, params, hdrs                
                ).then(data => {
                    resolve(data);              
                }).catch(err => {
                    reject(err);
                });
            });                
        }   
    }

    static getClaimDetailByClaimId(claimId) {
        if(claimId){
            const url = serviceLinks.getClaimDetailByClaimId + claimId;
            let params = {};
            let hdrs = headers.GetHeaders();
            return new Promise((resolve, reject) => {
                ApiHelper('GET', url, params, hdrs                
                ).then(data => {
                    resolve(data);              
                }).catch(err => {
                    reject(err);
                });
            });                
        }   
    }
    static createNewClaim(params) {
        if(params){
            const url = serviceLinks.createNewClaim;
            // let params = {};
            let hdrs = headers.GetHeaders();
            return new Promise((resolve, reject) => {
                ApiHelper('POST', url, params, hdrs                
                ).then(data => {
                    resolve(data);              
                }).catch(err => {
                    reject(err);
                });
            });                
        }   
    }

    static cancelClaim(claimId) {
        if (claimId ) {
            const url = serviceLinks.cancelClaim; 
            const params = { claim_id: claimId, comment:"Le sinistre a été annulé" };
            const hdrs = headers.GetHeaders(); 
            
            return new Promise((resolve, reject) => {
                ApiHelper('POST', url, params, hdrs)
                    .then(data => {
                        resolve(data); 
                    })
                    .catch(err => {
                        reject(err); 
                    });
            });
        } else {
            return Promise.reject(new Error("Claim ID is required."));
        }
    }

    static async sendEmailForClaim( claimId,clientId) {
        if (!claimId || !clientId) {
          return Promise.reject(new Error("Claim ID and email are required."));
        }
        try {
          const response = await axios.post(`https://dzf7swx2chzp5.cloudfront.net/claims/send-email-for-claim`, {
            claimId,
             clientId,
           
          });
          return response.data;
        } catch (error) {
          console.error("Error sending email for claim:", error.response?.data || error.message);
          throw error.response?.data || new Error("Failed to send email for claim.");
        }
      }

    static getPolicyClaimLimits(policy_id) {
        console.log('Policy ID:', policy_id);
        if (!policy_id) {
            return Promise.reject(new Error("Policy ID is required to fetch claim limits."));
        }

        const urlTemplate = serviceLinks.Policies.getPolicyClaimLimit.url;
        if (!urlTemplate.includes("{policy_id}")) {
            console.error("URL template is missing {policy_id} placeholder");
            return Promise.reject(new Error("Invalid URL template for getPolicyClaimLimit"));
        }

        const url = urlTemplate.replace("{policy_id}", policy_id);
        console.log('Generated URL:', url);

        const hdrs = headers.GetHeaders();
        hdrs.policy_id = policy_id; // Set policy_id in headers if needed
        return new Promise((resolve, reject) => {
            ApiHelper('GET', url, {}, hdrs)
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static openClaim(claimId, comment) {
        if (claimId && comment) {
            const url = serviceLinks.openClaim; 
            const params = { claim_id: claimId, comment };
            const hdrs = headers.GetHeaders(); 
            
            return new Promise((resolve, reject) => {
                ApiHelper('POST', url, params, hdrs)
                    .then(data => {
                        resolve(data); 
                    })
                    .catch(err => {
                        reject(err); 
                    });
            });
        } else {
            return Promise.reject(new Error("Claim ID and comment are required."));
        }
    }
    

}

export default ClaimService;