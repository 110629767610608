import DashboardLayout from '../layouts/Dashboard';
import MyComplaintsPage from 'pages/frontOffice/MyComplaintsPage/MyComplaintsPage';
import MyPoliciesPage from 'pages/frontOffice/MyPoliciesPage/MyPoliciesPage';
import MyClaimsPage from 'pages/frontOffice/MyClaimsPage/MyClaimsPage';
import MyProfiles from 'pages/frontOffice/MyProfiles';
import TabPersonal from 'sections/apps/profiles/user/TabPersonal';
import TabPayment from 'sections/apps/profiles/user/TabPayment';
import TabPassword from 'sections/apps/profiles/account/TabPassword';
import TabSettings from 'sections/apps/profiles/account/TabSettings';
import CongratulationsPage from 'pages/frontOffice/RegisterPolicy/Congratulations';
// ==============================|| MAIN ROUTES ||============================== //

const ClientRoutes = {
  path: '/',
  children: [
    {
        path: 'congratulations/:id',
        element: <CongratulationsPage/>
      },
      {
      path: '/',
      element: <DashboardLayout/>,
      children: [
        {
          path: 'dashboard',
          element: <MyPoliciesPage />
        },
        {
          path: 'my-claims',
          element: <MyClaimsPage />
        },
        {
          path: 'my-complaints',
          element: <MyComplaintsPage />
        },
        {
          path: 'my-insurances',
          element: <MyPoliciesPage/>
        },
        {
          path: 'my-profiles',
          element: <MyProfiles/>,
          children: [
            {
              path: '',
              element: <TabPersonal/>
            },
            {
              path: 'payment',
              element: <TabPayment/>
            },
            {
              path: 'password',
              element: <TabPassword/>
            },
            {
              path: 'settings',
              element: <TabSettings/>
            }
          ]
        },
      
      ]
    }
  ]
};

export default ClientRoutes;
